@charset "utf-8";
/*loading*/
.pageLoading{
	display:block;
	width:100%;
	height:100%;
	position:fixed; 
	top:0;
	left:0;
	z-index:2000; 
	overflow:hidden;
}
.pageLoading::before{
	content:'';
	display:block;
	width:2000px;
	height:2000px;
	border-radius: 50%;
	position:absolute;
	top:50%;
	left:50%;
	background:#000000b3;
	-webkit-transform:translate(-50%,-50%) scale(3.5);
	transform:translate(-50%,-50%) scale(3.5);
	-webkit-transition:all .4s;
	transition:all .4s;
}
.pageLoading.end::before{
	width:130px;
	height:130px;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}

/*imgMove*/
.loadingImgMoveWrap{
	width:100%;
	height:100%;
	position:absolute;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.loadingImgMove{
	display:block;
	width:100px;
	height:100px;
	position:relative;
	overflow:hidden;
}
.loadingImgMove img{
	height:100%;
	-webkit-animation:imgMove 5s steps(5) infinite both;
	animation:imgMove 5s steps(5) infinite both;
}
@keyframes imgMove{
	0%{
		-webkit-transform:translateX(0);
		transform:translateX(0);
	}
	100%{
		-webkit-transform:translateX(-100%);
		transform:translateX(-100%);
	}
}
/*loadingBaloon*/
.loadingBaloon{
	-webkit-animation:baloon 1s linear infinite alternate both;
	animation:baloon 1s linear infinite alternate both;
}
@keyframes baloon{
	0%{
		-webkit-transform:translateY(0px);
		transform:translateY(0px);
		}
	100%{
		-webkit-transform:translateY(20px);
		transform:translateY(20px);
		}
}
/*common*/
@font-face {
	font-family: 'S-CoreDream-3Light';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'S-CoreDream-5Medium';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'S-CoreDream-6Bold';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'S-CoreDream-7ExtraBold';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-7ExtraBold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'S-CoreDream-8Heavy';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'S-CoreDream-9Black';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'NotoSansJP-Thin';
	font-style: normal;
	font-weight: 100;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff2) format('woff2'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff) format('woff'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.otf) format('opentype');
}

@font-face {
	font-family: 'NotoSansJP-DemiLight';
	font-style: normal;
	font-weight: 300;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format('woff2'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format('woff'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format('opentype');
}

@font-face {
	font-family: 'NotoSansJP-Regular';
	font-style: normal;
	font-weight: 400;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff2) format('woff2'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff) format('woff'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.otf) format('opentype');
}

@font-face {
	font-family: 'NotoSansJP-Medium';
	font-style: normal;
	font-weight: 500;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff2) format('woff2'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff) format('woff'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.otf) format('opentype');
}

@font-face {font-family: 'NotoSansJP-Bold';
	font-style: normal;
	font-weight: 700;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2) format('woff2'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff) format('woff'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf) format('opentype');
}

@font-face {font-family: 'NotoSansJP-Black';
	font-style: normal;
	font-weight: 900;
	src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff2) format('woff2'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff) format('woff'),
	url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.otf) format('opentype');
}


/* 
@font-face {
    font-family: 'NotoSansJP-Thin';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-1Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansJP-DemiLight';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-3Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
	font-family: 'NotoSansJP-Regular';
	font-style: normal;
	font-weight: 400;
	src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-4Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'NotoSansJP-Medium';
	font-style: normal;
	font-weight: 500;
	src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-5Medium.woff2') format('woff2');
}

@font-face {font-family: 'NotoSansJP-Bold';
	font-style: normal;
	font-weight: 700;
	src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-7Bold.woff2') format('woff2');
}

@font-face {font-family: 'NotoSansJP-Black';
	font-style: normal;
	font-weight: 900;
	src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-9Black.woff2') format('woff2');
} */


@font-face {
	font-family: 'CookieRun-Regular';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/CookieRun-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CookieRunOTF-Black';
	src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/CookieRunOTF-Black00.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
*{
	font-family:'S-CoreDream-5Medium','Noto Sans KR', AppleSDGothicNeo-Regular,"Malgun Gothic","맑은 고딕",dotum,'돋움',sans-serif; 
	font-size:18px; 
	color:#3b3b3b; 
	line-height: 1.6; 
	margin:0; 
	padding:0;
}

br{
	line-height:0;
}
html,body{
	height:100%;
	margin:0;
	padding:0;
	display:block;
	overflow:visible;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.body_black{
	display:none;
	background:#00000090;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
	z-index:130;
}
section{
	width:100%;
	height:100vh;
	position:relative;
	overflow:hidden;
	padding-top:80px;
	box-sizing:border-box;
	font-size:16px;
}
section.footer_section{
	height:auto;
	padding-top:0;
}
.inner{
	width:1200px;
	height:100%;
	margin:0 auto;
}
.inner90{
	width:90%;
	height:100%;
	margin:0 auto;
	position:relative;
}
.cf::after{
	display:block; 
	content:''; 
	clear:both;
}
.content_wrapper{
	width:100%;
	height:100%;
	min-height:100%;
	position:relative;
	padding-top:80px;
	box-sizing:border-box;
}
span.tit{
	font-size:32px; 
	font-family: 'S-CoreDream-6Bold';
	text-align:center;
}
:lang(jp) .tit{
	font-size:32px; 
	font-family: 'NotoSansJP-Regular';
	text-align:center;
}
span.color{
	color:#009cc6;
	font-size:inherit;
	font-family:inherit;
	font-weight:inherit;
}
span, p{
	white-space:normal; 
	word-break:keep-all; 
	word-wrap:break-word;
	letter-spacing:-.05rem;
}
:lang(jp) span, p{
	white-space:normal; 
	word-break:keep-all; 
	word-wrap:break-word;
	letter-spacing:-.05rem;
	font-family: 'NotoSansJP-Regular';
}
li{list-style:none;}
:lang(jp) li{list-style:none; font-family: 'NotoSansJP-Regular';}
a{
	text-decoration:none; 
	color:#333333;
}
:lang(jp) a{
	text-decoration:none; 
	color:#333333;
	font-family: 'NotoSansJP-Regular';
}
a:hover{
	color:#f29500;
}
:lang(jp) a:hover{
	color:#f29500;
	font-family: 'NotoSansJP-Regular';
}
.showBounce.on{
	opacity:0;
	-webkit-animation:showBounce .4s ease-out both;
	animation:showBounce .4s ease-out both;
}
.showBounce.sb2{
	-webkit-animation-delay:.1s;
	animation-delay:.1s;
}
.showBounce.sb3{
	-webkit-animation-delay:.2s;
	animation-delay:.2s;
}
.showBounce.sb4{
	-webkit-animation-delay:.3s;
	animation-delay:.3s;
}
.showBounce.sb5{
	-webkit-animation-delay:.4s;
	animation-delay:.4s;
}
.showBounce.sb6{
	-webkit-animation-delay:.5s;
	animation-delay:.5s;
}
.showBounce.sb7{
	-webkit-animation-delay:.6s;
	animation-delay:.6s;
}
/*showBounce*/
@keyframes showBounce {
  0% {
	  opacity:0; 
	  -webkit-transform: translateY(20px);
	  transform: translateY(20px);
	  }
  /* 40% {
	  opacity:1; 
	  -webkit-transform: translateY(-12px);
	  transform: translateY(-12px);
	  } */
  100% {
	  opacity:1; 
	  -webkit-transform: translateY(0px);
	  transform: translateY(0px);
	  }
}
/*fadeInUp*/
.fadeInUp{
	opacity:0;
	-webkit-animation:fadeInUp .8s ease-in-out .1s both;
	animation:fadeInUp .8s ease-in-out .1s both;
}
.fiu2{animation-delay:.8s;}
.fiu3{animation-delay:1.6s;}
.fiu4{animation-delay:2.4s;}
/*fadeInUp*/
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/*bounceIn*/
.bounceIn.on{
	-webkit-animation:bounceIn .6s linear both; 
	animation:bounceIn .6s linear both;
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.5, .5, .5);
    transform: scale3d(.5, .5, .5);
  }

  25% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  50% {
    -webkit-transform: scale3d(.9, .9, .9);
    transform: scale3d(.9, .9, .9);
  }

  75% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/*z-index guide*/
/*
body_bg(반투명 검은 배경) : 130
모바일 메뉴 : 999;
layer_popup : 200~250
float_btn_wrap : 90~99
header : 100~110
contents(.section) : 0~30
footer : 30~40
*/
/*header*/
.header{
	width:100%; 
	height:80px; 
	background:#ffffff; 
	position:fixed; 
	top:0; 
	z-index:100; 
}
.header .inner{
	display:-webkit-box; 
	-webkit-box-align:center; 
	display:flex; 
	align-items:center;
	position:relative;
	z-index:101;
}
.header .logo{
	display:block; 
	width:auto; 
	height:50px; 
	margin-left:22px;
	margin-top:12px;
}
.header .logo img{
	height:100%;
}
.menu_wrapper{
	width:calc(100% - 340px);
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content: center;
	box-sizing:border-box;
	padding-left:28px;
}
.header .menu{
	width:auto;
	display:-webkit-box; 
	display:flex; 
}

/* 문의하기 메뉴 추가 후 */
.menu_list{
	width:130px;
	height:80px;
}
.menu_list.eng{
	width:150px;
	height:80px;
}
.menu_list a{
	width:100%;
	height:100%;
	font-size:15px; 
	text-align:center;
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	justify-content:center;
	align-items:center;
	color:#1a1a1a;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .menu_list a{
	width:100%;
	height:100%;
	font-size:15px; 
	text-align:center;
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	justify-content:center;
	align-items:center;
	color:#1a1a1a;
	font-family: 'NotoSansJP-Regular';
}
.menu_list.dis_a{
	font-size:15px; 
	text-align:center;
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	justify-content:center;
	align-items:center;
	color:#808080;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .menu_list.dis_a{
	font-size:15px; 
	text-align:center;
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	justify-content:center;
	align-items:center;
	color:#808080;
	font-family: 'NotoSansJP-Regular';
}
.menu_list.on{
	background:#009cc6;
}
.menu_list.on a{
	color:#ffffff;
}
.menu_list:nth-child(1).on{
	background:none;
}
.menu_list:nth-child(1).on a{
	color:#009cc6;
}
.menu_list a:hover{
	color:#009cc6;
}
.menu_list.on a:hover{
	color:#ffffff;
}
.menu_list:nth-child(1).on a:hover{
	color:#009cc6;
}
/*login&mypage*/
.header .button{
	display:block; 
	width:167px; 
	height:38px;
	position:relative;
}
:lang(jp) .header .button{
	display:block; 
	width:190px; 
	height:38px;
	position:relative;
}
.header .button .member{
	width:100%; 
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	/* visibility:hidden; */
	visibility:visible;
}
.header .button .member a{
	width:86px; 
	height:auto; 
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	align-items:center; 
	justify-content:center;
	position:relative;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .header .button .member a{
	width:95px; 
	height:auto; 
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	align-items:center; 
	justify-content:center;
	position:relative;
	font-family: 'NotoSansJP-Regular';
}
.header .button .member .login{
	color:#009cc6; 
	width:80px;
}
.header .button .member .login::after{
	content:'|';
	display:block;
	color:#e6e6e6;
	position:absolute;
	right: 0;
}
.header .button .member .join{
	color:#666666;
}
.header .button .member .introduce{
	width:100%; 
	font-size: 14px;
	font-weight: 700;
	color:#666666;
}
/*로그인시 #my_page=display:block, .member=visibility:hidden;*/
/*비로그인시 #my_page=display:none, .member=visibility:visible;*/
#my_page{
	/* display:block;  */
	display:none; 
	width:100%; 
	height:100%; 
	position:absolute; 
	top:0px; 
	left:0px; 
	background:inherit;
}
#my_page .my_cover{
	width:100%; 
	height:100%; 
	position:relative; 
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:end; 
	display:flex; 
	align-items:center; 
	justify-content:flex-end;
	box-sizing:border-box;
	cursor:pointer;
}
.my_nikname{
	width:70px;
	text-align:right;
	overflow:hidden;
	text-overflow:ellipsis; 
	white-space:nowrap;
	color:#666666;
}
#my_page .my{
	width:auto; 
	height:38px; 
	margin-left:26px; 
	cursor:pointer; 
}
#my_page .my img{
	width:auto; 
	height:100%;
}
#my_page .my_menu{
	display:none; 
	width:150px; 
	height:auto; 
	position:absolute; 
	top:64px;  
	left:50%; 
	-webkit-transform:translateX(-50%);
	transform:translateX(-50%);
}
#my_page .my_menu ul{
	display:block; 
	position:absolute;
	width:100%; 
	height:auto; 
	background:#ffffff;
	border-radius:40px;
	border:1px solid #e1e1e1;
	padding:10px;
}
#my_page .my_menu li{
	width:100%; 
	margin:0;
	display:-webkit-box; 
	-webkit-box-align:center; 
	-webkit-box-pack:center; 
	display:flex; 
	align-items:center; 
	justify-content:center; 
}
#my_page .my_menu li:first-child{
	height:65px;
	border-bottom:1px solid #bdbdbd; 
	box-sizing:border-box;
}
#my_page .my_menu li:first-child div{
	width:auto; 
	height:32px; 
	margin-right:10px;
	position:relative;
}
#my_page .my_menu li:first-child div img{
	width:auto; 
	height:100%;
}
#my_page .my_menu li:first-child p{
	display:block; 
	width:70px; 
	overflow:hidden; 
	text-overflow:ellipsis; 
	white-space:nowrap;
}
#my_page .my_menu .page{
	height:44px; 
}
#my_page .my_menu .page a{
	font-size:16px; 
	font-weight:400;
	display:block;
	position:relative; 
}

#my_page2{
	/* display:block;  */
	display:block; 
	width:100%; 
	height:100%; 
	position:absolute; 
	top:59px; 
	left:-980px; 
	background:inherit;
}

/*mobile_menu_btn_area*/
.mobile_menu_btn_area{
	display:none; 
	width:auto;
	height:24px;
}
.my_menu .page a:hover{color:#f29500;}
.mobile_menu_btn_area>img{
	height:100%;
	cursor:pointer;
}
.mobile_menu_area{ 
	overflow:hidden; 
	position:fixed; 
	top:0; 
	left:-100%; 
	z-index:999; 
	width:100%; 
	height:100%;
	display:-webkit-box;
	-webkit-box-pack:start;
	display:flex;
	justify-content:flex-start;
}
.mobile_menu_wrap{
	width:100%;
	height:100%;
	background:#ffffff;
	position:relative;
}
.mobile_menu_wrap .mobile_menu_top_area{
	background:#ffffff;
	position:relative;
	top:0;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
	width:100%;
	height:80px;
	padding:20px;
	box-sizing:border-box;
}
.mobile_menu_wrap .mobile_menu_top_area img{
	height:42px;
	margin-top:12px;
}
.mobile_menu_wrap .mobile_menu_top_area img.back{
	height:24px;
	cursor:pointer;
	margin-top:0;
}
/* mobile_my_area */
/* 비로그인 상태 : .login_wrap{display:block;}, .my_wrap{display:none;} */
/* 로그인 상태 : .login_wrap{display:none;}, .my_wrap{display:block;} */
.mobile_menu_wrap .mobile_my_area{
	position:relative;
	top:0;
	width:100%;
	height:auto;
}
.mobile_my_area .login_wrap{
	/* display:block; */
	display:none;
	width:100%;
	height:60px;
	border-top:1px solid #009cc6;
	border-bottom:1px solid #009cc6;
	box-sizing:border-box;
}
.mobile_my_area .login_wrap>div{
	display:-webkit-box;
	display:flex;
	width:100%;
	height:100%;
}
.mobile_my_area .login_wrap a{
	width:50%;
	height:100%;
	background:#ffffff;
	font-size:20px;
	color:#009cc6;
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}	
.login_wrap a:first-child{
	background:#009cc6;
	color:#ffffff;
}	
.mobile_my_area .my_wrap{
	/* display:none; */
	display:block;
	width:100%;
	height:auto;
}
.mobile_my_area .my_wrap .top_area{
	width:100%;
	height:80px;
	background:#009cc6;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	box-sizing:border-box;
	padding:10px 30px;
}
.mobile_my_area .my_wrap .left_area{
	width:auto;
	height:44px;
	margin-right:24px;
}
.mobile_profile{
	width:auto;
	height:100%;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.mobile_profile img{
	height:100%;
	width:auto;
}
.mobile_my_area .my_wrap .right_area{
	width:auto;
	max-width:180px;
	height:auto;
}
.mobile_my_area .my_wrap .name{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:start;
	display:flex;
	align-items:flex-end;
	justify-content:flex-start;
}
.mobile_my_area .my_wrap .name p{
	width:auto;
	max-width:135px;
	height:auto;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:all;
	word-wrap:break-word;
	text-align:left;
	margin-right:5px;
	font-family:'S-CoreDream-6Bold';
	color:#ffffff;
	line-height:1;
	padding-top:2px;
}
:lang(jp) .mobile_my_area .my_wrap .name p{
	width:auto;
	max-width:135px;
	height:auto;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:all;
	word-wrap:break-word;
	text-align:left;
	margin-right:5px;
	font-family: 'NotoSansJP-Regular';
	color:#ffffff;
	line-height:1;
	padding-top:2px;
}
.mobile_my_area .my_wrap .name span{
	font-size:18px;
	color:#4d4d4d;
	line-height:1;
}
.mobile_my_area .my_wrap .bottom_area{
	width:100%;
	height:40px;
	display:-webkit-box;
	display:flex;
}
.mobile_my_area .my_wrap .bottom_area a{
	width:50%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:14px;
	color:#666666;
	background:#eeeeee;
}
.mobile_my_area .my_wrap .bottom_area a:last-child{
	border-left:1px solid #cccccc;
	color:#bbbbbb;
}
.mobile_menu_wrap .bottom_menu_area{
	position:relative;
	top:0;
	width:100%;
	height:auto;
}
.mobile_menu_wrap .bottom_menu_area a{
	font-size:15px;
	color:#1a1a1a;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	height:50px;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
}
.mobile_menu_wrap .bottom_menu_area .dis_a{
	font-size:15px;
	color:#808080;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	height:50px;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
}
.mobile_menu_wrap .bottom_menu_area .intro{
	font-size:17px;
	color:#276de6;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	height:50px;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
}
.mobile_menu_wrap .bottom_menu_area .language{
	font-size:15px;
	color:#1a1a1a;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	height:50px;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
}

/*footer*/
.footer{
	width:100%;
	height:270px;
	background:#1a1a1a;
	position:relative;
	bottom:0px;
}
.footer .inner90{
	display:-webkit-box;
	display:flex;
	width:1200px;
}
.f_left{
	width:80%;
	height:100%;
}
.f_left ul{
	width:100%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
	-webkit-box-orient:vertical;
	flex-direction:column;
	box-sizing:border-box;
	padding-left:20px;
}
.f_left li{
	font-family: 'S-CoreDream-3Light';
	font-size:14px;
	color:#666666;
	text-align:left;
}
:lang(jp) .f_left li{
	font-family: 'NotoSansJP-Thin';
	font-size:14px;
	color:#666666;
	text-align:left;
}
.f_left li:nth-child(1){
	height:46px;
	width:auto;
	margin-bottom:36px;
}
.f_left li:nth-child(1) img{
	height:100%;
	width:auto;
}
.f_left li:nth-child(2){
	display:-webkit-box;
	display:flex;
	margin-bottom:18px;
}
.f_left li:nth-child(2) a{
	font-family: 'S-CoreDream-3Light';
	font-size:14px;
	color:#e6e6e6;
}
:lang(jp) .f_left li:nth-child(2) a{
	font-family: 'NotoSansJP-Thin';
	font-size:14px;
	color:#e6e6e6;
}
.f_left li:nth-child(2) a:nth-child(2){
	margin:0 24px;
}
.f_left li:nth-child(2) a:nth-child(3){
	color:#009cc6;
}
.f_left li:nth-child(3){
	margin-bottom:32px;
}
.f_right{
	width:20%;
	height:100%;
}
.f_right ul{
	width:100%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
	-webkit-box-orient:vertical;
	flex-direction:column;
}
.f_right li.faq{
	color:#e6e6e6;
	font-family: 'S-CoreDream-6Bold';
	text-align:right;
	margin-bottom:10px;
}
:lang(jp) .f_right li.faq{
	color:#e6e6e6;
	font-family: 'NotoSansJP-Regular';
	text-align:right;
	margin-bottom:10px;
}
.f_right li.faq a{
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
	font-size:38px;
}
:lang(jp) .f_right li.faq a{
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
	font-size:38px;
}
.f_right li.faq a:hover{
	color:#009cc6;
}
.f_right li.inquiry{
	color:#666666;
	font-size:18px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
}
:lang(jp) .f_right li.inquiry{
	color:#666666;
	font-size:18px;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
}

/*login.html*/
.login_title{
	display:block; 
	margin:0 auto; 
	position:relative; 
	font-size:34px; 
	text-align:center;
	color:#009cc6; 
	top:32px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .login_title{
	display:block; 
	margin:0 auto; 
	position:relative; 
	font-size:34px; 
	text-align:center;
	color:#009cc6; 
	top:32px;
	font-family: 'NotoSansJP-Regular';
}
.login_sub_title{
	display:block; 
	margin:0 auto; 
	position:relative; 
	font-size:16px; 
	text-align:center;
	color:#009cc6; 
	top:32px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .login_sub_title{
	display:block; 
	margin:0 auto; 
	position:relative; 
	font-size:16px; 
	text-align:center;
	color:#009cc6; 
	top:32px;
	font-family: 'NotoSansJP-Regular';
}
.login_sub_content{
	display:block; 
	margin:0 auto; 
	position:relative; 
	font-size:24px; 
	text-align:center;
	color:#009cc6; 
	top:32px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .login_sub_content{
	display:block; 
	margin:0 auto; 
	position:relative; 
	font-size:24px; 
	text-align:center;
	color:#009cc6; 
	top:32px;
	font-family: 'NotoSansJP-Regular';
}
.center_align{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.login_bg_wrapper{
	font-size:18px; 
	margin:0 auto; 
	color:#333333; 
	background:#009cc6; 
	width:100%; 
	height:100%; 
	/* min-height:680px;  */
	position:absolute; 
	top:0; 
	left:0; 
	display:-webkit-box; 
	-webkit-box-align:center; 
	display:flex; 
	align-items:center;
}
.login_bg_wrapper.password_bg_wrapper{
	min-height:400px;
}
.login_bg_wrapper.email_bg_wrapper{
	min-height:400px;
}
.login_bg_wrapper.join_bg_wrapper{
	/* min-height:820px; */
	min-height:1020px;
}
.login_bg_wrapper.join_group_bg_wrapper{
	/* min-height:860px; */
	min-height:1140px;
}
.login_bg_wrapper.stuedent_make_bg_wrapper{
	/* min-height:900px; */
	min-height:1240px;
}
.login_bg_wrapper.join_agree_bg_wrapper{
	min-height:1100px; 
	line-height: 1.5;
}
.middle{
	position:relative; 
	margin:0 auto;
}

.login_top{
	width:100%; 
	height:20px; 
	position:relative; 
	top:0;
}
.login_top a.logo{
	display:block; 
	height:100%; 
	position:absolute;
}
.login_top a.logo img{height:40px;}
.login_top a.to_join{
	font-size:15px; 
	color:#999999; 
	position:absolute; 
	right:0;
}
:lang(jp) .login_top a.to_join{
	font-size:15px; 
	color:#999999; 
	position:absolute; 
	right:0;
	font-family: 'NotoSansJP-Thin';
}
a.ok{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:auto; 
	height:36px; 
	background:#009cc6; 
	border-radius:30px; 
	color:#ffffff; 
	font-weight:400; 
	text-align:center; 
	padding:5px 30px;
	padding-top:7px;
	box-sizing:border-box; 
	margin-top:20px;
}
a.ok:hover{color:#ffffff;}
div.input_area{
	width:100%; 
	height:55px; 
	box-sizing:border-box; 
	font-size:20px; 
	/* background:pink;  */
	margin-top:20px;
}
.check_wrap{
	height:20px; 
	display:-webkit-box; 
	-webkit-box-align:center;
	display:flex;
}
/*로그인 login.html*/
.login_area{
	width:420px; 
	/* height:650px;  */
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box; 
}
.login_area .loginpage_wrap{
	width:100%; 
	height:auto; 
	margin-top:25px;
}
.login_area .input_area.password{top:70px;}
.box_under_wrap{
	width:100%; 
	height:20px; 
	position:relative; 
	top:0px; 
	margin-top:30px;
	display:-webkit-box; 
	-webkit-box-align:center; 
	display:flex;
}
.login_area a.change{
	display:block; 
	width:100px; 
	font-size:14px; 
	font-weight:400; 
	color:#009cc6;  
	position:absolute; 
	left: 160px;
	background-color: #009cc6;
	color: white;
	border: none;
	border-radius: 20px;
	text-align: center;
}
.login_area a.change2{
	display:block; 
	width:84px; 
	font-size:14px; 
	font-weight:400; 
	color:#009cc6;  
	position:absolute; 
	right:0;
	background-color: #009cc6;
	color: white;
	border: none;
	border-radius: 20px;
	text-align: center;
}
.login_area .line{
	width:100%; 
	height:1px; 
	background:#009cc6; 
	margin-top:30px;
}
.login_area span.sub_tit{
	text-align:center; 
	color:#444444; 
	display:block; 
	margin:0 auto; 
	margin-top:22px;
}
/* 간편로그인 버튼 */
.login_area button{
	height:44px;
	width:300px;
	background:#000000;
	cursor:pointer;
	border-radius:30px;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	overflow:hidden;
	margin-top:16px;
	position:relative;
}
.login_area button img{
	height:100%;
}
:lang(ko) .login_area button.apple::after{
	content:'Apple로 로그인';
	position:absolute;
	width:100%;
	height:100%;
	color:#ffffff;
	font-size:19px;
	font-family:'Roboto', AppleSDGothicNeo-Regular,sans-serif;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
:lang(en) .login_area button.apple::after{
	content:'Sign in with Apple';
	position:absolute;
	width:100%;
	height:100%;
	color:#ffffff;
	font-size:19px;
	font-family:'Roboto', AppleSDGothicNeo-Regular,sans-serif;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
:lang(jp) .login_area button.apple::after{
	content:'Appleでサインイン';
	position:absolute;
	width:100%;
	height:100%;
	color:#ffffff;
	font-size:19px;
	font-family:'Roboto', AppleSDGothicNeo-Regular,sans-serif;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
.login_area button.google{
	background:#ffffff;
	box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, .24);
	margin-top:22px;
}
:lang(ko) .login_area button.google::after{
	content:'Google로 로그인';
	position:absolute;
	width:100%;
	height:100%;
	color:#3c4043;
	font-size:19px;
	font-family:'Roboto',sans-serif;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
:lang(en) .login_area button.google::after{
	content:'Sign in with Google';
	position:absolute;
	width:100%;
	height:100%;
	color:#3c4043;
	font-size:19px;
	font-family:'Roboto',sans-serif;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
:lang(jp) .login_area button.google::after{
	content:'Googleでサインイン';
	position:absolute;
	width:100%;
	height:100%;
	color:#3c4043;
	font-size:19px;
	font-family:'Roboto',sans-serif;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
.login_area button.google img{
	height:18px;
	margin-left:14.5px;
}
.login_area .easy_login_info{
	width:80%;
	position:relative;
	margin-top:2px;
}
.login_area .easy_login_info li{
	font-size:12px;
	text-align:center;
	color:#3b3b3b;
}

/*password_reset_area*/
.password_reset_area{
	width:500px; 
	/* height:420px;  */
	background:#ffffff; 
	border-radius:20px; 
	padding:30px;  
	box-sizing:border-box; 
	padding-bottom: 50px;
}
.password_reset_area .password_reset_wrap{
	width:100%; 
	height:220px; 
	margin-top:30px;
}
.password_reset_area .password_reset_wrap p{
	text-align:center; 
	width:100%;
	font-size:16px;
	margin-top:16px;
}
.password_reset_area .password_reset_wrap .password_reset_input_area.input_area {
	width: 100%; 
	height: 40px; 
	box-sizing: border-box; 
	font-size: 20px; 
	margin-top: 10px;
}
.password_reset_area .password_reset_wrap .ok {margin-top: 60px;}

/*email_reset_area*/
.email_reset_area{
	width:600px; 
	/* height:770px;  */
	background:#ffffff; 
	border-radius:20px; 
	padding:30px;  
	box-sizing:border-box; 
	padding-bottom: 50px;
}
.email_reset_area .email_reset_wrap{
	width:100%; 
	/* height:100px;  */
	margin-top:60px;
	margin-bottom: 20px;
}
.email_reset_area .email_reset_wrap p{
	text-align:center; 
	/* width:100%; */
	width:80%;
	font-size:16px;
	/* margin-top:16px; */
}
.email_reset_area .email_reset_wrap .email_reset_input_area.input_area {
	width: 100%; 
	height: 40px; 
	box-sizing: border-box; 
	font-size: 20px; 
	margin-top: 10px;
}
.email_reset_area .email_reset_wrap .ok {margin-top: 10px;}

/*email_reset_area2*/
.email_reset_area2{
	width:500px; 
	height:580px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px;  
	box-sizing:border-box; 
}
.email_reset_area2 .email_reset_wrap{
	width:100%; 
	height:100px; 
	margin-top:30px;
}
.email_reset_area2 .email_reset_wrap p{
	text-align:center; 
	width:100%;
	font-size:16px;
	margin-top:16px;
}
.email_reset_area2 .email_reset_wrap .email_reset_input_area.input_area {
	width: 100%; 
	height: 40px; 
	box-sizing: border-box; 
	font-size: 20px; 
	margin-top: 10px;
}
.email_reset_area2 .email_reset_wrap .ok {margin-top: 230px;}

/*회원가입 join.html*/
.join_area{
	width:420px; 
	height:900px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box;
	margin-top: 30px;
	margin-bottom: 30px;
}
.join_area .join_wrap{
	width:100%; 
	/* height:540px;  */
	height:670px; 
	margin-top:50px;
}
.join_area .input_area.email{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	position:relative;
}
:lang(ko) .join_area .input_area.email.overlap::before{
	content:'중복된 이메일입니다';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:110%;
	left:10px;
}
:lang(en) .join_area .input_area.email.overlap::before{
	content:'Duplicate email';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:110%;
	left:10px;
}
:lang(jp) .join_area .input_area.email.overlap::before{
	content:'重複メールです';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'NotoSansJP-Thin';
	position:absolute;
	top:110%;
	left:10px;
}
:lang(ko) .join_area .input_area.email.overlapno::before{
	content:'사용 가능한 이메일입니다';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:110%;
	left:10px;
}
:lang(en) .join_area .input_area.email.overlapno::before{
	content:'This email is available';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:110%;
	left:10px;
}
:lang(jp) .join_area .input_area.email.overlapno::before{
	content:'使用可能なメールアドレスです';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'NotoSansJP-Thin';
	position:absolute;
	top:110%;
	left:10px;
}
.join_area .input_area.email .input_area{
	margin-top:0;
	max-width:250px;
	margin-right:12px;
}
.join_area .email button{
	width:90px;
	height:35px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	background:#ffffff;
	border-radius:17px;
	font-size:13px;
	color:#808080;
	cursor:pointer;
}
.join_area .input_area.password{
	margin-top:40px;
	margin-bottom: 30px;
}
.join_area .input_area.password p{
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position: relative;
	top:5px;
	bottom: 50px;
	left:10px;
}
:lang(jp) .join_area .input_area.password p{
	color:#009cc6;
	font-size:13px;
	font-family:'NotoSansJP-Thin';
	position: relative;
	top:5px;
	bottom: 50px;
	left:10px;
}
.join_area .input_area.addstring p{
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position: relative;
	top:45px;
	left:10px;
}
:lang(jp) .join_area .input_area.addstring p{
	color:#009cc6;
	font-size:13px;
	font-family:'NotoSansJP-Thin';
	position: relative;
	top:45px;
	left:10px;
}
.join_area .input_area.hr1{
	margin-top:30px;
	margin-bottom: -20px;
}
.join_area .input_area.hr2{
	margin-top:30px;
	margin-bottom: -10px;
}
.join_area .input_area.name{
	margin-top:32px;
}
.join_area .join_wrap .select_area{
	width:100%; 
	height:40px; 
	margin-top:20px;
}
.join_area .join_wrap .select_area select{
	width:50%; 
	height:100%;
}
.join_area .join_wrap .select_area span{
	margin-left:10px;
}
.join_area .join_wrap .select_area .select_name{
	color:#009cc6;
	font-size:15px;
	font-family:'S-CoreDream-3Light';
	position: relative;
}
.join_area .join_wrap a.ok{
	margin-top:84px;
	width:190px;
	height:45px;
}

.join_area .join_wrap .tel{
	height:70px; 
	margin-bottom:20px; 
	background:none;
}
.join_area .join_wrap .tel p{
	font-size:16px;
}
.join_area .join_wrap .tel .input_area{
	width:30%; 
	float:left; 
	margin-right:5%;
	position:relative;
	top:-20px;
}
:lang(en) .join_area .join_wrap .tel .input_area{
	width:20%; 
	float:left; 
	margin-right:5%;
	position:relative;
	top:-20px;
}
:lang(jp) .join_area .join_wrap .tel .input_area{
	width:20%; 
	float:left; 
	margin-right:5%;
	position:relative;
	top:-20px;
}
.join_area .join_wrap .tel .input_area.box3{
	margin-right:0;
}
.join_area .join_wrap .tel .input_area.box0{
	width: 90px;
	display: flex; /* Flexbox 활성화 */
	justify-content: center;  /* 수평 중앙 정렬 */
	align-items: center;      /* 수직 중앙 정렬 */
}

/*join_group_area*/
.join_group_area{
	width:420px; 
	height:1040px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box;
	margin-top: 30px;
}
.join_group_area .join_group_wrap{
	width:100%;
	height:520px; 
	margin-top:50px;
}
.join_group_wrap .input_area>p{
	margin:5px 0;
}
.join_group_area .join_group_wrap .number{
	height:63px;
	background:none;
	margin-top:10px;
}
.join_group_area .join_group_wrap .number p{
	font-size:15px;
	margin-bottom: 10px;
}
.join_group_area .join_group_wrap .number .input_area{
	width:30%; 
	float:left; 
	margin-right:5%; 
	position:relative; 
	top:-20px;
	margin-top: 10px;
	height: 40px;
}
.join_group_area .join_group_wrap .number .input_area.box3{
	margin-right:0;
}
.join_group_wrap .input_area.fax{
	margin-top:50px;
}
.join_group_area .join_group_wrap .select_area{
	background:none;
	margin-top:30px;
}
.join_group_area .join_group_wrap .select_area select{
	width:50%; 
	height:100%;
}
.join_group_area .join_group_wrap .select_area .select_name{
	margin-left:10px; 
	font-size:14px;
}
.post_srch_wrap{
	width:100%;
	height:36px;
	margin-top:20px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.join_group_area .post_srch_wrap a.post_srch{
	width:150px; 
	height:30px; 
	background:#ffffff; 
	border:1px solid #009cc6; 
	box-sizing:border-box; 
	font-size:14px; 
	color:#009cc6; 
	text-align:center; 
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.join_group_area .join_group_wrap .addstring p{
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position: relative;
	top:0px;
	left:0px;
}
.join_group_area .input_area.hr1{
	margin-top:20px;
	margin-bottom:0px;
}
.join_group_area .join_group_wrap .post_number{
	margin-top:20px;
	margin-bottom:10px;
}
.join_group_area .join_group_wrap .adress{
	margin-top:20px;
	margin-bottom:10px;
}
.join_group_area .join_group_wrap .adress_more{
	margin-top:20px;
	margin-bottom:10px;
}

.dimmed_layer_wrapper{
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	background:#00000070;
	overflow:hidden;
	z-index:999;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.popClosePosition{
	display:none;
    /* background: white; */
    /* width: 40px; */
    /* height: 24px; */
    /* position: relative; */
    /* margin: auto; */
    /* top: 4px; */
    /* padding: 1px; */
    /* text-align: center; */
    /* font-weight: bold; */
    /* border: solid 1px #333; */
    /* border-radius: 15%; */
    /* cursor: pointer; */
}

.join_group_area .adress_box{
	margin-top:16px; 
	height:36px; 
	font-size:18px;
}
.join_group_area a.ok{
	margin-top:40px;
	width: 180px;
	height: 45px;
}

/*학습자 생성 stuedent_make.html*/
.stuedent_make_area{
	width:420px; 
	/* height:auto; */
	min-height:1100px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box;
	margin-top: 30px;
}
.stuedent_make_area .stuedent_make_wrap{
	width:100%; 
	height:auto; 
	margin-top:50px;
}
.stuedent_make_area .input_area.nikname{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	position:relative;
}
:lang(ko) .stuedent_make_area .input_area.nikname.nokorean::before{
	content:'2~50자리의 영어와 숫자만 입력해주세요';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
:lang(en) .stuedent_make_area .input_area.nikname.nokorean::before{
	content:'Please enter 2-50 digits of English and numbers';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
:lang(jp) .stuedent_make_area .input_area.nikname.nokorean::before{
	content:'2~50桁の英語と数字だけ入力してください';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
:lang(ko) .stuedent_make_area .input_area.nikname.overlap::before{
	content:'중복된 아이디입니다';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
:lang(en) .stuedent_make_area .input_area.nikname.overlap::before{
	content:'Duplicate ID';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
:lang(jp) .stuedent_make_area .input_area.nikname.overlap::before{
	content:'重複 ID';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
:lang(ko) .stuedent_make_area .input_area.nikname.overlapno::before{
	content:'사용 가능한 아이디입니다';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
:lang(en) .stuedent_make_area .input_area.nikname.overlapno::before{
	content:'You can use this ID';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
:lang(jp) .stuedent_make_area .input_area.nikname.overlapno::before{
	content:'このIDを使用することができます';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:104%;
	left:10px;
}
.stuedent_make_area .input_area.nikname .input_area{
	margin-top:0;
	max-width:250px;
	margin-right:12px;
}
.stuedent_make_area .input_area.nikname button{
	width:90px;
	height:35px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	background:#ffffff;
	border-radius:17px;
	font-size:13px;
	color:#808080;
	cursor:pointer;
}
.stuedent_make_area .input_area.hr1{
	margin-top:30px;
	margin-bottom:-70px;
}
.stuedent_make_area .input_area.hr2{
	margin-top:20px;
	margin-bottom:-70px;
}
.stuedent_make_area .input_area.password{
	margin-top:38px;
	margin-bottom: 30px;
}
.stuedent_make_area .input_area.password p{
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position: relative;
	top:5px;
	left:10px;
}
.stuedent_make_area .input_area.addstring{
	margin-top:0px;
	margin-bottom: -40px;
}
.stuedent_make_area .input_area.addstring p{
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position: relative;
	top:25px;
	left:10px;
}
.stuedent_make_area .input_area.addstring2{
	margin-top:-15px;
	margin-bottom: -40px;
}
.stuedent_make_area .input_area.addstring2 p{
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position: relative;
	top:25px;
	left:10px;
}
.stuedent_make_area .input_area.addstring3{
	margin-top:-15px;
	margin-bottom: -20px;
}
.stuedent_make_area .input_area.addstring3 p{
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position: relative;
	top:25px;
	left:10px;
}
.stuedent_make_area .input_area.name{
	margin-top:30px;
}
.stuedent_make_area .input_area.name p{
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position: relative;
	top:25px;
	left:10px;
}
.stuedent_make_area .input_area.over_check_next{
	margin-top:50px;
}
.stuedent_make_area .stuedent_make_wrap a.ok{
	margin-top:86px;
	width: 260px;
	height: 45px;
}


/*join_agree*/
.join_agree_area{
	width:1000px; 
	height:auto; 
	min-height:1000px; 
	background:#ffffff; 
	border-radius:20px; 
	padding:30px; 
	box-sizing:border-box;
}
.join_agree_area a.agree_logo img{
	width:120px; 
	display:block; 
	margin:0 auto; 
	margin-top:30px;
}
.join_agree_area .join_agree_wrap{
	width:100%; 
	height:auto; 
	margin-top:50px;
}
.join_agree_area .join_agree_wrap .sub_tit{
	font-size:20px; 
	font-weight:700; 
	text-align:left;
}
/*agree_use_wrap*/
.join_agree_area .join_agree_wrap .agree_use_wrap{
	width:100%; 
	height:270px; 
	overflow-y:scroll; 
	border:1px solid #333333; 
	box-sizing:border-box;
	margin-top:20px;
}
.join_agree_area .join_agree_wrap .agree_use_wrap>div{
	width:100%; 
	height:auto;
}
.join_agree_area .agree_use_wrap span.tit{
	color:#333333; 
	text-align:left; 
	top:0; 
	padding-left:10px; 
	font-size:28px;
}
.join_agree_area .agree_use_wrap .wrap{
	width:98%; 
	height:auto; 
	margin:0 auto;
}
.join_agree_area .agree_use_wrap .wrap .privacy_area.area1{
	margin-top:0;
}
.join_agree_area .agree_use_wrap .wrap .privacy_area.area7{
	margin-bottom:0;
}
.join_agree_area .agree_use_wrap .wrap .privacy_area{
	width:100%; 
	height:inherit;
	position:relative; 
	top:0; 
	margin:60px 0;
}
.join_agree_area .agree_use_wrap .wrap .privacy_area span.tit{
	font-size:24px; 
	padding-left:0;
}
.join_agree_area .agree_use_wrap .wrap .privacy_area span.sub_tit{
	font-weight:500; 
	text-align:left;
}
.join_agree_area .agree_use_wrap .wrap p{
	font-size:18px; 
	text-align:left;
	padding:10px 20px; 
	white-space:normal; 
	word-break:keep-all; 
	word-wrap:break-word;
}
/*agree_privacy_wrap*/
.agree_privacy_wrap .wrap .privacy_area.area12{
	margin-bottom:0;
}
.agree_privacy_wrap .wrap .privacy_area span.tit{
	font-size:24px; 
	padding-left:0; 
	margin-bottom:20px;
}
.agree_privacy_wrap .wrap .privacy_info{
	width:100%; 
	height:auto; 
	margin-bottom:40px; 
	margin-top:20px;
}
.agree_privacy_wrap .wrap .privacy_info p{
	padding:0; 
	padding-left:5px;
}

.agree_privacy_wrap .wrap .privacy_area.area1 .box_wrap{
	width:100%; 
	height:auto;
	padding:20px; 
	box-sizing:border-box; 
	position:relative; 
	top:0px;
}
.agree_privacy_wrap .box_wrap .title{
	width:100%; 
	height:70px;
	background:#009cc6;
	position:relative;
	top:0;
	left:0;
	display:-webkit-box;
	display:flex;
}
.agree_privacy_wrap .box_wrap .title span{
	color:#ffffff;
	box-sizing:border-box;
	text-align:center;
	height:100%;
	width:76%;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
.agree_privacy_wrap .box_wrap .title span:first-child{
	border-right:1px solid #ffffff;
	width:24%;
}
.agree_privacy_wrap .box_wrap .list{
	width:100%; 
	height:auto;
	background:#ffffff;
	border-bottom:1px solid #eaeaea;
	box-sizing:border-box;
	position:relative;
	top:0; 
	display:-webkit-box;
	display:flex;
}
.agree_privacy_wrap .box_wrap .list span{
	box-sizing:border-box; 
	width:76%;
	height:auto;
	padding:30px;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.agree_privacy_wrap .box_wrap .list span.name{
	width:24%;
	height:inherit;
	border-right:1px solid #eaeaea;
	text-align:center; 
	background:#fbfbfb;
	-webkit-box-pack:center;
	justify-content:center;
	padding:0;
}
/*agree_check_wrap*/
.join_agree_area .check_wrap{
	height:36px; 
	align-items:center; 
	margin-top:10px; 
	margin-bottom:20px;
}
.join_agree_area .check_wrap input{
	width:16px; 
	height:16px; 
	margin-right:10px;
}
/*agree_btn*/
.join_agree_area .agree_btn{
	width:220px; 
	height:36px; 
	margin:0 auto;
	margin-top:30px;
	display:-webkit-box; 
	display:flex;
}
.join_agree_area .agree_btn a{
	width:100px; 
	height:36px; 
	background:#ffffff;
	border:1px solid #009cc6;
	box-sizing:border-box; 
	color:#009cc6; 
	text-align:center; 
	border-radius:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.join_agree_area .agree_btn a.agree_continue{
	background:#009cc6; 
	color:#ffffff; 
	margin-left:20px;
}

/*index.html*/
/* .notice_popup_bg{
	display:none;
	background:#00000090;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
	z-index:130;
} */
.notice_popup_bg{
	display:none;
	position:fixed;
	top:40%;
	left:5%;
	z-index:130;
}
.notice_popup div{
	display:block;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.notice_popup_area{
	display:block;
	width:390px;
	height:auto;
	position:absolute;
	top:30%;
	left:5%;
	-webkit-transform:translate(0,-50%);
	transform:translate(0,-50%);
	z-index:140;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:flex-start;
	justify-content:center;
	flex-direction:column;
}
.notice_popup_area .no_more_show_wrap{
	width:auto;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	margin-top:4px;
}
#notice_popup_no_more_check{
	margin-right:6px;
	width:13px;
	height:13px;
	cursor:pointer;
}
.notice_popup_area .no_more_show{
	font-size:13px;
	color:#e6e6e6;
}
.notice_popup{
	width:390px;
	height:auto;
	background:#ffffff;
	position:relative;
	box-sizing:border-box;
	padding:20px;
	border-radius:15px;
}
.notice_popup_area button{
	width:32px;
	height:32px;
	background:url(../assets/back_icon.png) no-repeat center;
	border-radius:50%;
	overflow:hidden;
	border:none;
	outline:none;
	cursor:pointer;
	position:absolute;
	top:30px;
	right:19px;
	z-index:200;
}
.notice_popup .notice_popup_content{
	width:100%;
	height:auto;
	position:relative;
	box-sizing:border-box;
	padding-top:16px;
}
.notice_popup p{
	font-size:15px;
	width:100%;
	height:auto;
}
.notice_popup p.mobile_horizontal{
	font-size:12px;
	display:none;
	line-height:1.2;
}
.notice_popup p span{
	font-size:inherit;
	line-height:inherit;
	color:#1e83d6;
}

.email_banner_popup{
	display:none;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.email_banner_popup div{
	width:300px;
	height:170px;
	background:#ffffff;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
	box-sizing:border-box;
	padding:30px;
	border-radius:30px;
}
.email_banner_popup p{
	font-size:18px;
	text-align:center;
	position:relative;
	z-index:141;
}
.email_banner_popup div.btn_area{
	width:100%;
	height:36px;
	position:relative;
	top:0;
	left:0;
	padding:0;
	background:none;
	-webkit-transform:translate(0,0);
	transform:translate(0,0);
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:14px;
}
.email_banner_popup .confirm{
	width:40%;
	height:100%;
	position:relative;
	border:none;
	background:#009cc6;
	color:#ffffff;
	border-radius:30px;
	cursor:pointer;
}

.stakato{
	font-size:inherit;
	font-family:inherit;
	text-align:inherit;
	letter-spacing:inherit;
	color:#f9be2d;
	position:relative;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.stakato::before{
	content:'';
	display:block;
	width:.8vh;
	height:.8vh;
	position:absolute;
	top:-1.5vh;
	border-radius:50%;
	background:#f9be2d;
}
.stakato:first-child{
	margin-left:1vw;
}
.white{
	color:#ffffff;
	font-size:inherit;
	font-family:inherit;
	text-align:inherit;
	letter-spacing:inherit;
}
.index_tit{
	font-size:1.45em;
	text-align:center;
	color:#3b3b3b;
	font-family: 'S-CoreDream-8Heavy';
	display:-webkit-box;
	display:flex;
}
:lang(jp) .index_tit{
	font-size:1.45em;
	text-align:center;
	color:#3b3b3b;
	font-family: 'NotoSansJP-Bold';
	display:-webkit-box;
	display:flex;
}
.sub_title{
	display:-webkit-box;
	display:flex;
	font-size:0.9em;
	font-family: 'S-CoreDream-6Bold';
	text-align:center;
	color:#3b3b3b;
}
:lang(jp) .sub_title{
	display:-webkit-box;
	display:flex;
	font-size:0.9em;
	font-family: 'NotoSansJP-Regular';
	text-align:center;
	color:#3b3b3b;
}
p.txt{
	font-size:.85em; 
	text-align:center;
}
/*main_banner_area */
.main_banner_area{
	padding-top:0;
}
.main_banner_area .bn_wrap{
	width:auto;
	height:100%; 
	position:relative; 
	top:0; 
	left:0;
}
.bn{
	width:100%; 
	height:100%;
	display:block;
	position:absolute; 
	top:0; 
	left:0;
	background:#f9be2d;
	opacity:0;
	-webkit-transition:all .5s ease-in;
	transition:all .5s ease-in;
}
.bn.active{
	z-index:10;
	opacity:1;
}
.bn_content_wrap{
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:20;
	box-sizing:border-box;
	padding-top:17.2vh;
	padding-bottom:9.75vh;
}
.bn_content_wrap .inner90{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
}
.bn_content_wrap .top_area{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
/* 출시 전 이메일 배너 */
/* .bn.open{
	background:url(../assets/open_banner_bg.png) no-repeat center;
	background-size:cover;
} */
.bn.open{
	background:url(../assets/ios_bg.png) no-repeat center;
	background-size:cover;
}
.bn.open .top_area{
	position:relative;
	z-index:10;
}
.bn.open .top_area img{
	height:107px;
}
.bn.open .top_area span{
	font-family: 'CookieRun-Regular';
	color:#ffffff;
	font-size:1.1em;
	text-shadow:0 1.7px #333333;
	line-height:1;
	margin-top:24px;
}
:lang(jp) .bn.open .top_area span{
	font-family: 'NotoSansJP-Regular';
	color:#ffffff;
	font-size:1.1em;
	text-shadow:0 1.7px #333333;
	line-height:1;
	margin-top:24px;
}
.bn.open .top_area span.title{
	font-family: 'CookieRunOTF-Black';
	font-size:4em;
	text-shadow:0 2px #333333;
	margin-top:6px;
}
:lang(jp) .bn.open .top_area span.title{
	font-family: 'NotoSansJP-Black';
	font-size:4em;
	text-shadow:0 2px #333333;
	margin-top:6px;
}
.bn.open .top_area p{
	font-size:1.1em;
	color:#333333;
	line-height:1;
	margin-top:27px;
	text-align:center;
}
.bn.open .top_area p.second_p{
	margin-top:8px;
}
.bn.open .top_area span.strong{
	text-shadow:none;
	font-size:1.9em;
	color:#fbb03b;
}
.bn.open .top_area span.strong2{
	text-shadow:none;
	font-size:1.3em;
	color:#f7931e;
}
.bn.open .top_area p.input_info{
	font-size:.8em;
	color:#4d4d4d;
	margin-top:36px;
	line-height:1.5;
}
.bn.open .top_area p.input_info br{
	display:none;
}
.bn.open .top_area p.input_info br.enter{
	display:block;
}
.bn.open .open_email_input_area{
	width:492px;
	height:46px;
	border-radius:23px;
	overflow:hidden;
	position:relative;
	margin-top:8px;
	display:-webkit-box;
	display:flex;
	box-sizing:border-box;
	border:2px solid #e6e6e6;
}
.bn.open #banner_email_input{
	width:343px;
	height:100%;
	font-size:20px;
	box-sizing:border-box;
	padding:0 20px;
	border-radius:23px 0 0 23px;
	border:none;
	text-align:center;
}
.bn.open #banner_email_input::placeholder{
	color:#cccccc;
}
.bn.open .open_email_input_area button{
	width:149px;
	height:100%;
	background:#1391ff;
	color:#ffffff;
	font-size:20px;
	border:none;
	cursor:pointer;
}
.bn.open .img_area{
	position:relative;
	width:100%;
	margin-top:-80px;
}
.bn.open .img_area .base,
.bn.open .img_area .top{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
.bn.open .img_area .top{
	position:absolute;
	top:0;
}
.bn.open .img_area .bottom{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
.bn.open .img_area .bottom{
	position:relative;
	top:20px;
}
/* .bn.open .img_area .base img{
	width:53.5%;
	height:auto;
	margin-top:0;
} */
.bn.open .img_area .base img{
	width:45%;
	height:auto;
	margin-top:0;
}
.bn.open .img_area .top img{
	width:30.5%;
	height:auto;
	margin-top:6%;
	-webkit-animation-delay:.8s;
	animation-delay:.8s;
}
/* .bn.open .img_area .bottom img{
	width:30.5%;
	height:auto;
	margin-top:6%;
	-webkit-animation-delay:.8s;
	animation-delay:.8s;
} */
.bn.open .img_area .bottom .bounceIn .on img{
	width:30.5%;
	height:auto;
	margin-top:6%;
	-webkit-animation-delay:.8s;
	animation-delay:.8s;
}

/* 인기브랜드대상 배너 */
.bn.popular{
	background:url(../assets/popular_bn_bg3.png) no-repeat center;
	background-size:cover;
	overflow:hidden;
}
/* .bn.popular .inner90{
	background:#01ff0d50;
} */
.bn.popular .img_area{
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:10;
}
.bn.popular .top,
.bn.popular .bottom{
	width:100%;
	height:50%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
}
.bn.popular .bottom{
	-webkit-box-align:end;
	align-items:flex-end;
	/* background:#ff46c950; */
}
.bn.popular .img_area .bounceIn{
	position:relative;
	width:auto;
	height:15%;
}
.bn.popular .bn_baloon1,
.bn.popular .bn_baloon2{
	position:relative;
	height:100%;
	-webkit-animation-delay:.7s;
	animation-delay:.7s;
}
.bn.popular .bn_baloon1.on{
	-webkit-animation:bnBaloon1 3s linear infinite alternate both;
	animation:bnBaloon1 3s linear infinite alternate both;
}
.bn.popular .bn_baloon2.on{
	-webkit-animation:bnBaloon2 3s linear infinite alternate both;
	animation:bnBaloon2 3s linear infinite alternate both;
}
@keyframes bnBaloon1{
	0%{
		-webkit-transform:translateY(0px);
		transform:translateY(0px);
		}
	100%{
		-webkit-transform:translateY(20px);
		transform:translateY(20px);
		}
}
@keyframes bnBaloon2{
	0%{
		-webkit-transform:translateY(20px);
		transform:translateY(20px);
		}
	100%{
		-webkit-transform:translateY(0px);
		transform:translateY(0px);
		}
}
.bn.popular .top .star1{
	height:15%;
	top:60%;
	left:-26%;
}
.bn.popular .top .star2{
	height:18%;
	top:-4%;
	left:-21%;
}
.bn.popular .top .star3{
	height:25%;
	top:-15%;
	left:26%;
}
.bn.popular .top .star4{
	height:12%;
	top:72%;
	left:30%;
}
.bn.popular .top .star5{
	height:21%;
	top:31%;
	left:34%;
}
.bn.popular .top .star6{
	height:18%;
	top:0%;
	left:40%;
}
.bn.popular .bottom .star1{
	height:12%;
	top:-76%;
	left:-36%;
}
.bn.popular .bottom .star2{
	height:18%;
	top:-16%;
	left:-35%;
}
.bn.popular .bottom .star3{
	height:24%;
	top:-72%;
	left:-31%;
}
.bn.popular .bottom .star4{
	height:14%;
	top:-25%;
	left:-22%;
}
.bn.popular .bottom .star5{
	height:9%;
	top:-40%;
	left:18%;
}
.bn.popular .bottom .star6{
	height:30%;
	top:-12%;
	left:31%;
}
.bn.popular .bottom .star7{
	height:16%;
	top:-66%;
	left:31%;
}

.bn.popular .txt{
	height:15%;
	-webkit-animation-delay:.1s;
	animation-delay:.1s;
	margin-bottom: 40px;
	/*border:#fafafa solid;*/
}
.bn.popular .tropy{
	height:74%;
	-webkit-animation-delay:.4s;
	animation-delay:.4s;
}

/* 오픈세일배너 */
.bn.sale{
	background:url(../assets/sale_bn_bg.png) no-repeat center;
	background-size:cover;
	overflow:hidden;
}
.bn.sale .bn_content_wrap .inner90{
	-webkit-box-pack:start;
	justify-content:flex-start;
}
.bn.sale .bottom_coin_area{
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:8;
	background:url(../assets/sale_bn_bottom_coin.png) no-repeat center;
	background-size:cover;
}
.bn.sale .txt1{
	height:12%;
	margin-top:1.7%;
	-webkit-animation-delay:.1s;
	animation-delay:.1s;
}
.bn.sale .txt2{
	height:46.5%;
	margin-top:2%;
	-webkit-animation-delay:.3s;
	animation-delay:.3s;
}
.bn.sale .img_area{
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:10;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	/* background:#01ff0d50; */
}
.bn.sale .img_area .bounceIn{
	position:relative;
	width:auto;
	height:15%;
}
.bn.sale .bn_baloon1,
.bn.sale .bn_baloon2{
	position:relative;
	height:100%;
	-webkit-animation-delay:.7s;
	animation-delay:.7s;
}
.bn.sale .bn_baloon1.on{
	-webkit-animation:bnBaloon1 3s linear infinite alternate both;
	animation:bnBaloon1 3s linear infinite alternate both;
}
.bn.sale .bn_baloon2.on{
	-webkit-animation:bnBaloon2 3s linear infinite alternate both;
	animation:bnBaloon2 3s linear infinite alternate both;
}
.bn.sale .coin1.bounceIn{
	height:17%;
	top:22%;
	left:-32%;
}
.bn.sale .coin2.bounceIn{
	height:8%;
	top:75%;
	left:-32%;
}
.bn.sale .coin3.bounceIn{
	height:11%;
	top:8%;
	left:-31%;
}
.bn.sale .coin4.bounceIn{
	height:5%;
	top:43%;
	left:-34%;
}
.bn.sale .coin5.bounceIn{
	height:7%;
	top:21%;
	left:31%;
}
.bn.sale .coin6.bounceIn{
	height:9%;
	top:74%;
	left:28%;
}
.bn.sale .coin7.bounceIn{
	height:4%;
	top:41%;
	left:30.5%;
}
.bn.sale .coin8.bounceIn{
	height:9%;
	top:72%;
	left:30.5%;
}
.bn.sale .coin9.bounceIn{
	height:10%;
	top:6%;
	left:32%;
}
.bn.sale .coin10.bounceIn{
	height:10%;
	top:44%;
	left:34.5%;
}

/* 수학섬 업데이트 배너 */
.bn.mathupdate{
	background:url(../assets/math_bg.png) no-repeat center;
	background-size:cover;
	overflow:hidden;
}
.bn.mathupdate .bn_content_wrap .inner90{
	-webkit-box-pack:start;
	justify-content:flex-start;
}
.bn.mathupdate .txt1{
	position: absolute;
	z-index: 2;
	/* height:auto; */
	height: 90%;
	/* margin-top: 6%; */
	/* margin-left: -4%; */
	-webkit-animation-delay:.1s;
	animation-delay:.1s;
}
.bn.mathupdate .txt2{
	position: relative;
	z-index: 1;
	height:70%;
	margin-top:10%;
	-webkit-animation-delay:.3s;
	animation-delay:.3s;
}
.bn.mathupdate .button{
	position: absolute;
	z-index: 2;
	width: auto;
	margin-top:15%;
	margin-left:-4%;
	/* -webkit-animation-delay:.1s;
	animation-delay:.1s; */
}
.bn.mathupdate .button img{
	height: auto;
	margin-top:15%;
	-webkit-animation-delay:.1s;
	animation-delay:.1s;
}

.bn_btn_wrap{
	width:100%; 
	height:36px; 
	position:absolute; 
	top:92%;
	z-index:30;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	/* visibility:hidden; */
}
.bn_btn_area{
	width:auto;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	background:rgba(0,0,0,.3);
	margin-right:10px;
	border-radius:30px;
	padding:0 10px;
}
.bn_btn{
	width:12px; 
	height:12px; 
	background:none; 
	box-sizing:border-box;
	border:1px solid #ffffff;
	border-radius:50%; 
	margin:0 12px; 
	cursor:pointer;
	opacity:.7;
}
.bn_btn.on{
	background:#ffffff; 
	border:none;
	opacity:1;
}
.bn_play{
	width:36px;
	height:36px;
	background:rgba(0,0,0,.3);
	border-radius:50%;
	overflow:hidden;
	cursor:pointer;
}
.bn_play img{
	width:100%;
	height:100%;
}
.bn_play .stop{
	display:block;
}
.bn_play .play{
	display:none;
}
.bn_play.stop .stop{
	display:none;
}
.bn_play.stop .play{
	display:block;
}

/*introduce_area*/
.introduce_area{
	background:rgba(249,190,45,.1);
	padding-bottom:10.2vh;
}
.introduce_area .stakato::before{
	width:.6vh;
	height:.6vh;
	top:-.7vh;
}
.introduce_area .inner90{
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	-webkit-box-pack:end;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:flex-end;
}
.introduce_area .top_area{
	width:100%;
	height:40.5vmin;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	align-items:center;
	position:relative;
}
.introduce_area .top_area img{
	width:auto;
	height:100%;
	margin-right:4vw;
}
.introduce_area .top_area .right_area{
	height:auto;
}
.introduce_area .right_area p{
	font-size:.85em;
	text-align:left;
	margin-top:2.5vh;
}
.introduce_area .right_area p br{
	display:block;
}
.introduce_area span.index_tit{
	color:#f9be2d;
	text-align:left;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .introduce_area span.index_tit{
	color:#f9be2d;
	text-align:left;
	font-family: 'NotoSansJP-Regular';
}
.introduce_area .sub_title{
	color:#3c3c3c;
}
.introduce_area .sub_title .stakato:nth-child(3){
	margin-left:.5vw;
}
.introduce_area .ym_who_popup{
	display:none;
}
.introduce_area .ym_who_popup.on{
	display:block;
	position:absolute;
	width:90%;
	height:44vh;
	background:#2e3b65;
	z-index:11;
	border-radius:88px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.introduce_area .ym_who_popup.on .ym_wyo_box{
	width:23vw;
	height:auto;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.ym_who_popup.on .ym_wyo_box:first-child{
	width:18vw;
}
.ym_who_popup.on .ym_wyo_box:nth-child(2){
	margin:0 3vw;
	width:22vw;
}
.ym_who_popup.on .ym_wyo_box span{
	text-align:center;
	color:#f4c222;
	font-size:1em;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .ym_who_popup.on .ym_wyo_box span{
	text-align:center;
	color:#f4c222;
	font-size:1em;
	font-family: 'NotoSansJP-Regular';
}
.ym_who_popup.on .ym_wyo_box p{
	text-align:center;
	color:#f5f5f5;
	margin-top:3vh;
	font-size:.9em;
}
:lang(jp) .ym_who_popup.on .ym_wyo_box p{
	text-align:center;
	color:#f5f5f5;
	margin-top:3vh;
	font-size:.9em;
	word-break: break-all;
	overflow-wrap: break-word;
	font-family: 'NotoSansJP-Regular';
}
.introduce_area .ym_who_btn_area{
	width:auto;
	height:auto;
}
.introduce_area .ym_who_btn{
	width:27vmax;
	height:6vh;
	border-radius:100px;
	background:#f4c222;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
	cursor:pointer;
	overflow:hidden;
	margin-top:9vh;
	-webkit-transform:translate3d(0,0,0);
	-webkit-backface-visibility:hidden;
	transform:translate3d(0,0,0);
	backface-visibility:hidden;
	isolation:isolate;
}
.introduce_area .ym_who_btn span{
	font-size:.85em;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
	text-align:center;
	position:relative;
	z-index:2;
}
:lang(jp) .introduce_area .ym_who_btn span{
	font-size:.85em;
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
	text-align:center;
	position:relative;
	z-index:2;
}
.introduce_area .ym_who_btn .hover_round{
	border-radius:50%;
	background:#ffffff;
	position:relative;
	margin-left:15px;
	width:24px;
	height:24px;
	-webkit-transition:all .6s;
	transition:all .6s;
}
.introduce_area .ym_who_btn.on{
	background:#ffffff;
}
.introduce_area .ym_who_btn.on span{
	color:#f29500;
}
.introduce_area .ym_who_btn.on{
	background:#ffffff;
}
.introduce_area .ym_who_btn.on .hover_round{
	-webkit-transform:scale(50);
	transform:scale(50);
}

/*ym_world_area*/
.ym_world_area{
	background:#f9be2d;
	padding-top:19.5vh;
	padding-bottom:4.5vh;
}
.ym_world_area .inner90{
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.ym_world_area .index_tit{
	font-family: 'S-CoreDream-7ExtraBold';
	letter-spacing:-.07rem;
}
:lang(jp) .ym_world_area .index_tit{
	font-family: 'NotoSansJP-Medium';
	letter-spacing:-.07rem;
}
.ym_world_area .index_tit .br{
	font-family:inherit;
	font-size:inherit;
	text-align:inherit;
	letter-spacing:inherit;
	color:inherit;
	display:-webkit-box;
	display:flex;
	margin-right:5px;
}
.ym_world_area .stakato{
	color:#fff7e8;
}
.ym_world_area .stakato:first-child{
	margin-left:.5vw;
}
.ym_world_area .stakato:last-child{
	margin-right:.1vw;
}
.ym_world_area .stakato::before{
	background:#fff7e8;
	top:-.5vh;
}
.ym_world_area .top_area{
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	position:relative;
}
.ym_world_area p.txt{
	margin-top:1vh;
}
.ym_world_area p.txt br{
	display:block;
}
.ym_world_area .bottom_area{
	width:auto;
	height:55vh;
	margin-top:3vh;
	display:-webkit-box;
	display:flex;
}
.ym_world_area .left_area{
	height:55vmin;
	width:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:-5vw;
}
.ym_world_area .left_area .loby{
	height:70vh;
	width:auto;
	margin-top:-2vh;
}
.ym_world_area .left_area .plus_wrap{
	height:100%;
	width:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:2;
}
.ym_world_area .plus_wrap .plus{
	width:3.4vh;
	height:3.4vh;
	position:relative;
	cursor:pointer;
	-webkit-transform:scale(1);
	transform:scale(1);
	-webkit-transition:all .6s;
	transition:all .6s;
}
.ym_world_area .plus_wrap .plus:hover{
	-webkit-transform:scale(1.1);
	transform:scale(1.1);
}
.ym_world_area .plus_wrap .plus.on{
	-webkit-transform:scale(1.1);
	transform:scale(1.1);
}
.ym_world_area .plus img{
	width:auto;
	height:100%;
	position:absolute;
	cursor:pointer;
	display:block;
}
.ym_world_area .plus img:first-child{
	display:none;
}
.ym_world_area .plus.on img:first-child{
	display:block;
}
.ym_world_area .plus.on img:last-child{
	display:none;
}
.ym_world_area .plus:nth-child(1){
	top:12%;
	left:24%;
}
.ym_world_area .plus:nth-child(2){
	top:-5%;
	left:34%;
}
.ym_world_area .plus:nth-child(3){
	top:-7%;
	left:57%;
}
.ym_world_area .plus:nth-child(4){
	top:-4%;
	left:67%;
}
.ym_world_area .plus:nth-child(5){
	top:13%;
	left:39%;
}
.ym_world_area .plus:nth-child(6){
	top:-3%;
	left:30%;
}
.ym_world_area .right_area{
	height:100%;
	width:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:3vw;
}
.ym_world_area .building_wrap{
	width:40vh;
	height:40vh;
	border-radius:30%;
	box-sizing:border-box;
	border:4px dashed #ffffff;
	padding:2.8vh;
	background:rgba(255,255,255,.15);
	position:relative;
	box-shadow: 8px 8px 10px 0 rgba(107, 82, 18, .35);
}
.ym_world_area .building{
	width:100%;
	height:100%;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	position:absolute;
	top:0;
	left:0;
	visibility:hidden;
	padding:2.8vh;
	box-sizing:border-box;
	-webkit-transition:none;
	transition:none;
}
.ym_world_area .building.on{
	visibility:visible;
}
.ym_world_area .building span{
	width:auto;
	height:auto;
	border-radius:20px;
	background:#ffffff;
	box-sizing:border-box;
	padding:0 1vw;
	font-family: 'S-CoreDream-7ExtraBold';
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	font-size:.8em;
	-webkit-transition:none;
	transition:none;
}
:lang(jp) .ym_world_area .building span{
	width:auto;
	height:auto;
	border-radius:20px;
	background:#ffffff;
	box-sizing:border-box;
	padding:0 1vw;
	font-family: 'NotoSansJP-Medium';
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	font-size:.8em;
	-webkit-transition:none;
	transition:none;
}
.ym_world_area .building img{
	width:auto;
	height:20vh;
	margin-top:1.5vh;
	margin-bottom:.5vh;
	-webkit-transition:none;
	transition:none;
}
.ym_world_area .building p{
	text-align:center;
	color:#2e3b65;
	font-size:0.68em;
	-webkit-transition:none;
	transition:none;
}
.ym_world_area .building p br{
	display:block;
}

/*ym_difference_area*/
.ym_difference_area{
	background:#37b3e9;
	padding-top:21.1vh;
	padding-bottom:8.9vh;
}
.ym_difference_area .inner90{
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.ym_difference_area .index_tit{
	font-family:'S-CoreDream-7ExtraBold';
}
:lang(jp) .ym_difference_area .index_tit{
	font-family: 'NotoSansJP-Medium';
}
.ym_difference_area .tabbtn_area{
	height:auto;
	width:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	margin:3vh 0;
}
.ym_difference_area .tabbtn_area .btn{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	color:#e6e6e6;
	font-family: 'S-CoreDream-3Light';
	cursor:pointer;
}
.ym_difference_area .tabbtn_area .btn:first-child{
	margin-right:2.5vw;
}
.ym_difference_area .tabbtn_area .btn::before{
	content:'';
	display:block;
	width:3.5vh;
	height:3.5vh;
	border-radius:50%;
	background:#e6e6e6;
	margin-right:.3vw;
	box-sizing:border-box;
	-webkit-transition:all .6s;
	transition:all .6s;
}
.ym_difference_area .tabbtn_area .btn:hover::before{
	background:#f4c222;
}
.ym_difference_area .tabbtn_area .btn.on::before{
	background:#f4c222;
}
.ym_difference_area .tabbtn_area .btn:hover{
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .ym_difference_area .tabbtn_area .btn:hover{
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
}
.ym_difference_area .tabbtn_area .btn.on{
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .ym_difference_area .tabbtn_area .btn.on{
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
}
.ym_difference_area .middle_area{
	width:100%;
	height:18vh;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.ym_difference_area .info_txt_wrap{
	width:auto;
	height:auto;
	box-sizing:border-box;
	padding:2.2vh 2.8vw;
	border-radius:30px;
	border:2px dashed #ffffff;
	background:rgba(255,255,255,.3);
	-webkit-transition:all .6s;
	transition:all .6s;
}
.ym_difference_area .info_txt_wrap p{
	display:none;
	width:100%;
	color:#ffffff;
	text-align:center;
	font-size:0.75em;
}
.ym_difference_area .info_txt_wrap p.on{
	display:block;
}
.ym_difference_area .img_area{
	width:90%;
	height:29vmin;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
	margin-top:5vh;
}
.ym_difference_area .img_area .left_img{
	width:50%;
	height:100%;
	box-sizing:border-box;
	border-right:2px dashed #ffffff40;
	padding-right:1vw;
	display:-webkit-box;
	-webkit-box-pack:end;
	display:flex;
	justify-content:flex-end;
}
.ym_difference_area .img_area .right_img{
	width:50%;
	height:100%;
	box-sizing:border-box;
	padding-left:1vw;
	display:-webkit-box;
	-webkit-box-pack:start;
	display:flex;
	justify-content:flex-start;
}
.ym_difference_area .img_area .right_img img{
	display:none;
	height:100%;
	width:auto;
}
.ym_difference_area .img_area .left_img img{
	display:none;
	height:100%;
	width:auto;
}
.ym_difference_area .img_area .left_img img.on{
	display:block;
}
.ym_difference_area .img_area .right_img img.on{
	display:block;
}

/*ym_myroom_area*/
.ym_myroom_area{
	background:#fff9f0;
	padding-top:20.4vh;
	padding-bottom:12.2vh;
}
.ym_myroom_area .inner90{
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.ym_myroom_area .index_tit .br{
	font-family:inherit;
	font-size:inherit;
	text-align:inherit;
	letter-spacing:inherit;
	color:inherit;
	display:-webkit-box;
	display:flex;
	margin-right:5px;
}
.ym_myroom_area .stakato:first-child{
	margin-left:.5vw;
}
.ym_myroom_area .stakato:last-child{
	margin-right:.1vw;
}
.ym_myroom_area .stakato::before{
	top:-.5vh;
}
.ym_myroom_area p.txt{
	margin-top:1.3vh;
}
.ym_myroom_area p.txt br{
	display:block;
}
.ym_myroom_area .bottom_area{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	height:45vh;
	position:relative;
	margin-top:9vh;
}
.ym_myroom_area .box{
	width:44.7vh;
	border:3px solid #ffffff;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .05);
	border-radius:26%;
	box-sizing:border-box;
	position:relative;
	overflow:hidden;
	cursor:pointer;
	-webkit-transform:scale(.8);
	transform:scale(.8);
	-webkit-transition:all .6s;
	transition:all .6s;
	top:-2vh;
}
.ym_myroom_area .box::after{
	content:'';
	display:block;
	padding-bottom:100%;
}
.ym_myroom_area .box .cover{
	visibility:visible;
	background:#fff7eb;
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:25;
	text-align:center;
	font-size:.85em;
	font-family: 'S-CoreDream-6Bold';
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	-webkit-transition:none;
	transition:none;
}
:lang(jp) .ym_myroom_area .box .cover{
	visibility:visible;
	background:#fff7eb;
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:25;
	text-align:center;
	font-size:.85em;
	font-family: 'NotoSansJP-Regular';
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	-webkit-transition:none;
	transition:none;
}
.ym_myroom_area .box.on{
	-webkit-transform:scale(1);
	transform:scale(1);
	top:0;
	border:3px solid #ffffff;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .35);
}
.ym_myroom_area .box:nth-child(2){
	margin:0 .2vw;
}
.ym_myroom_area .box:hover{
	-webkit-transform:scale(.84);
	transform:scale(.84);
}
.ym_myroom_area .box.on:hover{
	-webkit-transform:scale(1);
	transform:scale(1);
}
.ym_myroom_area .box.on .cover{
	visibility:hidden;
}
.ym_myroom_area .box .content{
	background:#f9be2d;
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	padding:3.8vh;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.ym_myroom_area .content span{
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .ym_myroom_area .content span{
	font-family: 'NotoSansJP-Regular';
}
.ym_myroom_area .content p{
	font-size:.75em;
	text-align:center;
	margin-top:1vh;
}
.ym_myroom_area .content .bottom_area{
	height:70%;
	width:100%;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	position:relative;
	margin-top:10%;
}
.ym_myroom_area .box:first-child .content .bottom_area{
	margin-top:1vh;
}
.ym_myroom_area .content .graph_area3 img{
	height:auto;
	width:90%;
}
.ym_myroom_area .content .graph_area3 .count_wrap{
	width:24%;
	height:6vh;
	position:absolute;
	z-index:3;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.ym_myroom_area .content .graph_area3 .counting{
	color:#541da7;
	font-size:1.3em;
	font-family: 'S-CoreDream-9Black';
	position:relative;
}
:lang(jp) .ym_myroom_area .content .graph_area3 .counting{
	color:#541da7;
	font-size:1.3em;
	font-family: 'NotoSansJP-Black';
	position:relative;
}
.ym_myroom_area .content .graph_area3 .count1{
	top:58%;
	left:5%;
}
.ym_myroom_area .content .graph_area3 .count2{
	top:58%;
	left:38%;
}
.ym_myroom_area .content .graph_area3 .count3{
	top:58%;
	left:71%;
}
.ym_myroom_area .content .graph_area1{
	height:40%;
	width:100%;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	position:relative;
	margin-bottom:3%;
}
.ym_myroom_area .graph_area1 img{
	height:70%;
	width:auto;
}
.ym_myroom_area .graph_area1 .wrap{
	width:80%;
	height:24%;
	position:relative;
	top:-32%;
	border-radius:60px;
	z-index:3;
	overflow:hidden;
	/* isolation:isolate; */
	/* -webkit-transform:translate3d(0,0,0); */
	/* -webkit-backface-visibility:hidden; */
}
.ym_myroom_area .graph_area1 .wrap .bar{
	width:0%;
	min-width:0;
	height:100%;
	background:#00d10c;
	position:relative;
	z-index:3;
	-webkit-transition:width 1.6s;
	transition:width 1.6s;
}
.ym_myroom_area .graph_area1 .wrap .bar.on{
	width:100%;
}
.ym_myroom_area .content .graph_area1_graph{
	height:auto;
	width:90%;
	position:relative;
}
.ym_myroom_area .content .graph_area2{
	height:70%;
	width:100%;
	overflow:hidden;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-end;
	justify-content:center;
}
.ym_myroom_area .graph_area2 .graph{
	width:24%;
	height:100%;
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.ym_myroom_area .graph_area2 .graph:nth-child(2){
	margin:0 1%;
}
.ym_myroom_area .graph img{
	height:20%;
	width:auto;
	position:absolute;
	bottom:0;
	opacity:0;
	--bottomA:2.5%;
	--animationDelay:.1s;
}
.ym_myroom_area .graph img.on{
	-webkit-animation:graphFadeIn .05s ease-in-out both;
	animation:graphFadeIn .05s ease-in-out both;
}
@keyframes graphFadeIn{
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
}
.ym_myroom_area .graph img.head{
	height:49%;
	bottom:39%;
	z-index:20;
	opacity:1;
}
.ym_myroom_area .graph:nth-child(2) img.head{
	bottom:24%;
}
.ym_myroom_area .graph:nth-child(3) img.head{
	bottom:34%;
}
.ym_myroom_area .graph:nth-child(1) .head.on{
	-webkit-animation:graphHead1 1.6s ease-in-out both;
	animation:graphHead1 1.6s ease-in-out both;
	-webkit-animation-delay:calc(var(--animationDelay) * 1);
	animation-delay:calc(var(--animationDelay) * 1);
}
.ym_myroom_area .graph:nth-child(2) .head.on{
	-webkit-animation:graphHead2 1s ease-in-out both;
	animation:graphHead2 1s ease-in-out both;
	-webkit-animation-delay:calc(var(--animationDelay) * 1);
	animation-delay:calc(var(--animationDelay) * 1);
}
.ym_myroom_area .graph:nth-child(3) .head.on{
	-webkit-animation:graphHead3 1.3s ease-in-out both;
	animation:graphHead3 1.3s ease-in-out both;
	-webkit-animation-delay:calc(var(--animationDelay) * 1);
	animation-delay:calc(var(--animationDelay) * 1);
}
@keyframes graphHead1{
	0%{
		bottom:2.5%;
	}
	100%{
		bottom:39%;
	}
}
@keyframes graphHead2{
	0%{
		bottom:2.5%;
	}
	100%{
		bottom:24%;
	}
}
@keyframes graphHead3{
	0%{
		bottom:2.5%;
	}
	100%{
		bottom:34%;
	}
}
.ym_myroom_area .graph img:nth-child(2){
	bottom:calc(var(--bottomA) * 1);
	z-index:2;
	-webkit-animation-delay:calc(var(--animationDelay) * 1);
	animation-delay:calc(var(--animationDelay) * 1);
}	
.ym_myroom_area .graph img:nth-child(3){
	bottom:calc(var(--bottomA) * 2);
	z-index:3;
	-webkit-animation-delay:calc(var(--animationDelay) * 2);
	animation-delay:calc(var(--animationDelay) * 2);
}	
.ym_myroom_area .graph img:nth-child(4){
	bottom:calc(var(--bottomA) * 3);
	z-index:4;
	-webkit-animation-delay:calc(var(--animationDelay) * 3);
	animation-delay:calc(var(--animationDelay) * 3);
}	
.ym_myroom_area .graph img:nth-child(5){
	bottom:calc(var(--bottomA) * 4);
	z-index:5;
	-webkit-animation-delay:calc(var(--animationDelay) * 4);
	animation-delay:calc(var(--animationDelay) * 4);
}	
.ym_myroom_area .graph img:nth-child(6){
	bottom:calc(var(--bottomA) * 5);
	z-index:6;
	-webkit-animation-delay:calc(var(--animationDelay) * 5);
	animation-delay:calc(var(--animationDelay) * 5);
}	
.ym_myroom_area .graph img:nth-child(7){
	bottom:calc(var(--bottomA) * 6);
	z-index:7;
	-webkit-animation-delay:calc(var(--animationDelay) * 6);
	animation-delay:calc(var(--animationDelay) * 6);
}	
.ym_myroom_area .graph img:nth-child(8){
	bottom:calc(var(--bottomA) * 7);
	z-index:8;
	-webkit-animation-delay:calc(var(--animationDelay) * 7);
	animation-delay:calc(var(--animationDelay) * 7);
}	
.ym_myroom_area .graph img:nth-child(9){
	bottom:calc(var(--bottomA) * 8);
	z-index:9;
	-webkit-animation-delay:calc(var(--animationDelay) * 8);
	animation-delay:calc(var(--animationDelay) * 8);
}	
.ym_myroom_area .graph img:nth-child(10){
	bottom:calc(var(--bottomA) * 9);
	z-index:10;
	-webkit-animation-delay:calc(var(--animationDelay) * 9);
	animation-delay:calc(var(--animationDelay) * 9);
}	
.ym_myroom_area .graph img:nth-child(11){
	bottom:calc(var(--bottomA) * 10);
	z-index:11;
	-webkit-animation-delay:calc(var(--animationDelay) * 10);
	animation-delay:calc(var(--animationDelay) * 10);
}	
.ym_myroom_area .graph img:nth-child(12){
	bottom:calc(var(--bottomA) * 11);
	z-index:12;
	-webkit-animation-delay:calc(var(--animationDelay) * 11);
	animation-delay:calc(var(--animationDelay) * 11);
}	
.ym_myroom_area .graph img:nth-child(13){
	bottom:calc(var(--bottomA) * 12);
	z-index:13;
	-webkit-animation-delay:calc(var(--animationDelay) * 12);
	animation-delay:calc(var(--animationDelay) * 12);
}	
.ym_myroom_area .graph img:nth-child(14){
	bottom:calc(var(--bottomA) * 13);
	z-index:14;
	-webkit-animation-delay:calc(var(--animationDelay) * 13);
	animation-delay:calc(var(--animationDelay) * 13);
}	
.ym_myroom_area .graph img:nth-child(15){
	bottom:calc(var(--bottomA) * 14);
	z-index:15;
	-webkit-animation-delay:calc(var(--animationDelay) * 14);
	animation-delay:calc(var(--animationDelay) * 14);
}	

/*brain_music_area*/
.brain_music_area{
	background:url(../assets/뮤직룸.png) no-repeat center;
	background-size:cover;
	padding-top:35.5vh;
	padding-bottom:24.3vh;
}
.brain_music_area .inner90{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.brain_music_area .index_tit{
	text-align:left;
	padding-left:1vw;
}
.brain_music_area .sub_title{
	text-align:left;
	padding-left:1vw;
}
.brain_music_area .wrap{
	width:auto;
	height:auto;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:start;
	-webkit-box-orient:vertical;
	display:flex;
	justify-content:center;
	align-items:flex-start;
	flex-direction:column;
	margin-left:16.5vw;
	position:relative;
}
.brain_music_area .wrap ul{
	height:auto;
	width:auto;
	position:relative;
	margin-top:20px;
}
.brain_music_area .wrap li{
	width:auto;
	min-width:390px;
	height:8vh;
	position:relative;
	border-radius:48px;
	padding:2vh 1vw;
	background:#d9fbf1;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	border:none;
	box-sizing:border-box;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .35);
}
.brain_music_area .wrap li.on{
	border:2px solid #ffffff;
}
.brain_music_area .wrap li:hover{
	border:2px solid #ffffff;
}
.brain_music_area .wrap li:nth-child(2){
	margin:1.5vh 0;
}
.brain_music_area .wrap li .play_wrap{
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
	width:4vh;
	height:4vh;
	position:relative;
	margin-right:1vw;
	cursor:pointer;
}
.brain_music_area .wrap li .play_wrap img{
	height:100%;
	width:auto;
	position:absolute;
	top:0;
	left:0;
	visibility:hidden;
	-webkit-transition:none;
	transition:none;
}
.brain_music_area .wrap li .play_wrap .stop, 
.brain_music_area .wrap li .play_wrap .play{
	height:100%;
}
.brain_music_area .wrap li .play_wrap img.on{
	visibility:visible;
}
.brain_music_area .wrap li .right_area{
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:flex-start;
	justify-content:center;
	flex-direction:column;
	position:relative;
}
.brain_music_area .wrap li .right_area span{
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .brain_music_area .wrap li .right_area span{
	font-family: 'NotoSansJP-Regular';
}
.brain_music_area .wrap li .right_area p{
	font-size:.7em;
}
/*coding_block_area*/
.coding_block_area{
	background:#fff9f0;
	padding-top:20.2vh;
	padding-bottom:16.3vh;
}
.coding_block_area .inner90{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.coding_block_area .index_tit{
	display:-webkit-box;
	display:flex;
}
.coding_block_area .index_tit .br{
	font-family:inherit;
	font-size:inherit;
	text-align:inherit;
	letter-spacing:inherit;
	color:inherit;
	display:-webkit-box;
	display:flex;
	margin-right:5px;
}
.coding_block_area .stakato:first-child{
	margin-left:.5vw;
}
.coding_block_area .stakato:last-child{
	margin-right:.1vw;
}
.coding_block_area .stakato::before{
	top:-.5vh;
}
.coding_block_area .bottom_area{
	width:auto;
	height:43vh;
	position:relative;
	margin-top:15vh;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.coding_block_area .cont{
	width:10vw;
	height:auto;
	position:relative;
	margin:0 .5vw;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
	-webkit-transform:scale(1);
	transform:scale(1);
	-webkit-transition:all .6s;
	transition:all .6s;
	cursor:pointer;
	opacity:.5;
	background:none;
	border-radius:36px;
	box-shadow:none;
	padding:3vh .5vw;
	box-sizing:border-box;
}
.coding_block_area .cont:last-child{
	width:17vw;
}
.coding_block_area .arrow_wrap{
	width:3vw;
	height:auto;
	position:relative;
	-webkit-transition:all .6s;
	transition:all .6s;
	opacity:.5;
}
.coding_block_area .arrow1{
	height:auto;
}
.coding_block_area .arrow2{
	height:auto;
}
.coding_block_area .arrow_wrap img{
	width:50%;
	height:auto;
	display:block;
	margin:0 auto;
}
.coding_block_area .arrow_wrap.on{
	width:6vw;
	opacity:1;
	margin-left:4vw;
}
.coding_block_area .cont.on{
	-webkit-transform:scale(1.4);
	transform:scale(1.4);
	opacity:1;
	background:#f9be2d;
	box-shadow: 8px 8px 10px 0 rgba(0, 0, 0, .35);
	margin:0 2vw;
}
.coding_block_area .cont:last-child.on{
	margin-left:4vw;
}
.coding_block_area .cont:last-child br{
	display:block;
}
.coding_block_area .cont.on:hover{
	-webkit-transform:scale(1.4);
	transform:scale(1.4);
	opacity:1;
}
.coding_block_area .cont:hover{
	-webkit-transform:scale(1.1);
	transform:scale(1.1);
	opacity:1;
}
.coding_block_area .cont .num{
	height:2.8vh;
	width:auto;
}
.coding_block_area .cont .title{
	font-size:.65em;
	font-family: 'S-CoreDream-7ExtraBold';
	margin-top:.5vh;
}
:lang(jp) .coding_block_area .cont .title{
	font-size:.65em;
	font-family: 'NotoSansJP-Medium';
	margin-top:.5vh;
}
.coding_block_area .cont .sub_tit{
	font-size:.5em;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .coding_block_area .cont .sub_tit{
	font-size:.5em;
	font-family: 'NotoSansJP-Regular';
}
.coding_block_area .cont p{
	font-size:.5em;
	text-align:Center;
	margin-top:.5vh;
}
.coding_block_area .cont img:last-child{
	height:auto;
	width:3.5vw;
	margin-top:2vh;
}
.coding_block_area .box>img{
	height:auto;
	width:10%;
}

/*yf_area*/
.yf_area{
	background:#f9be2d;
	padding-top:21vh;
	padding-bottom:15.3vh;
}
.yf_area .inner90{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.yf_area .sub_title{
	display:-webkit-box;
	display:flex;
}
.yf_area .stakato{
	color:#fffbf2;
}
.yf_area .stakato:first-child{
	margin-left:.5vw;
}
.yf_area .stakato:last-child{
	margin-right:.2vw;
}
.yf_area .stakato::before{
	background:#fffbf2;
	display:block;
	width:.6vh;
	height:.6vh;
	top:-.5vh;
}
.yf_area .content_area{
	width:100%;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	margin-top:8vh;
	box-sizing:border-box;
	height:46vmin;
}
.yf_area .content_area .btn_area{ 
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	-webkit-box-pack:end;
	display:flex;
	align-items:center;
	justify-content:flex-end;
	flex-direction:column;
	position:relative;
	box-sizing:border-box;
	padding-bottom:2%;
	margin-left:2vw;
	margin-right:3.7vw;
	width:auto;
	height:100%;
}
.yf_area .content_area .btn_area li{ 
	width:3.6vw;
	height:8%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	margin-top:26%;
}
.yf_area .content_area .btn_area li img{ 
	width:auto;
	height:100%;
	-webkit-transition:all .4s;
	transition:all .4s;
}
.yf_area .content_area .btn_area li:hover{ 
	height:11%;
}
.yf_area .content_area .btn_area li.on:hover{ 
	height:13%;
}
.yf_area .content_area .btn_area li.on{ 
	height:13%;
}
.yf_area .yf_img_area{
	width:auto;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-end;
	justify-content:center;
	margin-right:1vw;
}
.yf_area .yf_img_area img{
	width:auto;
	height:98%;
	position:relative;
	display:none;
}
.yf_area .yf_img_area img.on{
	display:block;
}
.yf_area .yf_info_area{
	position:relative;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
	width:auto;
	height:100%;
} 
.yf_area .yf_info_area .album_area{
	width:30vmax;
	height:55%;
	margin-bottom:15%;
	position:relative;
}	
.yf_area .yf_info_area .album{
	height:auto;
	position:relative;
	box-sizing:border-box;
	width:25vmin;
	max-width:55%;
	background:#ffffff;
	padding:5px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:Center;
	-webkit-transition:all .6s;
	transition:all .6s;
}	
.yf_area .yf_info_area .album img{
	width:100%;
	height:auto;
	position:relative;
	display:none;
}	
.yf_area .yf_info_area .album img.on{
	display:block;
}	
.album1{
	-webkit-transform:translate(0%,0%) rotate(-16deg) scale(1);
	transform:translate(0%,0%) rotate(-16deg) scale(1);
	left:-4%;
	top:0%;
	z-index:3;
}
.album1.on{
	-webkit-transform:translate(9%,43%) rotate(9deg) scale(1.1);
	transform:translate(9%,43%) rotate(9deg) scale(1.1);
	z-index:1;
}
.album2{
	-webkit-transform:translate(0%,0%) rotate(-7deg) scale(1.1);
	transform:translate(0%,0%) rotate(-7deg) scale(1.1);
	top:-21%;
	left:32%;
	z-index:1;
}
.album2.on{
	-webkit-transform:translate(10%,-76%) rotate(11deg) scale(1);
	transform:translate(10%,-76%) rotate(11deg) scale(1);
	z-index:3;
}
.yf_area .yf_info_area .txt_area{
	width:auto;
	height:30%;
	position:relative;
	margin-top:1vh;
}	
.yf_area .yf_info_area .txt_box{
	width:auto;
	height:auto;
	position:absolute;
	top:0;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:end;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:flex-start;
	justify-content:flex-end;
	flex-direction:column;
	visibility:hidden;
	box-sizing:border-box;
	border:2px dashed #fff9f0;
	padding:1vh 2vw;
	border-radius:30px;
	box-shadow:8px 8px 10px 0 rgba(107,82,18,.35);
	-webkit-transition:none;
	transition:none;
}	
.yf_area .yf_info_area .txt_box.on{
	visibility:visible;
}	
.yf_area .yf_info_area .txt_box span{
	text-align:left;
	color:#fff9f0;
	font-family: 'S-CoreDream-7ExtraBold';
	font-size:1em;
	-webkit-transition:none;
	transition:none;
}	
:lang(jp) .yf_area .yf_info_area .txt_box span{
	text-align:left;
	color:#fff9f0;
	font-family: 'NotoSansJP-Medium';
	font-size:1em;
	-webkit-transition:none;
	transition:none;
}	
.yf_area .yf_info_area .txt_box p{
	text-align:left;
	color:#fff9f0;
	font-size:.75em;
	margin-top:.4vh;
	-webkit-transition:none;
	transition:none;
}	

.yf_area .inner90{
	-webkit-box-pack:center;
	justify-content:center;
}
	
/* yame_sns_area */
.yame_sns_area{
	background:#f9be2d url(../assets/yame_sns_bg@2x.png) no-repeat center bottom;
	background-size:100% auto;
}
.yame_sns_area .inner90{
	width:882px;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.yame_sns_area .yame_sns_title_area{
	width:100%;
	height:146px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.yame_sns_title_area img{
	height:100%;
}
.yame_sns_title_area span{
	font-family: 'S-CoreDream-6Bold';
	font-size:30px;	
	color:#1a1a1a;
	margin-left:10px;
}
:lang(jp) .yame_sns_title_area span{
	font-family: 'NotoSansJP-Regular';
	font-size:30px;	
	color:#1a1a1a;
	margin-left:10px;
}
.yame_sns_area .yame_youtube_area{
	width:100%;
	height:auto;
	padding:11px 56px 0;
	box-sizing:border-box;
	position:relative;
}
.yame_youtube_area a{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	padding-bottom:16px;
	border-bottom:2px solid #f7931e;
}
.yame_youtube_area img{
	height:210px;
	margin-right:32px;
}
.yame_youtube_area .right_area{
	position:relative;
}
.yame_youtube_area .top_area{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.yame_youtube_area .top_area img{
	height:42px;
	margin-right:10px;
}
.yame_youtube_area span{
	font-family: 'S-CoreDream-6Bold';
	font-size:30px;	
	color:#1a1a1a;
}
:lang(jp) .yame_youtube_area span{
	font-family: 'NotoSansJP-Regular';
	font-size:30px;	
	color:#1a1a1a;
}
.yame_youtube_area p{
	font-size:20px;
	color:#4d4d4d;
	margin-top:20px;
	line-height:1.9;
	text-align:left;
}
.yame_sns_area .yame_cafe_area{
	width:calc(100% - 112px);
	height:105px;
	margin-top:17px;
	border-radius:16px;
	background:#f9be2d;
	border:2px solid #f7931e;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
	box-sizing:border-box;
	padding:0 32px;
}
.yame_cafe_area .cafe_top_area{
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.yame_cafe_area img{
	height:63px;
	margin-right:16px;
}
.yame_cafe_area .text_area{
	position:relative;
}
.yame_cafe_area span{
	font-family: 'S-CoreDream-6Bold';
	font-size:20px;
	color:#1a1a1a;
	text-align:left;
	line-height:1;
}
:lang(jp) .yame_cafe_area span{
	font-family: 'NotoSansJP-Regular';
	font-size:20px;
	color:#1a1a1a;
	text-align:left;
	line-height:1;
}
.yame_cafe_area p{
	font-size:14px;
	color:#4d4d4d;
	text-align:left;
	margin-top:10px;
}
.yame_cafe_area a{
	width:102px;
	height:48px;
	border-radius:6px;
	background:#00c135;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:18px;
	color:#005809;
}

/* floating_paging_btn_wrap */
.floating_paging_btn_wrap{
	width:auto;
	height:100%;
	display:block;
	position:fixed;
	top:0;
	right:.1%;
	z-index:90;
	display:-webkit-box;
	-webkit-box-align:end;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:flex-end;
	justify-content:center;
	flex-direction:column;
	padding-top:80px;
	box-sizing:border-box;
	visibility:visible;
}
.floating_paging_btn_wrap li{
	cursor:pointer;
	color:#767676;
	font-size:.6em;
	text-align:right;
	padding:.5vh .3vw;
	border-radius:60px;
	margin:10% 0;
}
.floating_paging_btn_wrap li.on{
	color:#525252;
	background:#f9de8a;
}
.floating_paging_btn_wrap li:hover{
	color:#525252;
	background:#f9de8a;
}

/*privacy.html , use.html*/
#content{
	width:100%; 
	height:auto; 
	background:#fff9f0; 
	position:relative; 
	padding-bottom:80px;
}
#content .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
#content span.index_title{
	color:#009cc6; 
	margin-top:60px;
	font-size:34px;
}

#content .wrap{
	width:80%; 
	height:auto;
	margin:0 auto;
	margin-top:30px;
}
#content .wrap .privacy_area{
	width:100%;
	height:auto;
	position:relative;
	top:0; 
	margin:60px 0;
}
#content .wrap .privacy_area span.tit{
	font-size:24px; 
	text-align:left;
	top:0; 
	color:#333333;
	margin-bottom:20px;
}
#content .wrap .privacy_area span.sub_tit{
	font-weight:500;
	text-align:left;
}
#content .wrap p{
	font-size:18px;
	text-align:left;
	padding:10px 20px;
	white-space:normal; 
	word-break:keep-all; 
	word-wrap:break-word;
}
#content .wrap .privacy_info{
	width:100%; 
	height:auto;
	background:#f7f7f7; 
	margin-bottom:60px;
	border:1px solid #009cc6; 
	box-sizing:border-box;
	position:relative; 
	top:0px;
	border-radius:10px; 
	padding:20px;
}

#content .wrap .privacy_area.area1 .box_wrap{
	width:100%; 
	height:auto;
	padding:20px; 
	box-sizing:border-box; 
	position:relative; 
	top:0px;
}
#content .box_wrap .title{
	width:100%; 
	height:70px;
	background:#009cc6;
	position:relative;
	top:0;
	left:0;
	display:-webkit-box;
	display:flex;
}
#content .box_wrap .title span{
	color:#ffffff;
	box-sizing:border-box;
	text-align:center;
	height:100%;
	width:76%;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:Center;
}
#content .box_wrap .title span:first-child{
	border-right:1px solid #ffffff;
	width:24%;
}
#content .box_wrap .list{
	width:100%; 
	height:auto;
	background:#ffffff;
	border-bottom:1px solid #eaeaea;
	box-sizing:border-box;
	position:relative;
	top:0; 
	display:-webkit-box;
	display:flex;
}
#content .box_wrap .list span{
	box-sizing:border-box; 
	width:76%;
	height:auto;
	padding:30px;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
#content .box_wrap .list span.name{
	width:24%;
	height:inherit;
	border-right:1px solid #eaeaea;
	text-align:center; 
	background:#fbfbfb;
	-webkit-box-pack:center;
	justify-content:center;
	padding:0;
}

/*to_top*/
#content .to_top{
	width:40px;
	height:40px; 
	cursor:pointer; 
	margin:0 auto; 
	background:#009cc6;
	border-radius:50px;
	box-sizing:border-box;
	position:relative; 
	top:-20px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
#content .to_top a{
	color:#ffffff;
	font-size:16px;
	text-align:center; 
}
#content .to_top span{
	color:#ffffff;
	font-size:16px;
	text-align:center; 
}


/*mypage_html*/
/*mypage_contents*/
.mypage_top_line_banner{
	width:100%;
	height:85px;
	position:relative;
	background:#666666;
}
.mypage_top_line_banner .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.mypage_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .mypage_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
}
/*mypage_tab_btn_area*/
.mypage_tab_btn_area{
	height:62px;
	width:100%;
	position:relative;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	background:#ffffff;
}
.mypage_tab_btn_area ul{
	width:100%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.mypage_tab_btn_area li{
	/* width:150px; */
	width: auto;
	height:40px;
	box-sizing:border-box;
	cursor:pointer;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.mypage_tab_btn_area li a{
	height:40px;
	font-size:14px;
	color:#666666;
	padding:8px 20px;
	box-sizing:border-box;
	border-radius:20px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.mypage_tab_btn_area li.on a{
	color:#f15a24;
	border:2px solid #f15a24;
}
.mypage_tab_btn_area li.hover_bg{
	display:none;
	position:absolute;
	/* width:116px; */
	width:auto;
	height:40px;
	box-sizing:border-box;
	border:2px solid #f15a24;
	border-radius:20px;
	left:167px;
	transition:all .25s;
	-webkit-transition:all .25s;
}

/*mypage_tab*/
.mypage_tab{
	width:100%;
	height:auto;
	min-height:calc(100vh - 497px);
	background:#ffffff;
}
.mypage_tab .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.mypage_tab .my_tit{
	font-family: 'S-CoreDream-6Bold';
	font-size:32px;
}
:lang(jp) .mypage_tab .my_tit{
	font-family: 'NotoSansJP-Regular';
	font-size:32px;
}
/*mypage_report*/
.my_report_top_btn_area{
	width:1060px;
	height:124px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
}
/* my_report_top_area */
.my_report_top_area{
	width:1060px;
	height:80px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.mypage_report #report_student_list_select{
	width:208px;
	height:40px;
	position:relative;
	box-sizing:border-box;
	border-radius:10px;
	padding:0 30px 0 20px;
	border:1px solid #e6e6e6;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(../assets/select_arrow.png) no-repeat 90% center;
	font-size:14px;
	color:#666666;
	cursor:pointer;
}
#report_student_list_select option{
	font-size:14px;
	color:#666666;
}


.my_report_top_btn_area button{
	width:180px;
	height:40px;
	background:#ffffff;
	border-radius:10px;
	border:1px solid #1363f8;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:16px;
	color:#666666;
	cursor:pointer;
}
.my_report_top_btn_area button img{
	width:auto;
	height:12px;
	margin-left:12px;
}
/*
.my_report_top_btn_area button{
	width:130px;
	height:36px;
	background:#ffffff;
	border-radius:10px;
	border:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:14px;
	color:#666666;
	cursor:pointer;
}
.my_report_top_btn_area button img{
	width:auto;
	height:12px;
	margin-left:12px;
}
*/
/* my_report_top_area */
.my_report_top_area button{
	height:36px;
	background:#ffffff;
	border-radius:10px;
	border:1px solid #1363f8;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:16px;
	color:#666666;
	cursor:pointer;
}
.my_report_top_area button img{
	width:auto;
	height:12px;
	margin-left:12px;
}
/*
.my_report_top_area button{
	height:36px;
	background:#ffffff;
	border-radius:10px;
	border:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:14px;
	color:#666666;
	cursor:pointer;
}
.my_report_top_area button img{
	width:auto;
	height:12px;
	margin-left:12px;
}
*/
.mypage_report .for_freeuser_info{
	text-align:right;
	color:#f15a24;
	font-size:13.5px;
	position:relative;
	margin-top:24px;
	width:1060px;
	display:-webkit-box;
	-webkit-box-pack:end;
	display:flex;
	justify-content:flex-end;
	box-sizing:border-box;
	padding-right:10px;
}
.mypage_report .for_freeuser_info br{
	display:none;
}
.my_report_missonprogress_area{
	width:1060px;
	/* height:822px; */
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:12px;
}
.my_report_missonprogress_area .head{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.my_report_missonprogress_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
:lang(jp) .my_report_missonprogress_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'NotoSansJP-Regular';
	margin-right:12px;
}
.my_report_missonprogress_area .head p{
	font-size:14px;
	color:#b3b3b3;
}
.my_report_missonprogress_area .wrap{
	width:100%;
	/* height:390px; */
	height: auto;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
	border-bottom:1px solid #e6e6e6;
}
.my_report_missonprogress_area .misson_progress{
	width:20%;
	/* height:368px; */
	height: 100%;
	box-sizing:border-box;
	border-right:1px solid #e6e6e6;
	padding:20px;
	padding-left:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.my_report_missonprogress_area .misson_progress:last-child{
	border:none;
}
/* my_chart_missonprogress_area */
.my_chart_missonprogress_area{
	width:1060px;
	height:442px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:6px;
	margin-bottom:42px;
}
.my_chart_missonprogress_area .head{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.my_chart_missonprogress_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
:lang(jp) .my_chart_missonprogress_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'NotoSansJP-Regular';
	margin-right:12px;
}
.my_chart_missonprogress_area .head p{
	font-size:14px;
	color:#b3b3b3;
}
.my_chart_missonprogress_area .wrap{
	width:100%;
	height:390px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
}
.my_chart_missonprogress_area .misson_progress{
	width:50%;
	/* height:368px; */
	box-sizing:border-box;
	border-right:1px solid #e6e6e6;
	padding:10px;
	/* display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex; */
	align-items:center;
	/* flex-direction:column; */
}
.my_chart_missonprogress_area .misson_progress:last-child{
	border:none;
}
/* my_class_chart_missonprogress_area */
.my_class_chart_missonprogress_area{
	width:1060px;
	height:480px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:6px;
	margin-bottom:42px;
}
.my_class_chart_missonprogress_area .head{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.my_class_chart_missonprogress_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
:lang(jp) .my_class_chart_missonprogress_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'NotoSansJP-Regular';
	margin-right:12px;
}
.my_class_chart_missonprogress_area .head p{
	font-size:14px;
	color:#b3b3b3;
}
.my_class_chart_missonprogress_area .wrap{
	margin-top: 30px;
	margin-left: 10px;
	margin-right: 20px;
	/* display:-webkit-box; */
	-webkit-box-align:center;
	/* display:flex; */
	align-items:center;
	position:relative;
}
.my_class_chart_missonprogress_area .misson_progress{
	/* height:368px; */
	box-sizing:border-box;
	border-right:1px solid #e6e6e6;
	padding:10px;
	/* display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex; */
	align-items:center;
	/* flex-direction:column; */
}
.my_class_chart_missonprogress_area .misson_progress:last-child{
	border:none;
}
.misson_progress .misson_left{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.misson_progress img{
	width:80px;
	height:80px;
	margin-bottom:36px;
}
.misson_progress .progress_wrap{
	display:-webkit-box;
	-webkit-box-align:end;
	display:flex;
	align-items:flex-end;
	margin-bottom:20px;
}
.misson_progress .progress_wrap span{
	font-family: 'S-CoreDream-6Bold';
	font-size:16px;
	color:#8cc63f;
}
:lang(jp) .misson_progress .progress_wrap span{
	font-family: 'NotoSansJP-Regular';
	font-size:16px;
	color:#8cc63f;
}
.misson_progress:nth-child(2) .progress_wrap span{
	color:#fbb03b;
}
.misson_progress:nth-child(3) .progress_wrap span{
	color:#29abe2;
}
.misson_progress:nth-child(4) .progress_wrap span{
	color:#d9e021;
}
.misson_progress:nth-child(5) .progress_wrap span{
	color:#00ffff;
}
.misson_progress .progress_wrap .now{
	font-size:38px;
	color:#22b573;
	margin-right:5px;
	line-height:1;
}
.misson_progress:nth-child(2) .progress_wrap .now{
	color:#f15a24;
}
.misson_progress:nth-child(3) .progress_wrap .now{
	color:#0071bc;
}
.misson_progress:nth-child(4) .progress_wrap .now{
	color:#8cc63f;
}
.misson_progress:nth-child(5) .progress_wrap .now{
	color:#29abe2;
}
.misson_progress .misson_txt{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.misson_progress .misson_name{
	font-size:14px;
	font-family: 'S-CoreDream-6Bold';
	color:#22b573;
}
:lang(jp) .misson_progress .misson_name{
	font-size:14px;
	font-family: 'NotoSansJP-Regular';
	color:#22b573;
}
.misson_progress:nth-child(2) .misson_name{
	color:#f15a24;
}
.misson_progress:nth-child(3) .misson_name{
	color:#0071bc;
}
.misson_progress:nth-child(4) .misson_name{
	color:#8cc63f;
}
.misson_progress:nth-child(5) .misson_name{
	color:#29abe2;
}
.misson_progress p{
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
	margin-top:40px;
}
:lang(jp) .misson_progress p{
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
	margin-top:40px;
	word-break: break-all;
	overflow-wrap: break-word;
	font-family: 'NotoSansJP-Regular';
}
/* misson_class_txt */
.misson_progress .misson_class_txt{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.misson_progress .misson_class_name{
	font-size:14px;
	font-family: 'S-CoreDream-6Bold';
	color:#22b573;
}
:lang(jp) .misson_progress .misson_class_name{
	font-size:14px;
	font-family: 'NotoSansJP-Regular';
	color:#22b573;
}
.misson_progress .misson_class_txt p{
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
	margin-top:5px;
	margin-bottom:5px;
}
.my_report_study_time_area{
	width:1060px;
	height:auto;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:60px;
}
.my_report_study_time_area .head{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.my_report_study_time_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
:lang(jp) .my_report_study_time_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'NotoSansJP-Regular';
	margin-right:12px;
}
.my_report_study_time_area .head p{
	font-size:14px;
	color:#b3b3b3;
}
.my_report_study_time_area .wrap{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
}
.my_report_study_time_area .wrap ul{
	width:100%;
	height:auto;
	padding:24px 30px;
	box-sizing:border-box;
}
.my_report_study_time_area .wrap li{
	width:100%;
	height:auto;
	display:-webkit-box;
	display:flex;
}
.my_report_study_time_area .wrap span{
	color:#4d4d4d;
	font-size:15px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .my_report_study_time_area .wrap span{
	color:#4d4d4d;
	font-size:15px;
	font-family: 'NotoSansJP-Regular';
}
.my_report_study_time_area .wrap .name{
	margin-right:10px;
}
.my_report_cognitive_ability_area{
	width:1060px;
	height:auto;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:60px;
	margin-bottom:178px;
}
.my_report_cognitive_ability_area .head{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.my_report_cognitive_ability_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
:lang(jp) .my_report_cognitive_ability_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'NotoSansJP-Regular';
	margin-right:12px;
}
.my_report_cognitive_ability_area .wrap{
	width:100%;
	height:394px;
	display:-webkit-box;
	display:flex;
	position:relative;
}
.my_report_cognitive_ability_area .ability_area{
	width:50%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	padding:52px 64px;
	padding-left:77px;
	box-sizing:border-box;
}
.my_report_cognitive_ability_area .ability_area:last-child{
	padding:52px 64px;
	padding-right:77px;
}
.my_report_cognitive_ability_area .cognitive_ability{
	width:100%;
	height:52px;
	margin-bottom:68px;
	display:-webkit-box;
	display:flex;
}
.my_report_cognitive_ability_area .cognitive_ability:last-child{
	margin-bottom:0;
}

/* my_report_bottom_area */
.my_report_bottom_area{
	width:1060px;
	height:auto;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:22px 22px 34px 34px;
	overflow:hidden;
	margin-top:5px;
	margin-bottom:20px;
}
.my_report_bottom_area .head{
	height:52px;
	width:100%;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	padding:0 30px;
	position:relative;
}
.my_report_bottom_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
:lang(jp) .my_report_bottom_area .head span{
	font-size:15px;
	color:#4d4d4d;
	font-family: 'NotoSansJP-Regular';
	margin-right:12px;
}
.my_report_bottom_area .head p{
	font-size:13px;
	color:#fcb12f;
	font-family: 'S-CoreDream-6Bold';
	margin-right:12px;
}
:lang(jp) .my_report_bottom_area .head p{
	font-size:13px;
	color:#fcb12f;
	font-family: 'NotoSansJP-Regular';
	margin-right:12px;
}
.my_report_bottom_area .wrap{
	width:100%;
	height:394px;
	display:-webkit-box;
	display:flex;
	position:relative;
}
.my_report_bottom_area .ability_area{
	width:50%;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	padding:52px 64px;
	padding-left:77px;
	box-sizing:border-box;
}
.my_report_bottom_area .ability_area:last-child{
	padding:52px 64px;
	padding-right:77px;
}
.my_report_bottom_area .cognitive_ability{
	width:100%;
	height:52px;
	margin-bottom:68px;
	display:-webkit-box;
	display:flex;
}
.my_report_bottom_area .cognitive_ability:last-child{
	margin-bottom:0;
}
.cognitive_ability .left_area{
	height:100%;
	width:40px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.cognitive_ability img{
	width:100%;
	height:auto;
}
.cognitive_ability img.creative_icon{
	width:114%;
}
.cognitive_ability .right_area{
	padding-left:6px;
	box-sizing:border-box;
	height:100%;
}
.cognitive_ability .right_area p{
	width:100%;
	height:22px;
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
}
.cognitive_ability .top_area{
	width:100%;
	height:30px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.cognitive_ability .top_area span{
	font-family: 'S-CoreDream-6Bold';
	font-size:24px;
	color:#f15a24;
}
:lang(jp) .cognitive_ability .top_area span{
	font-family: 'NotoSansJP-Regular';
	font-size:24px;
	color:#f15a24;
}
.cognitive_ability:nth-child(2) .ability_percent{
	color:#8cc63f;
}
.cognitive_ability:nth-child(3) .ability_percent{
	color:#00a99d;
}
.ability_area:last-child .cognitive_ability:nth-child(1) .ability_percent{
	color:#7f65be;
}
.ability_area:last-child .cognitive_ability:nth-child(2) .ability_percent{
	color:#008bbc;
}
.ability_area:last-child .cognitive_ability:nth-child(3) .ability_percent{
	color:#ff46c9;
}
.cognitive_ability .top_area span.name{
	font-size:16px;
	color:#4d4d4d;
	margin-right:14px;
}

/*mypage_stuedent_info*/
.translate_popup{
	display:none;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.translate_popup div{
	width:300px;
	height:230px;
	background:#ffffff;
	display:block;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
	box-sizing:border-box;
	padding:30px;
	border-radius:30px;
}
.translate_popup p{
	font-size:18px;
	text-align:center;
	margin-bottom:20px;
	position:relative;
	z-index:141;
}
.translate_popup select#student_info_product_select{
	width:100%;
	height:50px;
	border:1px solid #e6e6e6;
	border-radius:30px;
	font-size:15px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	background:url(../assets/select_arrow.png) no-repeat 90% center;
	box-sizing:border-box;
	padding:10px;
	cursor:pointer;
}
.translate_popup select option{
	font-size:16px;
}
.translate_popup div.btn_area{
	width:100%;
	height:36px;
	position:relative;
	top:0;
	left:0;
	padding:0;
	background:none;
	-webkit-transform:translate(0,0);
	transform:translate(0,0);
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:30px;
}
.translate_popup .confirm{
	width:40%;
	height:100%;
	position:relative;
	border:none;
	background:#009cc6;
	color:#ffffff;
	border-radius:30px;
	cursor:pointer;
}
.translate_popup .cancle{
	width:40%;
	height:100%;
	position:relative;
	border:1px solid #009cc6;
	background:#ffffff;
	color:#009cc6;
	border-radius:30px;
	margin-left:20px;
	cursor:pointer;
}

.mypage_stuedent_info .my_tit{
	margin-top:124px;
}
.parents_state_area{
	width:1000px;
	height:144px;
	position:relative;
	margin:0 auto;
	margin-top:66px;
}
.parents_state_area>p{
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
	margin-bottom:20px;
	margin-left:40px;
}
.mypage_stuedent_info p.ellip{
	width:96%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.mypage_stuedent_info span.ellip{
	width:96%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
/* 학부모 상태가 유료 일때 html에서 147,151번줄의 p태그에 class="pay" 추가 */
.parents_state_box{
	width:100%;
	height:110px;
	border-radius:55px;
	box-sizing:border-box;
	border:2px solid #00d5cd;
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.parents_state_box .state_head{
	height:85px;
	width:222px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:starat;
	display:flex;
	align-items:center;
	justify-content:flex-starat;
	box-sizing:border-box;
	border-right:1px solid #e6e6e6;
	padding-left:32px;
}
.state_head .profile_wrap{
	width:50px;
	height:50px;
	border-radius:50%;
	box-sizing:border-box;
	border:1px solid #cccccc;
	overflow:hidden;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:40px; 
}
.state_head .profile_wrap img{
	height:101%;
	width:auto;
}
.parents_state_box .state_head>span{
	font-family: 'S-CoreDream-6Bold';
	color:#4d4d4d;
	font-size:16px;
}
:lang(jp) .parents_state_box .state_head>span{
	font-family: 'NotoSansJP-Regular';
	color:#4d4d4d;
	font-size:16px;
}
.parents_state_box .right_area{
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.parents_state_box .state_list{
	height:85px;
	width:205px;
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
	padding-left:52px;
	box-sizing:border-box;
}
.parents_state_box .state_list:nth-child(1){
	padding-left:42px;
	width:140px;
}
.parents_state_box .state_list:nth-child(2){
	padding-left:20px;
}
.parents_state_box .state_list:nth-child(3){
	padding-left:10px;
	width:195px;
}
.parents_state_box .state_list:nth-child(4){
	padding-left:34px;
}
.state_list span{
	font-size:13px;
	font-family: 'S-CoreDream-6Bold';
	color:#999999;
	text-align:left;
	margin-bottom:18px;
}
:lang(jp) .state_list span{
	font-size:13px;
	font-family: 'NotoSansJP-Regular';
	color:#999999;
	text-align:left;
	margin-bottom:18px;
}
.state_list p{
	font-size:15px;
	color:#b3b3b3;
	text-align:left;
}
.state_list div{
	display:-webkit-box;
	display:flex;
	position:relative;
}
.state_list .info_popup_btn{
	border:none;
	background:none;
	height:20px;
	width:auto;
	margin-left:26px;
	cursor:pointer;
	position:relative;
}
.state_list .info_popup_btn img{
	height:100%;
	width:auto;
	-webkit-transform:rotate(90deg);
	transform:rotate(90deg);
}
.state_list .info_popup{
	display:none;
	width:auto;
	max-width:400px;
	height:auto;
	padding:20px;
	padding-right:56px;
	background:#ffffff;
	border:1px solid #e6e6e6;
	position:absolute;
	top:-10px;
	z-index:3;
	box-sizing:border-box;
}	
.state_list li{
	margin:5px 0;
}
.state_list .info_popup li.info_popup_close_btn{
	width:100%;
	height:16px;
	margin:0;
	position:absolute;
	top:20px;
	left:0;
	display:-webkit-box;
	-webkit-box-pack:end;
	display:flex;
	justify-content:flex-end;
	box-sizing:border-box;
	padding-right:20px;
}
.state_list .info_popup li.info_popup_close_btn img{
	height:100%;
	width:auto;
	cursor:pointer;
}
.state_list li span{
	margin-bottom:0;
}
.state_list li span.ellip{
	width:100%;
}
.parents_state_area .state_list p.pay{
	color:#f15a24;
}
.mypage_stuedent_info .line{
	background:#e6e6e6;
	height:1px;
	width:1060px;
	margin:0 auto;
	position:relative;
	margin-top:68px;
}
.student_state_area{
	width:900px;
	height:auto;
	min-height:500px;
	position:relative;
	margin:0 auto;
	margin-top:68px;
}
.student_state_box{
	width:100%;
	height:152px;
	border-radius:76px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:48px;
}
.student_state_box.make{
	border:1px solid #f15a24;
}
:lang(ko) .student_state_box.make::before{
	content:'구매한 학습계정';
	width:136px;
	height:28px;
	background:#f15a24;
	border-radius:14px;
	color:#ffffff;
	font-size:14px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:-14px;
	left:90px;
	z-index:10;
}
:lang(en) .student_state_box.make::before{
	content:'Account purchased';
	width:136px;
	height:28px;
	background:#f15a24;
	border-radius:14px;
	color:#ffffff;
	font-size:14px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:-14px;
	left:90px;
	z-index:10;
}
:lang(jp) .student_state_box.make::before{
	content:'購入したアカウント';
	width:136px;
	height:28px;
	background:#f15a24;
	border-radius:14px;
	color:#ffffff;
	font-size:14px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:-14px;
	left:90px;
	z-index:10;
}
.student_state_box .state_box_cover{
	display:none;
}
.student_state_box.make .state_box_cover{
	display:block;
	width:100%;
	height:100%;
	position:absolute;
	z-index:5;
	border-radius:76px;
	background:#ffffff;
	overflow:hidden;
}
.student_state_box.make .state_box_cover div{
	width:100%;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	justify-content:Center;
	flex-direction:column;
}
.student_state_box.make .state_box_cover p{
	color:#f15a24;
	font-size:14px;
	text-align:center;
	margin-bottom:15px;
}
.student_state_box.make .state_box_cover a{
	width:180px;
	height:40px;
	border-radius:30px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	border:1px solid #f15a24;
	color:#f15a24;
	font-size:13px;
	background:#ffffff;
}
.student_state_box.make .state_box_cover a:hover{
	background:#f15a24;
	color:#ffffff;
}
.student_state_box.pay{
	border:1px solid #009cc6;
}
.student_state_box.pay .state_list p.end{
	color:#f15a24;
}
.student_state_box:first-child{
	margin-top:0;
}
/*학습자 정보/관리 페이지에 처음 들어왔을때 새로운 학습자가 생성되었을때 클래스 student_state_box에 클래스 new 추가 */
.student_state_box.new{
	border:1px solid #f15a24;
}
:lang(ko) .student_state_box.new::before{
	content:'만료된 학습계정';
	width:136px;
	height:28px;
	background:#f15a24;
	border-radius:14px;
	color:#ffffff;
	font-size:14px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:-14px;
	left:90px;
}
:lang(en) .student_state_box.new::before{
	content:'Expired';
	width:136px;
	height:28px;
	background:#f15a24;
	border-radius:14px;
	color:#ffffff;
	font-size:14px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:-14px;
	left:90px;
}
:lang(jp) .student_state_box.new::before{
	content:'期限切れ';
	width:136px;
	height:28px;
	background:#f15a24;
	border-radius:14px;
	color:#ffffff;
	font-size:14px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:-14px;
	left:90px;
}
:lang(ko) .student_state_box.new::after{
	content:"* 이용권을 변경해 주셔야 플레이가 가능합니다.";
	display:block;
	color:#f15a24;
	font-size:14px;
	text-align:left;
	position:absolute;
	top:153px;
	left:90px;
}
:lang(en) .student_state_box.new::after{
	content:"* You need to change your ticket to play.";
	display:block;
	color:#f15a24;
	font-size:14px;
	text-align:left;
	position:absolute;
	top:153px;
	left:90px;
}
:lang(jp) .student_state_box.new::after{
	content:"* プレイするにはチケットの変更が必要です。";
	display:block;
	color:#f15a24;
	font-size:14px;
	text-align:left;
	position:absolute;
	top:153px;
	left:90px;
}
.student_state_box .state_head{
	height:85px;
	width:204px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
	box-sizing:border-box;
	border-right:1px solid #e6e6e6;
	padding-left:15px;
	padding-right:15px;
}
.student_state_box .state_head .profile_wrap{
	width:50px;
	height:50px;
	border-radius:50%;
	box-sizing:border-box;
	border:1px solid #cccccc;
	background:#fafafa;
	overflow:hidden;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:0; 
}
.student_state_box .state_head .profile_wrap span{
	font-size:22px;
	color:#4d4d4d;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .student_state_box .state_head .profile_wrap span{
	font-size:22px;
	color:#4d4d4d;
	font-family: 'NotoSansJP-Regular';
}
.student_state_box .state_head p{
	font-family: 'S-CoreDream-6Bold';
	color:#4d4d4d;
	font-size:16px;
	text-align:center;
	width:65%;
	white-space:normal;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
}
:lang(jp) .student_state_box .state_head p{
	font-family: 'NotoSansJP-Regular';
	color:#4d4d4d;
	font-size:16px;
	text-align:center;
	width:65%;
	white-space:normal;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
}
.student_state_box .right_area{
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.student_state_box .state_list{
	height:85px;
	width:172px; 
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
	padding-left:40px;
	box-sizing:border-box;
}
.student_state_box .state_list:nth-child(1){
	width:120px;
}
.student_state_box .state_list:nth-child(2){
	width:165px;
}
.student_state_box .state_list:last-child{
	width:188px;
	height:100%;
	padding-left:40px;
}
.student_state_box .state_list:last-child a{
	width:126px;
	height:50px;
	border-radius:30px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	border:1px solid #e6e6e6;
	color:#b3b3b3;
	font-size:13px;
}
/* 정보수정 a태그에 active 클래스 추가시 활성화, active 제거시 비활성화 */
/* 클래스 추가 없는 기본상태: 비활성화 */
.student_state_box .state_list:last-child a.active{
	pointer-events:auto;
	border:1px solid #e6e6e6;
	color:#b3b3b3;
}
.student_state_box .state_list:last-child button{
	width:126px;
	height:50px;
	border-radius:30px;
	box-sizing:border-box;
	border:1px solid #f15a24;
	color:#f15a24;
	font-size:13px;
	margin-top:10px;
	background:#ffffff;
	cursor:pointer;
	display:none;
}
/* .student_state_box.new .state_list:last-child button{
	display:block;
} */
.student_state_box .state_list:last-child button{
	display:block;
}
.student_state_box .state_list span{
	font-size:13px;
	font-family: 'S-CoreDream-6Bold';
	color:#999999;
	text-align:left;
	margin-bottom:18px;
}
:lang(jp) .student_state_box .state_list span{
	font-size:13px;
	font-family: 'NotoSansJP-Regular';
	color:#999999;
	text-align:left;
	margin-bottom:18px;
}
/* 학습자 상태가 유료 일때 html에서 174번줄의 p태그에 class="pay" 추가 */
.student_state_box .state_list p{
	font-size:15px;
	color:#b3b3b3;
	text-align:left;
}
.student_state_box .state_list:nth-child(4) p{
	visibility:hidden;
}
.student_state_box .state_list:nth-child(4) p.pay{
	color:#f15a24;
	visibility:visible;
}
.mypage_stuedent_info .go_price{
	width:300px;
	height:50px;
	background:#f15a24 url(../assets/btn_plus.png) no-repeat 10px center;
	background-size:34px;
	border-radius:25px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	color:#ffffff;
	font-size:16px;
	margin-top:60px;
	margin-bottom:200px;
}

/*mypage_parents_info*/
.mypage_parents_info .my_tit{
	margin-top:124px;
}
.mypage_parents_info .parents_info_area{
	width:1060px;
	height:auto;
	margin:0 auto;
	box-sizing:border-box;
	border-radius:50px 50px 28px 28px;
	border:1px solid #e6e6e6;
	padding:44px 64px;
	margin-top:16px;
	margin-bottom:160px;
}
.parents_info_area ul{
	width:100%;
	height:auto;
	position:relative;
}
.parents_info_area li{
	width:100%;
	height:86px;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
	display:-webkit-box;
	display:flex;
}
.parents_info_area li:last-child{
	border:none;
}
.parents_info_area li .head{
	height:100%;
	width:200px;
	font-size:15px;
	color:#666666;
	text-align:left;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.parents_info_area .right_area{
	height:100%;
	width:840px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.parents_info_area p{
	font-size:15px;
	color:#666666;
	text-align:left;
	padding-left:34px;
}
.parents_info_area p.info{
	font-size:13px;
	color:#b3b3b3;
	text-align:left;
	font-family: 'S-CoreDream-3Light';
	padding-left:0;
}
.parents_info_area input{
	width:257px;
	height:55px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:18px 34px;
	margin-right:32px;
	background:none;
}
.parents_info_area input::placeholder{
	color:#e6e6e6;
}
.parents_info_area button{
	width:147px;
	height:35px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	background:#ffffff;
	border-radius:17px;
	font-size:13px;
	color:#808080;
	cursor:pointer;
}
.parents_info_area li.type1{
	height:132px;
}
.parents_info_area li.type1 .right_area{
	-webkit-box-align:start;
	-webkit-box-pack:center;
	align-items:flex-start;
	justify-content:center;
	-webkit-box-orient:vertical;
	flex-direction:column;
}
.parents_info_area li.type1 p.info{
	font-size:11px;
	margin-top:10px;
}
.parents_info_area li.base{
	height:108px;
}
.parents_info_area li.base input{
	margin-right:0;
}
#parents_info_phone_number,
#parents_info_password,
#parents_info_password_confirm,
#parents_info_company_name,
#parents_info_company_number,
#parents_info_company_tel,
#parents_info_fax,
#parents_info_adress_number,
#parents_info_adress,
#parents_info_adress_more{
	background:#ffffff;
}
.parents_info_area li.base #parents_info_company_type{
	width:190px;
	height:40px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #808080;
	padding:8px 34px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(../assets/select_arrow.png) no-repeat 90% center;
	font-size:14px;
	color:#666666;
	cursor:pointer;
}
.parents_info_area li.base select option{
	font-size:14px;
	color:#666666;
}
.parents_info_area li.adress_area{
	height:256px;
}
.parents_info_area li.adress_area .right_area{
	-webkit-box-align:start;
	-webkit-box-pack:center;
	align-items:flex-start;
	justify-content:center;
	-webkit-box-orient:vertical;
	flex-direction:column;
}
.parents_info_area li.adress_area .top_area{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.parents_info_area li.adress_area .top_area input{
	margin-top:0;
	width:257px;
}
.parents_info_area li.adress_area input{
	margin-top:12px;
	width:644px;
}
.parents_info_area li.sms_add .top_area{
	display:-webkit-box;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	align-items:flex-start;
	justify-content:center;
}
.parents_info_area li.sms_add br{
	display:none;
}
.parents_info_area .radio_btn_area{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	width:auto;
}
.parents_info_area .radio_btn_area span{
	margin-right:56px;
	font-size:15px;
	color:#666666;
}
.parents_info_area .radio_btn_area input{
	width:30px;
	height:30px;
	margin-right:8px;
	-webkit-appearance:none;
	appearance:none;
	border-color:#e6e6e6;
	padding:0;
}
.parents_info_area .radio_btn_area input:checked{
	-webkit-appearance:none;
	appearance:none;
	background:#ff6e00;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.parents_info_area .radio_btn_area input:checked::after{
	content:'';
	display:block;
	width:17px;
	height:17px;
	border-radius:50%;
	background:#ffffff;
}
.parents_info_area li.withdrawal{
	height:40px;
	-webkit-box-pack:end;
	justify-content:flex-end;
	padding-right:20px;
	padding-top:12px;
	position:relative;
}
.parents_info_area .withdrawal button{
	border:none;
	width:auto;
	height:100%;
	text-decoration:underline;
	color:#b3b3b3;
	font-size:15px;
}
.parents_info_area .btn_area{
	margin-top:90px;
	width:100%;
	height:50px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.parents_info_area .btn_area button{
	width:150px;
	height:100%;
	border-radius:25px;
	background:#cccccc;
	font-size:16px;
	color:#ffffff;
	border:none;
}
.parents_info_area .btn_area button:last-child{
	background:#f16817;
	margin-left:20px;
}

/*mypage_pay_info*/
.refund_popup{
	display:none;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.refund_popup div{
	width:300px;
	height:480px;
	overflow-y:scroll;
	background:#ffffff;
	display:block;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
	box-sizing:border-box;
	padding:30px;
	border-radius:30px;
	scrollbar-width: none;
	-ms-overflow-style: none;
}
.refund_popup div::-webkit-scrollbar {
	display: none;
}
.refund_popup p{
	font-size:18px;
	text-align:center;
	margin-bottom:20px;
	position:relative;
	z-index:141;
}
.refund_popup p.info{
	font-size:13px;
	text-align:left;
	margin-bottom:0;
	margin-top:4px;
}
.refund_popup #virtual_account_number,
.refund_popup #virtual_account_holder{
	width:100%;
	height:50px;
	border:1px solid #e6e6e6;
	border-radius:30px;
	font-size:15px;
	box-sizing:border-box;
	padding:10px;
	margin-bottom:12px;
}
.refund_popup select#virtual_bank_name_select{
	width:100%;
	height:50px;
	border:1px solid #e6e6e6;
	border-radius:30px;
	font-size:15px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	background:url(../assets/select_arrow.png) no-repeat 90% center;
	box-sizing:border-box;
	padding:10px;
	cursor:pointer;
	margin-bottom:10px;
}
.refund_popup select option{
	font-size:16px;
}
.refund_popup div.btn_area{
	width:100%;
	height:36px;
	position:relative;
	top:0;
	left:0;
	padding:0;
	background:none;
	-webkit-transform:translate(0,0);
	transform:translate(0,0);
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:18px;
}
.refund_popup .confirm{
	width:40%;
	height:100%;
	position:relative;
	border:none;
	background:#009cc6;
	color:#ffffff;
	border-radius:30px;
	cursor:pointer;
}
.refund_popup .cancle{
	width:40%;
	height:100%;
	position:relative;
	border:1px solid #009cc6;
	background:#ffffff;
	color:#009cc6;
	border-radius:30px;
	margin-left:20px;
	cursor:pointer;
}

.mypage_pay_info .my_tit{
	margin-top:124px;
}
.mypage_pay_info .ellip{
	width:90%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.mypage_pay_info .pay_info_area{
	width:1078px;
	height:auto;
	min-height:432px;
	margin:58px auto;
	margin-bottom:160px;
}
.pay_info_area .title{
	width:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.pay_info_area .title p{
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
}
:lang(jp) .pay_info_area .title p{
	font-size:16px;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
}
.pay_info_area .title div{
	width:8px;
	height:8px;
	border-radius:50%;
	background:#333333;
	margin-right:4px;
}
.pay_info_area .title_info{
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	color:#808080;
}
.pay_info_area .pay_categori_select_div{
	margin-bottom: 10px;
}
#pay_categori_select{
	width:120px;
	height:100%;
	border-radius:10px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding-left:16px;
	font-size:16px;
	color:#666666;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(../assets/select_arrow.png) no-repeat 90% center;
	cursor:pointer;	
	float: right;
}
#pay_categori_select option{
	font-size:14px;
}
.mypage_pay_info .pay_info_list_wrap{
	width:100%;
	height:auto;
	margin-top:10px;
}
.pay_info_list_wrap li{
	width:100%;
	display:-webkit-box;
	display:flex;
	box-sizing:border-box;
}
.pay_info_list_wrap .list_head{
	height:80px;
	background:#f8f8f8;
	border:1px solid #e6e6e6;
	border-top:2px solid #333333;
}
.pay_info_list_wrap .list_head span{
	color:#666666;
	font-size:15px;
	text-align:center;
}
.pay_info_list_wrap li.list_head div{
	height:100%;
	width:170px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.pay_info_list_wrap li.list_head div:nth-child(2){
	width:196px;
}
.pay_info_list_wrap li.list_head div:nth-child(3){
	width:170px;
}
.pay_info_list_wrap li.list_head div:nth-child(4){
	width:186px;
}
.pay_info_list_wrap li.list_head div:nth-child(5){
	width:156px; 
}
.pay_info_list_wrap li.list_head div:nth-child(6){
	width:200px;
}
.pay_info_list_wrap .list{
	border-bottom:1px solid #e6e6e6;
	height:106px;
}
.pay_info_list_wrap li.list li{
	height:100%;
	width:170px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.pay_info_list_wrap ul.top_area{
	display:-webkit-box;
	display:flex;
}
.pay_info_list_wrap ul.bottom_area{
	display:-webkit-box;
	display:flex;
}
.pay_info_list_wrap li.list .top_area li:nth-child(2){
	width:196px;
}
.pay_info_list_wrap li.list .bottom_area li:nth-child(1){
	width:170px;
}
.pay_info_list_wrap li.list .bottom_area li:nth-child(2){
	width:186px;
}
.pay_info_list_wrap li.list .bottom_area li:nth-child(3){
	width:156px; 
}
.pay_info_list_wrap li.list .bottom_area li:nth-child(4){
	width:200px;
	-webkit-box-orient:vertical;
	flex-direction:column;
}
.pay_info_list_wrap .list .date{
	color:#b3b3b3;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	text-align:center;
}
.pay_info_list_wrap .list .name{
	color:#4d4d4d;
	font-size:15px;
	text-align:center;
	-webkit-line-clamp:2;
	white-space:normal;
	display:-webkit-box;
	-webkit-box-orient:vertical;
}
.pay_info_list_wrap .list .student_name{
	color:#b3b3b3;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	text-align:center;
}
.pay_info_list_wrap .list .fee{
	color:#b3b3b3;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	text-align:center;
}
.pay_info_list_wrap .list .pay_condition{
	color:#f15a24;
	font-size:15px;
	text-align:center;
}
.pay_info_list_wrap .pay_condition .pay_method{
	color:#808080;
	font-size:13px;
	text-align:center;
	font-family: 'S-CoreDream-3Light';
}
.pay_info_list_wrap .list a{
	color:#808080;
	font-size:13px;
	width:147px;
	height:35px;
	border-radius:17px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	text-align:center;
	margin:4px 0;
}
.pay_info_list_wrap .list button{
	color:#808080;
	background:#ffffff;
	font-size:13px;
	width:147px;
	height:35px;
	border-radius:17px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	margin:4px 0;
	cursor:pointer;
}
.list_pagebtn_area{
	width:100%;
	height:34px;
	margin-top:40px;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
.list_pagebtn_area li{
	height:100%;
	width:auto;
	margin:0 10px;
}
.list_pagebtn_area .first{
	width:auto;
}
.list_pagebtn_area .prev{
	width:auto;
}
.list_pagebtn_area .next{
	width:auto;
}
.list_pagebtn_area .last{
	width:auto;
}
.list_pagebtn_area li.arrow_btn img{
	height:15px;
	width:auto;
}
.list_pagebtn_area li a{
	height:100%;
	width:auto;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	align-items:center;
	font-size:15px;
	font-family: 'S-CoreDream-3Light';
	color:#808080;
}
.list_pagebtn_area li a:hover{
	color:#009cc6;
}
.list_pagebtn_area li.on a{
	color:#009cc6;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .list_pagebtn_area li.on a{
	color:#009cc6;
	font-family: 'NotoSansJP-Regular';
}

/*mypage_pay_info_more*/
.mypage_pay_info_more .my_tit{
	margin-top:124px;
}
.mypage_pay_info_more .ellip{
	width:90%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.mypage_pay_info_more .pay_info_more_area{
	width:1078px;
	height:auto;
	margin:86px auto;
	margin-bottom:160px;
	position:relative;
	box-sizing:border-box;
	border-top:2px solid #333333;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.pay_info_more_area ul{
	width:810px;
	height:auto;
	position:relative;
}
.pay_info_more_area li{
	width:100%;
	height:100px;
	display:-webkit-box;
	display:flex;
	box-sizing:border-box;
	border-bottom:1px solid #e6e6e6;
}
.pay_info_more_area li:nth-child(1){
	height:82px;
	border:none;
}
.pay_info_more_area li:nth-child(2){
	height:86px;
}
.pay_info_more_area .head, 
.pay_info_more_area p{
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	font-size:15px;
	text-align:left;
	box-sizing:border-box;
}
.pay_info_more_area div{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	font-size:15px;
	text-align:left;
	box-sizing:border-box;
}
.pay_info_more_area .head{
	width:326px;
	color:#666666;
	padding-left:149px;
}
.pay_info_more_area p{
	width:484px;
	color:#b3b3b3;
}
.pay_info_more_area li:nth-child(1) .head, 
.pay_info_more_area li:nth-child(1) p{
	-webkit-box-align:end;
	align-items:flex-end;
	padding-bottom:15px;
}
.pay_info_more_area li:nth-child(2) .head, 
.pay_info_more_area li:nth-child(2) p{
	-webkit-box-align:start;
	align-items:flex-start;
	padding-top:26px;
}
.pay_info_more_area li:nth-child(2) .head{
	color:#ff931e;
}
.pay_info_more_area li:nth-child(2) p{
	color:#ff931e;
}
.pay_info_more_area li.pay_more_product_info{
	height:auto;
	min-height:100px;
	padding:10px 0;
}
.pay_info_more_area li.pay_more_product_info span{
	height:inherit;
}
.pay_info_more_area li.pay_more_product_info p{
	height:auto;
}
.pay_info_more_area li.pay_more_total p{
	color:#4d4d4d;
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .pay_info_more_area li.pay_more_total p{
	color:#4d4d4d;
	font-size:16px;
	font-family: 'NotoSansJP-Regular';
}
.pay_info_more_area li.pay_more_refund{
	height:auto;
}
.pay_info_more_area li.pay_more_refund .head{
	height:inherit;
}
.pay_info_more_area li.pay_more_refund div{
	width:484px;
	height:auto;
	padding:12px 0;
	position:relative;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}
.pay_info_more_area li.pay_more_refund p{
	color:#f16817;
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	height:auto;
}
:lang(jp) .pay_info_more_area li.pay_more_refund p{
	color:#f16817;
	font-size:16px;
	font-family: 'NotoSansJP-Regular';
	height:auto;
}
.pay_info_more_area li.pay_more_refund p.info{
	font-size:14px;
	color:#808080;
	font-family:'S-CoreDream-3Light';
	margin-top:4px;
}
:lang(jp) .pay_info_more_area li.pay_more_refund p.info{
	font-size:14px;
	color:#808080;
	font-family:'NotoSansJP-Thin';
	margin-top:4px;
}
.pay_info_more_area li.info_txt{
	height:auto;
}
.pay_info_more_area li.info_txt .head{
	height:inherit;
}
.pay_info_more_area li.info_txt div{
	width:484px;
	height:auto;
	padding:12px 0;
	position:relative;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}
.pay_info_more_area li.info_txt div.top_area{
	width:auto;
	padding:0;
	-webkit-box-orient:horizontal;
	-webkit-box-pack:start;
	-webkit-box-align:center;
	flex-direction:row;
	justify-content:flex-start;
	align-items:center;
}
.pay_info_more_area li.info_txt .fake_account_number_copy{
	color:#808080;
	background:#ffffff;
	border:1px solid #b3b3b3;
	cursor:pointer;
	border-radius:20px;
	font-size:13px;
	padding:0 6px; 
}
.pay_info_more_area li.info_txt p{
	height:auto;
	width:auto;
	margin-right:12px;
}
.pay_info_more_area li.info_txt p.info{
	font-size:14px;
	color:#f16817;
	font-family:'S-CoreDream-3Light';
	margin-top:4px;
}
.pay_info_more_area a{
	width:150px;
	height:50px;
	border-radius:25px;
	background:#f16817;
	font-size:16px;
	color:#ffffff;
	margin-top:100px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.pay_info_more_area li a{
	width:100%;
	height:auto;
	background:none;
	color:#009cc6;
	font-size:15px;
	margin-top:0;
	-webkit-box-pack:start;
	justify-content:flex-start;
}

/*mypage_student_register*/
.mypage_student_register .my_tit{
	margin-top:124px;
}
.mypage_student_register .ellip{
	width:90%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.mypage_student_register .student_register_area{
	width:1060px;
	height:auto;
	margin:86px auto;
	margin-bottom:160px;
	position:relative;
	box-sizing:border-box;
	border-top:2px solid #333333;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.student_register_area ul{
	width:100%;
	height:auto;
	position:relative;
}
.student_register_area li{
	width:100%;
	height:128px;
	box-sizing:border-box;
	display:-webkit-box;
	display:flex;
}
.student_register_area .gender{
	width:100%;
}
.student_register_area li.product{
	height:205px;
	border-bottom:1px solid #E6E6E6;
}
.student_register_area li.overlap{
	height:144px;
}
.student_register_area li:last-child{
	height:170px;
	border-bottom:1px solid #E6E6E6;
}
.student_register_area li .head{
	height:100%;
	width:396px;
	font-size:15px;
	color:#666666;
	text-align:left;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	box-sizing:border-box;
	padding-left:218px;
}
.student_register_area .right_area{
	height:100%;
	width:664px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	position:relative;
}
:lang(ko) .student_register_area li.addstring .right_area::before{
	content:'생년월일, 성별 데이터를 정확하게 입력해주시면 학습자의 데이터 분석에 많은 도움이 됩니다.';
	display:block;
	color:#1b93f0;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(en) .student_register_area li.addstring .right_area::before{
	content:"If you enter the date of birth and gender data accurately, it will help you analyze the learner's data.";
	display:block;
	color:#1b93f0;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(jp) .student_register_area li.addstring .right_area::before{
	content:"生年月日と性別のデータを正確に入力すれば、学習者のデータ分析に役立ちます。";
	display:block;
	color:#1b93f0;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(ko) .student_register_area li.nokorean .right_area::before{
	content:'2~50자리의 영어와 숫자만 입력해주세요';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(en) .student_register_area li.nokorean .right_area::before{
	content:'Please enter 2-50 digits of English and numbers';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(jp) .student_register_area li.nokorean .right_area::before{
	content:'2~50桁の英語と数字だけ入力してください';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(ko) .student_register_area li.overlap .right_area::before{
	content:'중복된 아이디입니다';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(en) .student_register_area li.overlap .right_area::before{
	content:'Duplicate ID';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(jp) .student_register_area li.overlap .right_area::before{
	content:'重複 ID';
	display:block;
	color:#F15A24;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(ko) .student_register_area li.overlapno .right_area::before{
	content:'사용 가능한 아이디입니다';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(en) .student_register_area li.overlapno .right_area::before{
	content:'You can use this ID';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
:lang(jp) .student_register_area li.overlapno .right_area::before{
	content:'このIDを使用することができます';
	display:block;
	color:#009cc6;
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	position:absolute;
	top:75%;
	left:10px;
}
.student_register_area li.product .right_area{
	-webkit-box-orient:vertical;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}
.student_register_area li.type1 .right_area{
	-webkit-box-orient:vertical;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}
.student_register_area p.info{
	font-size:12px;
	color:#b3b3b3;
	text-align:left;
	font-family: 'S-CoreDream-3Light';
	margin-top:12px;
}
.student_register_area select{
	width:160px;
	height:55px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:15px 34px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:url(../assets/select_arrow.png) no-repeat 90% center;
	color:#B3B3B3;
	margin-left:10px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	cursor:Pointer;
}
#my_register_product_list_select{
	width:295px;
	height:52px;
	box-sizing:border-box;
	padding-right:55px;
	color:#666666;
}

.student_register_area input{
	width:295px;
	height:55px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:18px 34px;
	background:none;
}
.student_register_area li.overlap .right_area input{
	border:1px solid #F15A24;
}
.student_register_area li.nokorean .right_area input{
	border:1px solid #F15A24;
}
.student_register_area input::placeholder{
	color:#e6e6e6;
}
.student_register_area li button{
	width:104px;
	height:35px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	background:#ffffff;
	border-radius:17px;
	font-size:13px;
	color:#808080;
	cursor:pointer;
	margin-left:10px;
}
.student_register_area .radio_btn_area{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	width:auto;
}
.student_register_area .radio_btn_area span{
	margin-right:56px;
	font-size:15px;
	color:#666666;
}
.student_register_area .radio_btn_area input{
	width:30px;
	height:30px;
	margin-right:8px;
	-webkit-appearance:none;
	appearance:none;
	border-color:#e6e6e6;
	padding:0;
}
.student_register_area .radio_btn_area input:checked{
	-webkit-appearance:none;
	appearance:none;
	background:#ff6e00;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.student_register_area .radio_btn_area input:checked::after{
	content:'';
	display:block;
	width:17px;
	height:17px;
	border-radius:50%;
	background:#ffffff;
}
.student_register_area .btn_area{
	margin-top:90px;
	width:100%;
	height:50px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.student_register_area .btn_area a{
	width:150px;
	height:100%;
	border-radius:25px;
	background:#cccccc;
	font-size:16px;
	color:#ffffff;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.student_register_area .btn_area button{
	background:#f16817;
	margin-left:20px;
	width:150px;
	height:100%;
	border-radius:25px;
	font-size:16px;
	color:#ffffff;
	border:none;
	cursor:pointer;
}
.student_register_area select{
	width:160px;
	height:55px;
	border-radius:27px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:15px 34px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:url(../assets/select_arrow.png) no-repeat 90% center;
	color:#B3B3B3;
	margin-left:10px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	font-size:16px;
}

/* 간편 로그인시 학습자 등록하는 페이지 */
.add_student_area .student_register_area li:first-child{
	border-bottom:none;
}
.add_student_area .student_register_area .btn_area button{
	width:150px;
	height:100%;
	border-radius:25px;
	background:#cccccc;
	font-size:16px;
	color:#ffffff;
	border:none;
	cursor:pointer;
}
.add_student_area .student_register_area .btn_area button:last-child{
	background:#f16817;
	margin-left:20px;
	width:150px;
	height:100%;
	border-radius:25px;
	font-size:16px;
	color:#ffffff;
	border:none;
}

/*mypage_student_infofix*/
.mypage_student_infofix .student_register_area ul{
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
}
.mypage_student_infofix .student_register_area li{
	height:128px;
}
.mypage_student_infofix .student_register_area li:last-child{
	border:none;
	margin-bottom:20px;
}
.mypage_student_infofix .student_register_area li.infofix_vertical .right_area{
	-webkit-box-orient:vertical;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}
.mypage_student_infofix .student_register_area li.infofix_normal .right_area span{
	color:#666666;
	font-size:15px;
	padding-left:34px;
}
.mypage_student_infofix .student_register_area li.infofix_normal.infofix_vertical .right_area span:last-child{
	color:#b3b3b3;
	margin-top:6px;
}
.mypage_student_infofix .student_register_area li.type1 .right_area{
	-webkit-box-orient:vertical;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
}

#my_info_fix_month_list_select
,#my_register_month_list_select{
	margin-right:0;
}

/*mypage_coupon*/
.mypage_coupon .my_tit{
	margin-top:124px;
}
.mypage_coupon .coupon_title_info{
	margin-top:16px;
	font-family:'S-CoreDream-3Light';
	font-size:16px;
}
.mypage_coupon .coupon_register_area{
	width:100%;
	height:42px;
	margin-top:32px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
#coupon_register_input{
	width:420px;
	height:100%;
	border-radius:21px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:14px 26px;
	font-size:14px; 
	font-family:'S-CoreDream-3Light';
}
#coupon_register_input::placeholder{
	color:#b3b3b3;
}
.coupon_register_area button{
	/* width:80px; */
	width: auto;
	height:100%;
	border-radius:21px;
	font-size:14px;
	color:#ffffff;
	margin-left:12px;
	border:none;
	background:#009cc6;
	cursor:pointer;
	padding-left: 20px;
	padding-right: 20px;
} 
.mypage_coupon .coupon_list_area{
	width:1078px;
	height:auto;
	min-height:432px;
	margin:58px auto;
	margin-bottom:160px;
}
.coupon_list_area .coupon_list_top_area{
	width:100%;
	height:32px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:justify;
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.coupon_list_top_area .title{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	position:relative;
}
.coupon_list_top_area .title div{
	width:8px;
	height:8px;
	border-radius:50%;
	background:#333333;
	margin-right:4px;
}
.coupon_list_top_area .title p{
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
	margin-right:2px;
}
:lang(jp) .coupon_list_top_area .title p{
	font-size:16px;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
	margin-right:2px;
}
.coupon_list_top_area .coupon_count{
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	color:#f15a24;
}
:lang(jp) .coupon_list_top_area .coupon_count{
	font-size:16px;
	font-family: 'NotoSansJP-Regular';
	color:#f15a24;
}
#coupon_categori_select{
	width:120px;
	height:100%;
	border-radius:10px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding-left:16px;
	font-size:16px;
	color:#666666;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(../assets/select_arrow.png) no-repeat 90% center;
	cursor:pointer;
}
#coupon_categori_select option{
	font-size:14px;
}
.mypage_coupon .coupon_list_wrap{
	width:100%;
	height:auto;
	margin-top:10px;
}
.coupon_list_wrap li{
	width:100%;
	display:-webkit-box;
	display:flex;
	box-sizing:border-box;
}
.coupon_list_wrap .list_head{
	height:60px;
	background:#f8f8f8;
	border:1px solid #e6e6e6;
	border-top:2px solid #333333;
}
.coupon_list_wrap .list_head span{
	color:#666666;
	font-size:15px;
	text-align:center;
}
.coupon_list_wrap li.list_head div{
	width:200px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.coupon_list_wrap li.list_head .coupon_name{
	width:478px;
}
.coupon_list_wrap li.list_head .coupon_use_date{
	width:240px;
}
.coupon_list_wrap li.list_head .coupon_state{
	width:160px;
}
.coupon_list_wrap .no_list{
	height:100px;
	border-bottom:1px solid #e6e6e6;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	align-items:center;
	justify-content:center;
}
.coupon_list_wrap .no_list span{
	font-size:15px;
	color:#b3b3b3;
}
.coupon_list_wrap .list{
	border-bottom:1px solid #e6e6e6;
	height:80px;
	display:-webkit-box;
	display:flex;
	position:relative;
}
.coupon_list_wrap .list .top_area{
	width:478px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.coupon_list_wrap .list .coupon_name{
	font-size:15px;
	color:#4d4d4d;
	text-align:center;
	width:auto;
	max-width:80%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.coupon_list_wrap .list .coupon_d_day{
	width:auto;
	height:18px;
	box-sizing:border-box;
	border:1px solid #f15a24;
	border-radius:9px;
	font-size:13px;
	color:#f15a24;
	margin-left:7px;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	padding:0 5px;
}
.coupon_list_wrap .list.end .coupon_d_day{
	display:none;
}
.coupon_list_wrap .list .middle_area{
	width:400px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.coupon_list_wrap .list .coupon_use_date{
	width:240px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.coupon_list_wrap .list .coupon_use_date span{
	font-family:'S-CoreDream-3Light';
	font-size:15px;
	color:#b3b3b3;
}
.coupon_list_wrap .list .coupon_state{
	width:160px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.coupon_list_wrap .list .coupon_state span{
	font-size:15px;
	color:#009cc6;
}
.coupon_list_wrap .list.end .coupon_state span{
	color:#b3b3b3;
}
.coupon_list_wrap .list .bottom_area{
	width:200px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.coupon_list_wrap .list a{
	color:#808080;
	font-size:13px;
	width:147px;
	height:35px;
	border-radius:17px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	text-align:center;
	margin:4px 0;
}
.list_more_btn{
	width:100%;
	height:46px;
	box-sizing:border-box;
	background:#ffffff;
	border-radius:5px;
	color:#808080;
	font-size:16px;
	margin-top:24px;
	border:1px solid #b3b3b3;
	display:none;
}
.mypage_coupon .coupon_info_area{
	width:100%;
	height:auto;
	position:relative;
	margin-top:58px;
}
.coupon_info_area .top_area{
	width:100%;
	height:16px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content: flex-start;
}
.coupon_info_area .top_area span{
	font-size:15px;
	color:#808080;
}
.coupon_info_area .top_area .icon{
	width:16px;
	height:16px;
	border-radius:50%;
	background:#b3b3b3;
	font-size:13px;
	color:#ffffff;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content: center;
	margin-right:4px;
}
.coupon_info_area .bottom_area{
	width:100%;
	height:auto;
	margin-top:10px;
	box-sizing:border-box;
	border-top:1px solid #e6e6e6;
	padding:10px 16px;
}
.coupon_info_area .bottom_area li{
	font-family: 'S-CoreDream-3Light';
	color:#808080;
	font-size:13px;
}

/* coupon_info_more.html */
.coupon_info_more .pay_info_more_area .apply_coupon_count p{
	font-size:16px;
	color:#4d4d4d;
}
.coupon_info_more .pay_info_more_area .coupon_state p{
	color:#009cc6;
}
.coupon_info_more .pay_info_more_area .coupon_state.end p{
	color:#b3b3b3;
}

/* my_qna_info.html */
.mypage_qna_info .my_tit{
	margin-top:124px;
}
.mypage_qna_info .qna_info_area{
	width:1078px;
	height:auto;
	min-height:432px;
	margin:58px auto;
	margin-bottom:160px;
}
.qna_info_area .title{
	width:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.qna_info_area .title p{
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
}
:lang(jp) .qna_info_area .title p{
	font-size:16px;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
}
.qna_info_area .title div{
	width:8px;
	height:8px;
	border-radius:50%;
	background:#333333;
	margin-right:4px;
}
.mypage_qna_info .qna_info_list_wrap{
	width:100%;
	height:auto;
	margin-top:10px;
}
.qna_info_list_wrap li{
	width:100%;
	display:-webkit-box;
	display:flex;
	box-sizing:border-box;
}
.qna_info_list_wrap .list_head{
	height:60px;
	background:#f8f8f8;
	border:1px solid #e6e6e6;
	border-top:2px solid #333333;
}
.qna_info_list_wrap .list_head span{
	color:#666666;
	font-size:15px;
	text-align:center;
}
.qna_info_list_wrap li.list_head div{
	width:200px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.qna_info_list_wrap li.list_head .qna_number{
	width:60px;
}
.qna_info_list_wrap li.list_head .qna_title{
	width:438px;
}
.qna_info_list_wrap li.list_head .qna_name{
	width:140px;
}
.qna_info_list_wrap li.list_head .qna_date{
	width:120px;
}
.qna_info_list_wrap li.list_head .qna_state{
	width:120px;
}
.qna_info_list_wrap .no_list{
	height:100px;
	border-bottom:1px solid #e6e6e6;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	align-items:center;
	justify-content:center;
}
.qna_info_list_wrap .no_list span{
	font-size:15px;
	color:#b3b3b3;
}
.qna_info_list_wrap .list{
	border-bottom:1px solid #e6e6e6;
	height:80px;
	display:-webkit-box;
	display:flex;
	position:relative;
}
.qna_info_list_wrap .list .number_area{
	width:60px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.qna_info_list_wrap .list .number_area span{
	font-size:15px;
	color:#4d4d4d;
	font-family:'S-CoreDream-3Light';
}
.qna_info_list_wrap .list .right_area{
	width:calc(100% - 60px);
	height:100%;
	position:relative;
	display:-webkit-box;
	display:flex;
}
.qna_info_list_wrap .list .top_area{
	width:438px;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.qna_info_list_wrap .list .qna_title{
	width:auto;
	max-width:90%;
	font-size:15px;
	color:#4d4d4d;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.qna_info_list_wrap .list .middle_area{
	width:auto;
	height:100%;
	position:relative;
	display:-webkit-box;
	display:flex;
}
.qna_info_list_wrap .list .name{
	width:140px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.qna_info_list_wrap .list .name span{
	font-family:'S-CoreDream-3Light';
	font-size:15px;
	color:#b3b3b3;
	width:auto;
	max-width:90%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.qna_info_list_wrap .list .date{
	font-family:'S-CoreDream-3Light';
	font-size:15px;
	color:#b3b3b3;
	width:120px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.qna_info_list_wrap .list .state{
	font-size:15px;
	color:#f15a24;
	width:120px;
	height:100%;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.qna_info_list_wrap .list.complete .state{
	color:#009cc6;
}
.qna_info_list_wrap .list .bottom_area{
	width:200px;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.qna_info_list_wrap .list a{
	color:#808080;
	font-size:13px;
	width:147px;
	height:35px;
	border-radius:17px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	text-align:center;
	margin:4px 0;
}
/* no_member_qna_info.html */
/* 비회원 문의 내역 */
.mypage_tab.no_member_qna_info{
	min-height:calc(100vh - 350px);
}

/* qna_info_more.html */
.qna_info_more .pay_info_more_area li:nth-child(1){
	display:none;
}
.qna_info_more .pay_info_more_area li:nth-child(2){
	display:none;
}
.qna_info_more .pay_info_more_area .answer_state p{
	color:#f15a24;
}
.qna_info_more .pay_info_more_area.complete .answer_state p{
	color:#009cc6;
}
.qna_info_more .pay_info_more_area .question{
	height:auto;
	min-height:100px;
	padding:16px 0;
}
.qna_info_more .pay_info_more_area li.question span{
	height:inherit;
}
.qna_info_more .pay_info_more_area .question p{
	color:#4d4d4d;
	height:auto;
	white-space: pre-wrap;
} 
.qna_info_more .pay_info_more_area .answer_list{
	display:none;
} 
.qna_info_more .pay_info_more_area.complete .answer_list{
	display:-webkit-box;
	display:flex;
} 
.qna_info_more .pay_info_more_area .answer{
	height:auto;
	min-height:100px;
	padding:16px 0;
}
.qna_info_more .pay_info_more_area li.answer span{
	height:inherit;
}
.qna_info_more .pay_info_more_area .answer p{
	/* font-family:'S-CoreDream-6Bold'; */
	color:#4d4d4d;
	height:auto;
	white-space: pre-wrap;
} 


/*download.html*/
.download_area{
	width:100%;
	height:auto;
	position:relative;
	background:#fff9f0;
	padding-bottom:95px;
}
.download_top_line_banner{
	width:100%;
	height:85px;
	position:relative;
	background:#1E83D6;
}
.download_top_line_banner .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.download_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .download_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
}
.download_area .download_wrap{
	width:1112px; 
	height:auto;
	position:relative;
	box-sizing:border-box;
	margin:95px auto;
	border:2px solid #edd0a6;
	padding:56px 65px;
	padding-top:70px;
	border-radius:94px;
}
.download_wrap .top_top_area{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-pack:justify;
	-webkit-box-align:start;
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	position:relative;
}
.download_wrap .left_area{
	width:500px;
	height:auto;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-pack:start;
	display:flex;
	flex-direction:column;
	justify-content:flex-start;
}
.download_wrap .left_area img{
	width:323px;
	height:auto;
}
.download_wrap .left_area .download_title_wrap{
	width:384px;
	position:relative;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.download_wrap .download_title_wrap span{
	color:#f9be2d;
	font-family:'S-CoreDream-6Bold';
	/* font-size:17px; */
	font-size:13px;
}
:lang(jp) .download_wrap .download_title_wrap span{
	color:#f9be2d;
	font-family: 'NotoSansJP-Regular';
	/* font-size:17px; */
	font-size:13px;
}
.download_wrap .download_title_wrap img{
	width:100%;
	margin-top:8px;
}
.download_wrap .left_area p{
	/* font-size:36px; */
	font-size:28px;
	text-align:left;
	margin-top:35px;
}
.download_wrap .left_area p.sub_title{
	color:#666666;
	font-size:24px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .download_wrap .left_area p.sub_title{
	color:#666666;
	font-size:24px;
	font-family: 'NotoSansJP-Regular';
}
.download_wrap .down_link_area{
	width:400px;
	height:65px;
	margin-top:47px;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
.download_wrap .down_link_area a{
	width:180px;
}
.download_wrap .down_link_area a.apple{
	margin-left:25px;
}
.download_wrap .down_link_area img{
	width:100%;
	height: 60px;
}	
.download_wrap .right_area{
	width:465px;
	height:auto;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
}
.download_wrap .right_area .top_area{
	width:100%;
	height:322px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.download_wrap .right_area .top_area img{
	height:100%;
	width:auto;
}
.download_wrap .right_area .bottom_area{
	width:100%;
	height:auto;
	margin-top:18px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	flex-wrap:wrap;
}
.download_wrap .right_area .bottom_area img{
	height:101px;
	width:auto;
	margin-top:22px;
}
.download_wrap .right_area .bottom_area img:nth-child(2){
	margin:0 13px;
	margin-top:22px;
}
.download_wrap .right_area .bottom_area img:nth-child(5){
	margin:0 13px;
	margin-top:22px;
}
.download_wrap .btn_area{
	width:100%;
	height:133px;
	position:relative;
	margin-top:160px;
	margin-bottom:37px;
}
.download_wrap .btn_area button{
	height:100%;
	width:688px;
	border-radius:38px;
	box-sizing:border-box;
	margin:0 auto;
	background:#007cff;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:42px;
	color:#ffffff;
	text-align:center;
	border:none;
	cursor:pointer;
}

/*pay.html*/
.pay_add_info_popup{
	display:none;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.pay_add_info_popup div{
	width:300px;
	height:230px;
	background:#ffffff;
	display:block;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
	box-sizing:border-box;
	padding:30px;
	border-radius:30px;
}
.pay_add_info_popup p{
	font-size:18px;
	text-align:center;
	margin-bottom:20px;
	position:relative;
	z-index:141;
}
.pay_add_info_popup #pay_add_phonenumber{
	width:100%;
	height:50px;
	border:1px solid #e6e6e6;
	border-radius:30px;
	font-size:15px;
	box-sizing:border-box;
	padding:10px;
}
.pay_add_info_popup div.btn_area{
	width:100%;
	height:36px;
	position:relative;
	top:0;
	left:0;
	padding:0;
	background:none;
	-webkit-transform:translate(0,0);
	transform:translate(0,0);
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:30px;
}
.pay_add_info_popup .confirm{
	width:40%;
	height:100%;
	position:relative;
	border:none;
	background:#009cc6;
	color:#ffffff;
	border-radius:30px;
	cursor:pointer;
}
.pay_add_info_popup .cancle{
	width:40%;
	height:100%;
	position:relative;
	border:1px solid #009cc6;
	background:#ffffff;
	color:#009cc6;
	border-radius:30px;
	margin-left:20px;
	cursor:pointer;
}

.pay_area{
	width:100%;
	height:auto;
	min-height:1000px;
	position:relative;
	background:#fff9f0;
}
.pay_top_line_banner{
	width:100%;
	height:85px;
	position:relative;
	background:#1E83D6;
}
.pay_top_line_banner .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.pay_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .pay_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
}
.pay_area .pay_wrap{
	width:100%; 
	height:auto;
	position:relative;
	background:#fff9f0;
}
.pay_wrap .product_area{
	width:1023px;
	height:auto;
	position:relative;
	box-sizing:border-box;
	border-radius:50px;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	border:2px solid #edd0a6;
	margin:0 auto;
	margin-top:42px;
	overflow:hidden;
} 
.pay_wrap .product_area .top_top_area{
	width:100%;
	height:auto;
}
.product_area .top_top_area img{
	width:100%;
	height:auto;
}
.pay_wrap .product_area ul{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
	box-sizing:border-box;
	padding:20px;
}
.pay_wrap .product_area li{
	width:258px;
	height:auto;
	box-sizing:border-box;
	padding:16px 20px;
	padding-top:12px;
	margin:0 15px;
	border-radius:22px;
	border:2px solid #999999;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	cursor:pointer;
}
.pay_wrap .product_area li .scratch{
	width:184px;
	height:14px;
	border-radius:7px;
	background:#999999;
}
.pay_wrap .product_area li.on{
	border:2px solid #0060ff;
}
.pay_wrap .product_area li.on .scratch{
	background:#0060FF;
}
.pay_wrap .product_name{
	width:100%;
	height:auto;
	margin-top:17px;
	padding-bottom:19px;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:end;
	display:flex;
	justify-content:center;
	align-items:flex-end;
	box-sizing:border-box;
	border-bottom:1px solid #cccccc;
}
.pay_wrap .product_name p{
	color:#999999;
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
	padding-bottom:2px;
	line-height:1;
}
:lang(jp) .pay_wrap .product_name p{
	color:#999999;
	font-size:16px;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
	padding-bottom:2px;
	line-height:1;
}
.pay_wrap .product_name .period{
	font-size:26px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
	margin-right:10px;
	line-height:1;
}
:lang(jp) .pay_wrap .product_name .period{
	font-size:26px;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
	margin-right:10px;
	line-height:1;
}
.pay_wrap .product_price{
	width:100%;
	height:auto;
	box-sizing:border-box;
	padding-top:20px;
	padding-bottom:16px;
	display:-webkit-box;
	-webkit-box-pack:center;
	-webkit-box-align:end;
	display:flex;
	justify-content:center;
	align-items:flex-end;
}
.pay_wrap .product_price .left_area{
	width:auto;
	height:auto;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
	flex-direction:column;
	margin-right:24px;
}
.pay_wrap .product_price p{
	font-size:20px;
	text-align:left;
	color:#999999;
	position:relative;
	margin-bottom:12px;
	line-height:1;
}
.pay_wrap .product_price p::after{
	content:'';
	display:block;
	width:100%;
	height:1px;
	background:#999999;
	position:absolute;
	top:50%;
}
.pay_wrap .product_price .price{
	font-size:28px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
	color:#39b54a;
	line-height:1;
}
:lang(jp) .pay_wrap .product_price .price{
	font-size:28px;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
	color:#39b54a;
	line-height:1;
}
.pay_wrap .discount_area{
	width:62px;
	height:36px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	box-sizing:border-box;
	border-radius:6px;
	background:#00FF00;
	color:#1A1A1A;
	text-align:center;
}
.pay_wrap .student_count_area{
	width:150px;
	height:86px;
	box-sizing:border-box;
	padding:6px 10px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	border-radius:18px;
	border:1px solid #a2a2a2;
	margin-bottom:10px;
	position:relative;
}
.pay_wrap .student_count_area .count_area{
	width:70px;
	height:100%;
	box-sizing:border-box;
	padding:0 6px;
	border-right:1px solid #cccccc;
}
.pay_wrap .count_area input{
	width:100%;
	height:40px;
	box-sizing:border-box;
	border:none;
	font-size:18px;
	color:#f15a24;
	background:none;
	text-align:center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.pay_wrap .count_area input:focus{
	outline:none;
}
.pay_wrap .count_area span{
	width:100%;
	height:34px;
	box-sizing:border-box;
	border-bottom:1px solid #cccccc;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:Center;
	justify-content:Center;
	font-size:14px;
	color:#666666;
}
.pay_wrap .student_count_area .count_btn_area{
	width:60px;
	height:100%;
	box-sizing:border-box;
	padding-left:10px;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.pay_wrap .count_btn_area button{
	width:36px;
	height:36px;
	border-radius:50%;
	background:none;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
}
.pay_wrap .count_btn_area button:last-child{
	margin-top:4px;
}
.pay_wrap .count_btn_area button img{
	width:auto;
	height:100%;
}
.pay_wrap .product_total_price_area{
	width:100%;
	height:52px;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
	box-sizing:border-box;
	border-top:1px solid #cccccc;
}
.pay_wrap .product_total_price_area span{ 
	margin-right:12px;
	line-height:1;
	font-size:17px;
}
.pay_wrap .product_total_price_area .product_total_price{ 
	font-family: 'S-CoreDream-6Bold';
	margin-right:0;
	font-size:20px;
	display:-webkit-box;
	-webkit-box-align:end;
	display:flex;
	align-items:flex-end;
}
:lang(jp) .pay_wrap .product_total_price_area .product_total_price{ 
	font-family: 'NotoSansJP-Regular';
	margin-right:0;
	font-size:20px;
	display:-webkit-box;
	-webkit-box-align:end;
	display:flex;
	align-items:flex-end;
}
.pay_wrap .product_total_price_area .product_total_price::before{ 
	content:'₩';
	display:block;
	font-size:22px;
	font-family: 'S-CoreDream-6Bold';
	margin-right:4px;
}
:lang(jp) .pay_wrap .product_total_price_area .product_total_price::before{ 
	content:'₩';
	display:block;
	font-size:22px;
	font-family: 'NotoSansJP-Regular';
	margin-right:4px;
}
.pay_wrap .btn_area{
	width:221px;
	height:46px;
	position:relative;
	margin:0 auto;
}
.pay_wrap .btn_area button{
	width:100%;
	height:100%;
	border-radius:11px;
	background:#007cff;
	color:#ffffff;
	font-size:22px;
	font-family: 'S-CoreDream-6Bold';
	border:none;
	cursor:pointer;
}
:lang(jp) .pay_wrap .btn_area button{
	width:100%;
	height:100%;
	border-radius:11px;
	background:#007cff;
	color:#ffffff;
	font-size:22px;
	font-family: 'NotoSansJP-Regular';
	border:none;
	cursor:pointer;
}

.pay_wrap .pay_guide{
	width:1060px;
	height:127px;
	position:relative;
	margin:0 auto;
	box-sizing:border-box;
	margin-top:23px;
	padding:0 63px;
	border-bottom:2px solid #edd0a6;
	display:-webkit-box;
	-webkit-align-content:start;
	display:flex;
	align-content:flex-start;
	flex-wrap:wrap;
}
.pay_wrap .pay_guide li{
	color:#000000;
	font-size:12px;
	margin-right:30px;
	margin-top:10px;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.pay_wrap .pay_guide li br{
	display:none;
}
.pay_wrap .pay_guide li::before{
	content:'';
	display:block;
	background:#000000;
	width:6px;
	height:6px;
	margin-right:4px;
	border-radius:50%;
}
.pay_wrap .pay_guide li:nth-child(1) a{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	position:relative;
}
.pay_wrap .pay_guide li a::after{
	content:'';
	display:block;
	width:16px;
	height:16px;
	background:url(../assets/now_go_icon@2x.png) no-repeat center;
	background-size:cover;
	margin-left:8px;
}
.pay_wrap .pay_guide li a::before{
	content:'';
	display:block;
	width:287px;
	height:1px;
	background:#333333;
	position:absolute;
	bottom:0;
}
.pay_wrap .pay_guide li a{
	color:#000000;
	font-size:12px;
	text-align:left;
	text-decoration:none;
}
.pay_wrap .pay_product_info_top_line{
	width:100%;
	height:2px;
	background:#808080;
	position:relative;
	margin-top:160px;
	z-index:3;
}
:lang(ko) .pay_wrap .pay_product_info_top_line::after{
	content:'야미코딩 상세 설명';
	width:334px;
	height:55px;
	box-sizing:border-box;
	background:#fff4e1;
	color:#666666;
	border-radius:27px;
	border:2px solid #808080;
	font-size:15px;
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}
:lang(en) .pay_wrap .pay_product_info_top_line::after{
	content:'YAM-E coding Detailed Description';
	width:334px;
	height:55px;
	box-sizing:border-box;
	background:#fff4e1;
	color:#666666;
	border-radius:27px;
	border:2px solid #808080;
	font-size:15px;
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}
:lang(jp) .pay_wrap .pay_product_info_top_line::after{
	content:'YAM-E プログラミングの詳細説明';
	width:334px;
	height:55px;
	box-sizing:border-box;
	background:#fff4e1;
	color:#666666;
	border-radius:27px;
	border:2px solid #808080;
	font-size:15px;
	text-align:center;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}
.pay_wrap .pay_product_info_area{
	width:100%;
	height:auto;
	background:#fff9f0 url(../assets/pay_product_bg@2x.png) no-repeat top center;
	background-size:100% auto;
	position:relative;
	box-sizing:border-box;
	padding-top:41%;
	padding-bottom:200px;
}
.pay_wrap .pay_product_info_area .inner{
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.pay_wrap .pay_product_title{
	text-align:center;
	font-size:30px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .pay_wrap .pay_product_title{
	text-align:center;
	font-size:30px;
	font-family: 'NotoSansJP-Regular';
}
.pay_wrap .pay_product_3d_area{
	width:100%;
	height:706px;
	position:relative;
	margin-top:28px;
	background:url(../assets/pay_product_box_bg1.png) no-repeat center;
	background-size:auto 100%;
	padding-top:108px;
	padding-bottom:122px;
	box-sizing:border-box;
}
.pay_wrap .pay_product_top_area{
	width:956px;
	height:auto;
	box-sizing:border-box;
	padding-left:104px;
	padding-right:33px;
	margin:0 auto;
}
.pay_wrap .pay_product_top_area span{
	font-size:25px;
	color:#0457a2;
	font-family: 'S-CoreDream-7ExtraBold';
	text-align:left;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
:lang(jp) .pay_wrap .pay_product_top_area span{
	font-size:25px;
	color:#0457a2;
	font-family: 'NotoSansJP-Medium';
	text-align:left;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.pay_wrap .pay_product_top_area span::before{
	content:'';
	display:block;
	width:12px;
	height:12px;
	background:#0457a2;
	border-radius:50%;
	margin-right:4px;
}
.pay_wrap .pay_product_top_area p{
	color:#1f1715;
	text-align:left;
	padding-left:16px;
	box-sizing:border-box;
	margin-top:9px;
}
.pay_wrap .pay_product_3d_bottom_area{
	width:956px;
	height:auto;
	margin:0 auto;
	margin-top:26px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
}
.pay_wrap .pay_product_3d_bottom_area .img_area{
	width:266px;
	height:auto;
	position:relative;
}
.pay_wrap .pay_product_3d_bottom_area img{
	width:100%;
	height:auto;
}
.pay_wrap .pay_product_3d_bottom_area p{
	width:100%;
	height:auto;
	margin-top:12px;
	font-size:15px;
	color:#1f1715;
	font-family: 'S-CoreDream-3Light';
	text-align:left;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
}
.pay_wrap .pay_product_3d_bottom_area p::before{
	content:'';
	display:block;
	width:12px;
	height:12px;
	background:#999999;
	border-radius:50%;
	margin-right:8px;
	margin-top:7px;
}
.pay_wrap .pay_product_3d_bottom_area .img_area:first-child{
	width:359px;
}
.pay_wrap .pay_product_3d_bottom_area .img_area:first-child p{
	width:359px;
	margin-top:16px;
	padding-left:21px;
	box-sizing:border-box;
}
.pay_wrap .pay_product_3d_bottom_area .img_area:nth-child(2){
	margin-left:37px; 
	margin-right:24px; 
}
.pay_wrap .pay_product_contents_area{
	width:100%;
	height:1116px;
	position:relative;
	top:-40px;
	background:url(../assets/pay_product_box_bg2.png) no-repeat center;
	background-size:auto 100%;
	padding-top:156px;
	padding-bottom:82px;
	box-sizing:border-box;
}
.pay_wrap .pay_product_contents_bottom_area{
	width:956px;
	height:auto;
	margin:0 auto;
	margin-top:19px;
	position:relative;
}
.pay_wrap .pay_product_contents_bottom_area .top_area{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
}
.pay_product_contents_bottom_area .top_area .img_area{
	width:auto;
	height:auto;
	position:relative;
}
.pay_wrap .pay_product_contents_bottom_area p br.mobile{
	display:none;
}
.pay_wrap .pay_product_contents_bottom_area .top_area .img_area div{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
.pay_wrap .pay_product_contents_bottom_area .top_area img{
	width:360px;
	height:auto;
}
.pay_wrap .pay_product_contents_bottom_area .top_area img:nth-child(2){
	width:570px;
	margin-left:20px;
}
.pay_wrap .pay_product_contents_bottom_area p{
	width:100%;
	height:auto;
	margin-top:12px;
	font-size:15px;
	color:#1f1715;
	font-family: 'S-CoreDream-3Light';
	text-align:left;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
	box-sizing:border-box;
	padding-left:21px;
}
.pay_wrap .pay_product_contents_bottom_area p::before{
	content:'';
	display:block;
	width:12px;
	height:12px;
	background:#999999;
	border-radius:50%;
	margin-right:8px;
	margin-top:7px;
}
.pay_wrap .pay_product_contents_bottom_area .bottom_area{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
	margin-top:32px;
}
.pay_product_contents_bottom_area .bottom_area .img_area{
	width:318px;
	height:auto;
	position:relative;
}
.pay_product_contents_bottom_area .bottom_area .img_area img{
	width:318px;
	height:auto;
	position:relative;
}
.pay_product_contents_bottom_area .bottom_area .img_area:last-child{
	width:642px;
	margin-left:6px;
}
.pay_product_contents_bottom_area .bottom_area .img_area:last-child div{
	width:100%;
	height:auto;
	display:-webkit-box;
	-webkit-box-align:start;
	display:flex;
	align-items:flex-start;
}
.pay_product_contents_bottom_area .bottom_area .img_area:last-child img:last-child{
	margin-left:6px;
}
.pay_wrap .pay_product_etc_area{
	width:100%;
	height:742px;
	position:relative;
	top:-80px;
	background:url(../assets/pay_product_box_bg3.png) no-repeat center;
	background-size:auto 100%;
	padding-top:115px;
	padding-bottom:33px;
	box-sizing:border-box;
}
.pay_wrap .pay_product_etc_area .pay_product_top_area{
	width:1052px;
	padding-left:78px;
}
.pay_wrap .pay_product_etc_area .pay_product_top_area span.second{
	margin-top:42px;
}
.pay_product_etc_area img{
	width:1052px;
	height:auto;
	margin:0 auto;
	display:block;
	position:relative;
	margin-top:31px;
}
.pay_wrap .pay_bottom_btn_area{
	width:100%;
	height:77px;
	background:#edd0a6;
	position:relative;
}
.pay_wrap .pay_bottom_btn_area a{
	width:374px;
	height:90px;
	background:#007cff;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:22px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
	position:absolute;
	top:-45px;
	left:58%;
}
:lang(jp) .pay_wrap .pay_bottom_btn_area a{
	width:374px;
	height:90px;
	background:#007cff;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:22px;
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
	position:absolute;
	top:-45px;
	left:58%;
}

/*news.html*/
.news_area{
	width:100%;
	height:auto;
	min-height:calc(100vh - 350px);
	position:relative;
	background:#fff9f0;
	/* background:#ffffff; */
	padding-bottom:95px;
	box-sizing:border-box;
}
.news_top_line_banner{
	width:100%;
	height:85px;
	position:relative;
	background:#1E83D6;
}
.news_top_line_banner .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.news_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .news_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
}
.news_area .news_wrap{
	width:100%; 
	height:auto;
	position:relative;
}
.news_area .news_wrap .inner{
	height:auto;
}
.news_area .news_content_area{
	width:100%;
	height:auto;
	position:relative;
	box-sizing:border-box;
	padding-top:95px;
	display:-webkit-box;
	display:flex;
	flex-wrap:wrap;
}
.news_area .news_box{
	display:block;
	width:370px;
	height:auto;
	overflow:hidden;
	position:relative;
	margin:20px 15px;
	position:relative;
	background:#ffffffb0;
	box-sizing:border-box;
	border:1px solid #efdcc2;
	/* border:1px solid #e6e6e6; */
	border-radius:22px;
	box-shadow:none;
}
/* .news_area .news_box:hover{
	box-shadow:0 0 16px 0px #edd0a6c4;
} */
.news_area .news_box:hover{
	border:1px solid #1E83D6;
}
.news_area .news_box .img_area{
	width:100%;
	height:240px;
	overflow:hidden;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.news_area .news_box .img_area img{
	object-fit:cover;
}
.news_area .news_box .text_area{
	width:100%;
	height:auto;
	position:relative;
	box-sizing:border-box;
	padding:24px 20px;
}
.news_area .news_box .text_area span{
	font-size:20px;
	color:#3b3b3b;
	display:block;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
	margin-bottom:8px;
}
.news_area .news_box .text_area p{
	font-size:13px;
	font-family: 'S-CoreDream-3Light';
	color:#999999;
	display:block;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	word-break:normal;
	white-space:normal;
	display:-webkit-box;
	-webkit-line-clamp:3;
	-webkit-box-orient:vertical;
}
.news_area .news_box:hover span{
	color:#1E83D6;
}
.news_area .news_box:hover p{
	color:#1E83D6;
}

/*news_content.html*/
.news_more_content_area{
	width:100%;
	height:auto;
	min-height:calc(100vh - 350px);
	position:relative;
	background:#ffffff;
	padding-bottom:95px;
	box-sizing:border-box;
}
.news_more_content_area .news_more_content_wrap{
	width:100%; 
	height:auto;
	position:relative;
}
.news_more_content_area .news_more_content_wrap .inner{
	height:auto;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.news_more_content_area .news_more_content_article_area{
	width:100%;
	height:auto;
	position:relative;
	box-sizing:border-box;
	padding-top:95px;
}
.news_more_content_article_area .title{
	font-size:24px;
	font-family:'S-CoreDream-6Bold';
}
:lang(jp) .news_more_content_article_area .title{
	font-size:24px;
	font-family: 'NotoSansJP-Regular';
}
.news_more_content_article_area .article_info{
	display:-webkit-box;
	display:flex;
	margin-top:10px;
}
.news_more_content_article_area .article_info p{
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	color:#b3b3b3;
	padding-right:15px;
	border-right:1px solid #b3b3b3;
	line-height:1;
}
.news_more_content_article_area .article_info span{
	font-size:13px;
	font-family:'S-CoreDream-3Light';
	color:#009cc6;
	margin-left:15px;
	line-height:1;
}
.news_more_content_article_area .article_content{
	width:100%;
	height:auto;
	position:relative;
	margin-top:20px;
	box-sizing:border-box;
	padding:50px 0;
	border-top:1px solid #e6e6e6;
	border-bottom:1px solid #e6e6e6;
}
.news_more_content_article_area .article_content img{
	margin-top:10px;
	margin-bottom:30px;
	height:auto;
	width:auto;
	max-width:1000px;
}
.news_more_content_article_area .article_content p{
	font-size:15px;
	white-space: pre-wrap;
}
.news_more_content_area .bact_to_list{
	width:150px; 
	height:50px;
	background:#009cc6;
	border-radius:25px;
	margin-top:36px;
	color:#ffffff;
	font-size:16px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}



/* qna.html*/
/* 비회원 답변확인 팝업 */
.qna_no_member_confirm_popup{
	display:none;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.qna_no_member_confirm_popup div{
	width:300px;
	height:460px;
	background:#ffffff;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
	box-sizing:border-box;
	padding:30px;
	border-radius:30px;
}
.qna_no_member_confirm_popup span{
	font-size:15px;
}
.qna_no_member_confirm_popup span.qna_no_member_confirm_popup_tit{
	font-size:18px;
	display:block;
	text-align:center;
	margin-bottom:20px;
}
#qna_no_member_confirm_popup_name_input,
#qna_no_member_confirm_popup_email_input,
#qna_no_member_confirm_popup_password_input{
	width:100%;
	height:50px;
	border:1px solid #e6e6e6;
	border-radius:11px;
	font-size:15px;
	box-sizing:border-box;
	padding:10px;
	margin-bottom:12px;
	margin-top:4px;
}

.qna_no_member_confirm_popup div.btn_area{
	width:100%;
	height:36px;
	position:relative;
	top:0;
	left:0;
	padding:0;
	background:none;
	-webkit-transform:translate(0,0);
	transform:translate(0,0);
	display:-webkit-box;
	-webkit-box-align:Center;
	-webkit-box-pack:Center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:30px;
}
.qna_no_member_confirm_popup .confirm{
	width:40%;
	height:100%;
	position:relative;
	border:none;
	background:#009cc6;
	color:#ffffff;
	border-radius:30px;
	cursor:pointer;
}
.qna_no_member_confirm_popup .cancle{
	width:40%;
	height:100%;
	position:relative;
	border:1px solid #009cc6;
	background:#ffffff;
	color:#009cc6;
	border-radius:30px;
	margin-right:20px;
	cursor:pointer;
}

/* 개인정보처리방침 팝업 */
.qna_privacy_popup{
	display:none;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:140;
}
.qna_privacy_popup .popup_area{
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
}
.qna_privacy_popup .close_area{
	width:600px;
	height:20px;
	position:relative;
	display:-webkit-box;
	-webkit-box-pack:end;
	display:flex;
	justify-content:flex-end;
}
.qna_privacy_popup .close{
	height:100%;
	background:none;
	border:none;
	cursor:pointer;
}
.qna_privacy_popup .close img{
	height:100%;
}
.qna_privacy_popup .qna_privacy_popup_wrapper{
	width:600px;
	height:500px;
	background:#ffffff;
	position:relative;
	box-sizing:border-box;
	padding:30px;
	border-radius:17px;
	overflow-y:scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	margin-top:10px;
}
.qna_privacy_popup .qna_privacy_popup_wrapper::-webkit-scrollbar {
	display: none;
}
.qna_privacy_popup .popup_content_wrap{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	flex-direction:column;
}
.qna_privacy_popup_tit{
	font-size:28px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .qna_privacy_popup_tit{
	font-size:28px;
	font-family: 'NotoSansJP-Regular';
}
.qna_privacy_popup_txt{
	width:100%;
	margin:30px 0;
}
.qna_privacy_popup_content_wrap{
	width:100%;
	height:auto;
	position:relative;
}
.qna_privacy_popup_content_wrap .content_tit{
	font-size:22px;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .qna_privacy_popup_content_wrap .content_tit{
	font-size:22px;
	font-family: 'NotoSansJP-Regular';
}
.qna_privacy_popup_content_wrap p{
	font-size:16px;
	width:100%;
	margin:20px 0;
	line-height:1.5;
	box-sizing:border-box;
	padding-left:20px;
}

.qna_privacy_popup .confirm{
	width:140px;
	height:50px;
	position:relative;
	border:none;
	background:#009cc6;
	color:#ffffff;
	border-radius:30px;
	cursor:pointer;
	margin-top:32px;
}

/* 문의하기 */
.qna_top_line_banner{
	width:100%;
	height:85px;
	position:relative;
	background:#06667c;
}
.qna_top_line_banner .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.qna_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .qna_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
}

.qna_tab{
	width:100%;
	height:auto;
	position:relative;
}
.qna_tab .inner{
	width:880px;
	height:auto;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:end;
	display:flex;
	align-items:flex-end;
	flex-direction:column;
}
.qna_tab .confirm_no_member_qna{
	/* width:154px; */
	height:36px;
	border-radius:10px;
	box-sizing:border-box;
	/* border:1px solid #e6e6e6; */
	border:2px #999999 solid;
	background:#ffffff;
	cursor:pointer;
	font-size:13px;
	color:#666666;
	margin-top:32px;
	padding-left: 10px;
	padding-right: 10px;
}
.qna_tab .qna_area{
	width:100%;
	height:auto;
	position:relative;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.qna_area .qna_tit{
	font-size:32px;
	font-family: 'S-CoreDream-6Bold';
	text-align:center;
	margin-top:32px;
}
:lang(jp) .qna_area .qna_tit{
	font-size:32px;
	font-family: 'NotoSansJP-Regular';
	text-align:center;
	margin-top:32px;
}
.qna_area .qna_form{
	width:100%;
	height:auto;
	margin-top:36px;
	border-top:2px solid #333333;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	padding:20px 0;
}
.qna_form li{
	width:100%;
	height:60px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
.qna_form.login li.for_no_member{
	display:none;
}
.qna_form li.password{
	height:auto;
	min-height:60px;
	box-sizing:border-box;
	padding:10px 0;
}
.qna_form li.password div{
	height:auto;
}
.qna_form li.content{
	height:170px;
}
.qna_form li span{
	width:160px;
	box-sizing:border-box;
	font-size:15px;
	color:#666666;
	padding-left:32px;
}
.qna_form li input{
	height:42px;
	border-radius:4px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:0 16px;
}
#qna_form_name_input{
	width:140px;
}
#qna_form_email_input,
#qna_form_password_input{
	width:420px;
}
.qna_form li.password .info{
	font-size:12px;
	color:#b3b3b3;
	margin-top:4px;
}
#qna_form_categori_select{
	width:152px;
	height:42px;
	border-radius:4px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:0 16px;
	font-size:14px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(../assets/select_arrow.png) no-repeat 90% center;
	cursor:pointer;
}
#qna_form_categori_select option{
	font-size:14px;
	color:#666666;
}
#qna_form_title_input{
	width:710px;
}
#qna_form_content_textarea{
	width:710px;
	height:150px;
	resize:none;
	border-radius:4px;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	padding:4px 16px;
}
.qna_area .privacy_agree_area{
	width:100%;
	height:auto;
	margin-top:10px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
}
#qna_agree_checkbox_input{
	width:16px;
	height:16px;
	box-sizing:border-box;
	border:1px solid #b3b3b3;
	margin-right:4px;
}
.privacy_agree_area span{
	font-size:15px;
	color:#999999;
}
.privacy_agree_area span .popup{
	color:#009cc6;
	cursor:pointer;
}
.qna_area .qna_submit{
	width:150px; 
	height:50px;
	background:#009cc6;
	border-radius:25px;
	margin:0 auto;
	margin-top:36px;
	color:#ffffff;
	font-size:16px;
	cursor:pointer;
	border:none;
}
.qna_tab .line{
	width:1078px;
	height:1px;
	background:#e6e6e6;
	margin:0 auto;
	margin-top:60px;
}

/* 자주 묻는 질문 */
.faq_top_line_banner{
	width:100%;
	height:85px;
	position:relative;
	background:#06667c;
}
.faq_top_line_banner .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.faq_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'S-CoreDream-6Bold';
}
:lang(jp) .faq_top_line_banner span{
	font-size:32px;
	color:#ffffff;
	font-family: 'NotoSansJP-Regular';
}

/*faq_tab*/
.faq_tab{
	width:100%;
	height:auto;
	background:#ffffff;
}
.faq_tab .inner{
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-orient:vertical;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.faq_tab .faq_tit{
	font-family: 'S-CoreDream-6Bold';
	font-size:32px;
	margin-top:102px;
}
:lang(jp) .faq_tab .faq_tit{
	font-family: 'NotoSansJP-Regular';
	font-size:32px;
	margin-top:102px;
}
.faq_tab .faq_txt{
	font-family: 'S-CoreDream-3Light';
	font-size:16px;
	margin-top:16px;
}
:lang(jp) .faq_tab .faq_txt{
	font-family: 'NotoSansJP-Thin';
	font-size:16px;
	margin-top:16px;
}
.faq_tab .ellip{
	width:90%;
	display:block;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	word-break:normal;
}
.faq_tab .faq_srch_area{
	width:100%;
	height:42px;
	position:relative;
	margin-top:58px;
	display:-webkit-box;
	-webkit-box-pack:center;
	display:flex;
	justify-content:center;
}
#faq_srch_select{
	width:90px;
	height:100%;
	padding:0 8px;
	border:1px solid #e6e6e6;
	border-radius:10px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(../assets/select_arrow.png) no-repeat 90% center;
	cursor:pointer;
	font-size:16px;
	margin-right:10px;
}
#faq_srch_select option{
	font-size:14px;
}
.faq_srch_area #faq_input_id{
	width:376px;
	height:100%;
	box-sizing:border-box;
	border:1px solid #e6e6e6;
	border-radius:30px;
	overflow:hidden;
	padding:10px 15px;
	margin-right:12px;
	font-size:16px;
}
.faq_srch_area button{
	width:96px;
	height:100%;
	border-radius:30px;
	background:#009cc6;
	color:#ffffff;
	font-size:14px;
	cursor:pointer;
	border:none;
}
.faq_tab .faq_area{
	width:1078px;
	height:auto;
	min-height:432px;
	margin:58px auto;
	margin-bottom:160px;
}
.faq_tab .faq_categori_area{
	width:100%;
	height:32px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:end;
	display:flex;
	justify-content:flex-end;
	align-items:center;
	position:relative;
	margin-bottom:16px;
}
.faq_categori_area #faq_categori_select{
	width:120px;
	height:100%;
	padding:0 8px;
	border:1px solid #e6e6e6;
	border-radius:10px;
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none; 
	background:#ffffff url(../assets/select_arrow.png) no-repeat 90% center;
	cursor:pointer;
	font-size:16px;
}
#faq_categori_select option{
	font-size:14px;
}
.faq_tab .faq_list_wrap{
	width:100%;
	height:auto;
	border-top:2px solid #009cc6;
	position:relative;
}
.faq_list_wrap .no_result{
	width:100%;
	height:100px;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.faq_list_wrap .list{
	width:100%;
	height:auto;
	border-bottom:1px solid #e6e6e6;
	position:relative;
}
.faq_list_wrap .question_area{
	width:100%;
	height:auto;
	min-height:70px;
	box-sizing:border-box;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	padding:20px 56px;
	position:relative;
	cursor:pointer;
	border-bottom:1px solid #e6e6e6;
}
.faq_list_wrap .question_area span{
	font-size:26px;
	color:#009cc6;
	font-family: 'S-CoreDream-6Bold';
	margin-right:56px;
}
:lang(jp) .faq_list_wrap .question_area span{
	font-size:26px;
	color:#009cc6;
	font-family: 'NotoSansJP-Regular';
	margin-right:56px;
}
.faq_list_wrap .question_area p{
	font-size:15px;
	color:#575757;
	font-family: 'S-CoreDream-6Bold';
	width:84%;
	margin-right:60px;
}
:lang(jp) .faq_list_wrap .question_area p{
	font-size:15px;
	color:#575757;
	font-family: 'NotoSansJP-Regular';
	width:84%;
	margin-right:60px;
}
.faq_list_wrap .question_area img{
	height:12px;
	-webkit-transform:rotate(0deg);
	transform:rotate(0deg);
}
.faq_list_wrap .answer_area{
	width:100%;
	height:auto;
	box-sizing:border-box;
	padding:30px 56px;
	background:#f9f9f9;
	display:none;
}
.faq_list_wrap .answer_area p{
	font-size:15px;
	color:#575757;
}
:lang(jp) .faq_list_wrap .answer_area p{
	font-size:15px;
	color:#575757;
	font-family: 'NotoSansJP-Regular';
}
.faq_list_wrap .list.on .answer_area{
	display:block;
}
.faq_list_wrap .list.on .question_area img{
	-webkit-transform:rotate(180deg);
	transform:rotate(180deg);
}

/* 404 not found */
.not_found_area{
	background:#ffffff;
	width:100%;
	height:100%;
	overflow:hidden;
	position:fixed;
	top:0;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	-webkit-box-orient:vertical;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
}
.not_found_area img{
	width:400px;
}
.not_found_area p{
	color:#666666;
	font-size:36px;
	font-family: 'S-CoreDream-6Bold';
	line-height:1;
	margin-top:-20px;
}
:lang(jp) .not_found_area p{
	color:#666666;
	font-size:36px;
	font-family: 'NotoSansJP-Regular';
	line-height:1;
	margin-top:-20px;
}
.not_found_area a{
	color:#f15a24;
	font-size:14px;
	font-family: 'S-CoreDream-3Light';
	width:220px;
	height:36px;
	border-radius:30px;
	border:1px solid #f15a24;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:50px;
}

.not_found_area2{
	background:#ffffff;
	width:100%;
	height:100%;
	overflow:hidden;
	position:fixed;
	top:0;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.not_found_area2 .left_area{
	position:relative;
}
.not_found_area2 span{
	color:#f15a24;
	font-size:150px;
	font-family: 'S-CoreDream-8Heavy';
	line-height:1;
}
:lang(jp) .not_found_area2 span{
	color:#f15a24;
	font-size:150px;
	font-family: 'NotoSansJP-Bold';
	line-height:1;
}
.not_found_area2 p{
	color:#666666;
	font-size:36px;
	font-family: 'S-CoreDream-6Bold';
	line-height:1;
	margin-top:20px;
}
:lang(jp) .not_found_area2 p{
	color:#666666;
	font-size:36px;
	font-family: 'NotoSansJP-Regular';
	line-height:1;
	margin-top:20px;
}
.not_found_area2 a{
	color:#f15a24;
	font-size:14px;
	font-family: 'S-CoreDream-3Light';
	width:220px;
	height:36px;
	border-radius:30px;
	border:1px solid #f15a24;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:36px;
}
:lang(jp) .not_found_area2 a{
	color:#f15a24;
	font-size:14px;
	font-family: 'NotoSansJP-Thin';
	width:220px;
	height:36px;
	border-radius:30px;
	border:1px solid #f15a24;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:36px;
}
.not_found_area2 img{
	height:280px;
	margin-left:24px;
}

/* order.html */
.pay_area.order_area{
	background:#ffffff;
}
.order_wrap{
	width:100%;
	height:auto;
	background:#ffffff;
	position:relative;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-box-align:center;
	display:flex;
	flex-direction:column;
	align-items:center;
}
.order_wrap .title{
	margin-top:124px;
	font-size:28px;
	font-family:'S-CoreDream-6Bold';
}
:lang(jp) .order_wrap .title{
	margin-top:124px;
	font-size:28px;
	font-family: 'NotoSansJP-Regular';
}
.order_wrap ul{
	width:100%;
	max-width:880px;
	height:auto;
	border-top:2px solid #333333;
	position:relative;
	margin-top:62px;
}
.order_wrap li{
	width:100%;
	height:70px;
	border-bottom:1px solid #e6e6e6;
	box-sizing:border-box;
	display:-webkit-box;
	display:flex;
}
.order_wrap li.order_total_price_wrap{
	height:85px;
}
.order_wrap li.how_to_pay_wrap{
	height:106px;
}
.order_wrap li .left_area{
	width:20%;
	min-width:224px;
	background:#f8f8f8;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	box-sizing:border-box;
	padding-left:64px;
}
.order_wrap li .left_area span{
	font-size:15px;
	color:#666666;
	text-align:left;
}
.order_wrap li .right_area{
	width:80%;
	height:100%;
	position:relative;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	box-sizing:border-box;
	padding-left:64px;
}
.order_wrap li .right_area span{
	font-size:15px;
	color:#808080;
	text-align:left;
}
.order_wrap li .right_area span.order_price{
	color:#4d4d4d;
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
}
:lang(ko) .order_wrap li .right_area span.order_price::after{
	content:'원';
	display:block;
	color:#4d4d4d;
	font-size:15px;
	text-align:left;
	margin-left:2px;
}
:lang(en) .order_wrap li .right_area span.order_price::after{
	content:'won';
	display:block;
	color:#4d4d4d;
	font-size:15px;
	text-align:left;
	margin-left:2px;
}
:lang(jp) .order_wrap li .right_area span.order_price::after{
	content:'won';
	display:block;
	color:#4d4d4d;
	font-size:15px;
	text-align:left;
	margin-left:2px;
}
.order_wrap li .right_area span.order_total_price{
	color:#f15a24;
	font-size:22px;
	font-family:'S-CoreDream-6Bold';
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	line-height:1;
}
:lang(jp) .order_wrap li .right_area span.order_total_price{
	color:#f15a24;
	font-size:22px;
	font-family: 'NotoSansJP-Regular';
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	line-height:1;
}
:lang(ko) .order_wrap li .right_area span.order_total_price::after{
	content:'원';
	display:block;
	color:#f15a24;
	font-size:15px;
	line-height:1;
	margin-left:3px;
}
:lang(en) .order_wrap li .right_area span.order_total_price::after{
	content:'won';
	display:block;
	color:#f15a24;
	font-size:15px;
	line-height:1;
	margin-left:3px;
}
:lang(jp) .order_wrap li .right_area span.order_total_price::after{
	content:'won';
	display:block;
	color:#f15a24;
	font-size:15px;
	line-height:1;
	margin-left:3px;
}
.how_to_pay_wrap .radio_btn_area{
	display:-webkit-box;
	-webkit-box-align:center;
	display:flex;
	align-items:center;
	width:auto;
	margin-right:30px;
	font-size:15px;
	color:#b3b3b3;
}
.how_to_pay_wrap .radio_btn_area input{
	width:15px;
	height:15px;
	margin-right:4px;
	-webkit-appearance:none;
	appearance:none;
	border:1px solid #b3b3b3;
	padding:0;
	box-sizing:border-box;
	border-radius:50%;
}
.how_to_pay_wrap .radio_btn_area input:checked{
	-webkit-appearance:none;
	appearance:none;
	background:#ff6e00;
	border:none;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
}
.how_to_pay_wrap .radio_btn_area input:checked::after{
	content:'';
	display:block;
	width:8px;
	height:8px;
	border-radius:50%;
	background:#ffffff;
}
.order_wrap .customer_info_title{
	width:100%;
	max-width:880px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:start;
	display:flex;
	align-items:center;
	justify-content:flex-start;
	margin-top:50px;
}
.order_wrap .customer_info_title p{
	font-size:16px;
	font-family: 'S-CoreDream-6Bold';
	text-align:left;
}
:lang(jp) .order_wrap .customer_info_title p{
	font-size:16px;
	font-family: 'NotoSansJP-Regular';
	text-align:left;
}
.order_wrap .customer_info_title div{
	width:8px;
	height:8px;
	border-radius:50%;
	background:#333333;
	margin-right:4px;
}
.order_wrap ul.customer_info_wrap{
	margin-top:10px;
}
.order_wrap ul.customer_info_wrap li.vertical{
	height:auto;
}
.order_wrap ul.customer_info_wrap li.vertical .left_area{
	height:inherit;
}
.order_wrap ul.customer_info_wrap li.vertical .right_area{
	-webkit-box-orient:vertical;
	-webkit-box-align:start;
	-webkit-box-pack:center;
	flex-direction:column;
	align-items:flex-start;
	justify-content:center;
	height:auto;
	padding:15px 0;
	padding-left:64px;
}
.order_wrap ul.customer_info_wrap .right_area p{
	font-size:13px;
	color:#ff6e00;
	text-align:left;
	margin-top:5px;
}
.customer_info_wrap .right_area input{
	font-size:15px;
	color:#808080;
	text-align:left;
	border-radius:30px;
	width:160px;
	height:36px;
	box-sizing:border-box;
	padding:0 20px;
	border:1px solid #e6e6e6;
}
.customer_info_wrap .right_area input#order_customer_eamil{
	width:220px;
}

.order_wrap .btn_area{
	margin-top:62px;
	margin-bottom:100px;
	width:100%;
	height:50px;
	display:-webkit-box;
	-webkit-box-align:center;
	-webkit-box-pack:center;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
}
.order_wrap .btn_area button{
	width:150px;
	height:100%;
	border-radius:25px;
	background:#cccccc;
	font-size:16px;
	color:#ffffff;
	border:none;
	cursor:pointer;
}
.order_wrap .btn_area button:last-child{
	background:#009cc6;
	margin-left:20px;
}

/* 블록코딩 블록들 */
.blockCoding_container {
    width: 100%;
    /* border: 1px solid #f44601; */
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .blockCoding_inner {
    width: 350px;
    height: 100%;
    /* border: 1px solid #9f05f9; */
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .blockCoding_bridge {
    width: 180px;
    /* border: 1px solid #f9e905; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .blockCoding_chapter {
    width: 350px;
    height: 40px;
    /* background-color: #0af885; */
    display : flex;
	align-items : center;
    justify-content : center;
    font-size: 20px;
    font-weight: 900;
  }
  .blockCoding_block {
    width: 350px;
    height: 60px;
    /* background-color: #0af885; */
    display : flex;
	align-items : center;
    justify-content : flex-start;
  }
  .blockCoding_repeat_top {
    width: 370px;
    height: 60px;
    /* background-color: #f80ae4; */
    display : flex;
	align-items : center;
    justify-content : center;
    background:url(../assets/CodeBlock_0014-1.png);
    background-size: 100% 100%;
    background-position: center;
    color: white;
  }
  .blockCoding_repeat_middle {
    width: 370px;
    /* background-color: #4914e9; */
    display: flex;
    justify-content: flex-start;
  }
  .blockCoding_repeat_inner_left {
    width: 40px;
    /* border: 1px solid #f1220b; */
    display: flex;
    flex-wrap: wrap;
    background:url(../assets/CodeBlock_0014-22.png);
    background-size: 100% 100%;
    background-position: center;
  }
  .blockCoding_repeat_inner_right {
    width: 330px;
    /* border: 1px solid #9f05f9; */
    display: flex;
    flex-wrap: wrap;
	margin-left: 5px;
  }
  .blockCoding_repeat_bottom {
    width: 370px;
    height: 20px;
    /* background-color: #f80a5d; */
    background:url(../assets/CodeBlock_0014-3.png);
    background-size: 100% 100%;
    background-position: cover;
  }
  .blockCoding_repeat {
    width: 370px;
    /* background-color: #ce1231; */
  }
  .blockCoding_space {
    width: 80px;
    /* background-color: #ce1231; */
  }
  .blockCoding_space2 {
    width: 100px;
    /* background-color: #ce1231; */
  }
  .CodeBlock_0001 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0001.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0002 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0002.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0003 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0003.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0004 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0004.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0005 {
    width: 350px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0005.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0006 {
    width: 350px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0006.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0007 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0007.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0008 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0008.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0009 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0009.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0010 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0010.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0011 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0011.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0012 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0012.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0013 {
    width: 320px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0013.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0015 {
    width: 350px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0015.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_0016 {
    width: 350px;
    height: 50px;
    /* border: solid 1px #079ef5; */
    background:url(../assets/CodeBlock_0016.png);
    background-size: 100% 100%;
    background-position: center;
    display : flex;
	align-items : center;
    justify-content : left;
    color: white;
	font-size: 16px;
  }
  .CodeBlock_input_style {
    border:0;
	padding-top:2; 
	text-align:center;
  }

/*반응형*/
@media screen and (min-width:1200px) and (hover:hover) {
	.introduce_area .ym_who_btn:hover{
		background:#ffffff;
	}
	.introduce_area .ym_who_btn:hover span{
		color:#f29500;
	}
	.introduce_area .ym_who_btn:hover .hover_round{
		-webkit-transform:scale(50);
		transform:scale(50);
	}
}
@media screen and (min-width:2560px){
	/* index.html */
	.index_tit{
		font-size:2em;
	}
	.sub_title{
		font-size:1.4em;
	}
	p.txt{
		font-size:1em;
	}
	.stakato:first-child{
		margin-left:.5vw;
	}
	/* main_banner_area */
	.bn_content_wrap .bn_tit .stakato::before{
		max-width:20px;
		max-height:20px;
	}
	.bn_content_wrap .bn_tit{
		font-size:3.4em;
	}
	.bn_content_wrap .bn_txt{
		font-size:1.6em;
	}
	/* introduce_area */
	.introduce_area .right_area p{
		font-size:1em;
	}
	.introduce_area .ym_who_btn span{
		font-size:1.1em;
	}
	/* ym_world_area */
	.ym_world_area .building span{
		font-size:1.2em;
	}
	.ym_world_area .building p{
		font-size:1em;
	}
	/* ym_difference_area */
	.ym_difference_area .info_txt_wrap p{
		font-size:1em;
	}
	/* ym_myroom_area */
	.ym_myroom_area .content span{
		font-size:1.2em;
	}
	.ym_myroom_area .content p{
		font-size:1em;
	}
	.ym_myroom_area .box .cover{
		font-size:1em;
	}
	.ym_myroom_area .content .graph_area3 .counting{
		font-size:2em;
	}
	/* brain_music_area */
	.brain_music_area .wrap li .right_area span{
		font-size:1.2em;
	}
	.brain_music_area .wrap li .right_area p{
		font-size:1em;
	}
	/* coding_block_area */
	.coding_block_area .cont .title{
		font-size:1em;
	}
	.coding_block_area .cont .sub_tit{
		font-size:.7em;
	}
	.coding_block_area .cont p{
		font-size:.8em;
	}
	/* yf_area */
	.yf_area .yf_info_area .txt_box span{
		font-size:1.4em;
	}
	.yf_area .yf_info_area .txt_box p{
		font-size:1em;
	}
	
}
@media screen and (min-width:1200px) and (max-width:1359px) {
	
	/* main */
	.bn_content_wrap{
		padding-top:12.2vh;
		padding-bottom:8vh;
	}
	.bn_content_wrap .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.bn_content_wrap img{
		height:42vmin;
	}
	/* introduce_area */
	.introduce_area{
		padding-bottom:8vh;
	}
	.introduce_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	/* .ym_world_area */
	.ym_world_area{
		padding-bottom:8vh;
		padding-top:80px;
	}
	.ym_world_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	/* .ym_difference_area */
	.ym_difference_area{
		padding-bottom:9vh;
		padding-top:80px;
	}
	.ym_difference_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	/* .ym_myroom_area */
	.ym_myroom_area{
		padding-bottom:10vh;
		padding-top:80px;
	}
	.ym_myroom_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	/* brain_music_area */
	.brain_music_area{
		padding-bottom:10vh;
		padding-top:80px;
	}
	.brain_music_area .wrap{
		margin-left:1.5vw;
	}
	/* coding_block_area */
	.coding_block_area{
		padding-bottom:10vh;
		padding-top:80px;
	}
	.coding_block_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.coding_block_area .bottom_area{
		margin-top:7vh;
	}
	.coding_block_area .cont:last-child br{
		display:none;
	}
	/* .yf_area */
	.yf_area{
		padding-bottom:10vh;
		padding-top:80px;
	}
	.yf_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	/* floating_paging_btn_wrap */
	.floating_paging_btn_wrap{
		display:none;
	}

	.bn.open .img_area .bottom{
		width:100%;
		height:auto;
		display:-webkit-box;
		-webkit-box-pack:center;
		display:flex;
		justify-content:center;
	}
	.bn.open .img_area .bottom{
		position:relative;
		top:20px;
	}
	.bn.open .img_area .bottom img{
		width:100%;
		height:auto;
		margin-top:6%;
		-webkit-animation-delay:.8s;
		animation-delay:.8s;
	}
	
}
/*타블렛*/
@media screen and (min-width:768px) and (max-width:1199px) {
	
	.inner{
		width:90%;
	}
	
	/*header*/
	.header .inner{
		width:90%;
	}
	.header .logo{
		margin:0 auto;
		margin-top:12px;
		height:44px;
	}
	.menu_wrapper{
		display:none;
	}
	/*login&mypage*/
	.header .button{
		display:none; 
	}
	/*mobile_menu_btn_area*/
	.mobile_menu_btn_area{
		display:block; 
	}
	.mobile_menu_area{
		width:50%;
	}
	
	/*footer*/
	section.footer_section{
		height:auto;
		background:#1a1a1a;
		padding-top:0;
		padding-bottom:0;
		top:0;
		position:relative;
	}
	.footer{
		height:auto;
	}
	.footer .inner90{
		width:90%;
		-webkit-box-direction:reverse;
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		flex-direction:column-reverse;
		justify-content:center;
	}
	.f_left{
		width:100%;
		height:auto;
		margin:20px 0;
		margin-top:40px;
	}
	.f_left ul{
		-webkit-box-align:center;
		align-items:center;
		padding-left:0;
	}
	.f_left li{
		text-align:center;
	}
	.f_right{
		width:100%;
		height:auto;
	}
	.f_right ul{
		-webkit-box-align:center;
		align-items:center;
		margin-top:40px;
	}
	
	/*로그인 login.html*/
	.login_area{
		width:98%; 
		max-width:420px;
	}
	.login_area a.google{
		width:100%; 
		max-width:348px;
	}
	
	/*password_reset_area*/
	.password_reset_area{
		width:98%; 
		max-width:500px;
	}

	/*password_reset_area*/
	.email_reset_area{
		width:98%; 
		max-width:500px;
	}

	/*join_area*/
	.join_area{
		width:98%; 
		max-width:420px;
	}
	
	/*join_group_area*/
	.join_group_area{
		width:98%; 
		max-width:420px;
	}
	.join_group_area .join_group_wrap .select_area span{
		font-size:15px;
	}
	
	/*join_agree*/
	.join_agree_area{
		width:98%;
	}
	.join_agree_area a.agree_logo img{
		margin-top:20px;
	}
	
	/*stuedent_make_area*/
	.stuedent_make_area{
		width:98%; max-width:420px;
	}
	
	.agree_privacy_wrap .wrap .privacy_area.area1 .box_wrap img{
		width:80%;
	}
	
	/*index.html*/
	.stakato::before{
		width:.5vh;
		height:.5vh;
		top:-.7vh;
	}
	p.txt{
		font-size:.8em;
	}
	section{
		height:100vh;
		position:fixed;
		top:100vh;
	}
	/*main_banner_area */
	.bn.open .top_area p.input_info br{
		display:none;
	}
	.bn.open .img_area{
		margin-top:6px;
	}
	.bn.open .img_area .base img{
		width:90.5%;
	}
	.bn.open .img_area .top img{
		width:50%;
		margin-top:10%;
	}
	.bn.open .img_area .bottom .bounceIn .on img{
		width:30.5%;
		height:auto;
		margin-top:6%;
		-webkit-animation-delay:.8s;
		animation-delay:.8s;
	}
	/* 오픈세일배너 */
	.bn.sale{
		background:url(../assets/sale_bn_bg_mobile.png) no-repeat center;
		background-size:cover;
	}
	.bn.sale .txt1{
		width:90%;
		height:auto;
		margin-top:0;
	}
	.bn.sale .txt2{
		width:52%;
		height:auto;
		margin-top:42px;
	}
	.bn.sale .coin1.bounceIn{
		height:10%;
		top:29%;
		left:-20%;
	}
	.bn.sale .coin2.bounceIn{
		height:3%;
		top:87%;
		left:-31%;
	}
	.bn.sale .coin3.bounceIn{
		height:8%;
		top:62%;
		left:-28%;
	}
	.bn.sale .coin4.bounceIn{
		height:5%;
		top:81%;
		left:-22%;
	}
	.bn.sale .coin5.bounceIn{
		height:7%;
		top:34%;
		left:42%;
	}
	.bn.sale .coin6.bounceIn{
		height:4%;
		top:69%;
		left:18%;
	}
	.bn.sale .coin7.bounceIn{
		height:4%;
		top:53%;
		left:27%;
	} 
	.bn.sale .coin8.bounceIn{
		height:9%;
		top:83%;
		left:20%;
	}
	.bn.sale .coin9.bounceIn{
		display:none;
	}
	.bn.sale .coin10.bounceIn{
		display:none;
	}
	/* 인기브랜드대상배너 */
	.bn.popular .top .star2{
		height:11%;
		top:-21%;
		left:-21%;
	}
	.bn.popular .top .star3{
		height:20%;
		top:-15%;
		left:38%;
	}
	.bn.popular .top .star4{
		height:12%;
		top:72%;
		left:36%;
	}
	.bn.popular .top .star5{
		display:none;
	}
	.bn.popular .top .star6{
		left:54%;
	}
	.bn.popular .bottom .star2{
		left:-46%;
	}
	.bn.popular .bottom .star3{
		height:24%;
		top:-72%;
		left:-33%;
	}
	.bn.popular .bottom .star4{
		height:14%;
		top:-25%;
		left:-32%;
	}
	.bn.popular .bottom .star5{
		height:9%;
		top:-40%;
		left:18%;
	}
	/* 수학섬 업데이트 배너 */
	.bn.mathupdate{
		background:url(../assets/math_bg.png) no-repeat center;
		background-size:cover;
	}
	.bn.mathupdate .txt1{
		position: absolute;
		z-index: 2;
		/* height: auto;
		margin-top: 6%;
		margin-left: -2%; */
		height: 80%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}
	.bn.mathupdate .txt2{
		position: relative;
		z-index: 1;
		height:60%;
		margin-top:20%;
		-webkit-animation-delay:.3s;
		animation-delay:.3s;
	}
	.bn.mathupdate .button{
		position: absolute;
		z-index: 2;
		width: auto;
		margin-top:30%;
		margin-left:-4%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}

	/*introduce_area*/
	.introduce_area{
		padding-top:calc(80px + 3vh);
		padding-bottom:3vh;
	}
	.introduce_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.introduce_area .top_area{
		height:auto;
		-webkit-box-direction:reverse;
		-webkit-box-orient:vertical;
		flex-direction:column-reverse;
	}
	.introduce_area .top_area img{
		height:30vh;
		margin-right:0;
	}
	.introduce_area .top_area .right_area{
		display:-webkit-box;
		-webkit-box-align:Center;
		-webkit-box-orient:vertical;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-bottom:3vh;
	}
	.introduce_area .right_area p{
		text-align:center;
		font-size:.75em;
	}
	.introduce_area span.index_tit{
		text-align:center;
	}
	.introduce_area .ym_who_popup.on{
		height:104%;
		padding:6vmin;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.introduce_area .ym_who_popup.on .ym_wyo_box{
		width:100%;
		margin-top:3vh;
	}
	.ym_who_popup.on .ym_wyo_box:first-child{
		width:100%;
		margin:0;
	}
	.ym_who_popup.on .ym_wyo_box:nth-child(2){
		width:100%;
		margin:0;
		margin-top:3vh;
	}
	.ym_who_popup.on .ym_wyo_box p{
		margin-top:1vh;
		font-size:.8em;
	}
	.introduce_area .ym_who_btn{
		width:auto;
		box-sizing:border-box;
		padding:0 15vmin;
		margin-top:4vh;
		height:34px;
	}
	.introduce_area .ym_who_btn .hover_round{
		width:20px;
		height:20px;
	}

	/*ym_world_area*/
	.ym_world_area{
		padding-top:calc(80px + 3vh);
		padding-bottom:4vh;
	}
	.ym_world_area .bottom_area{
		height:auto;
		margin-top:3vh;
		-webkit-box-orient:vertical;
		-webkit-box-direction:reverse;
		flex-direction:column-reverse;
	}
	.ym_world_area .left_area{
		height:30vh;
		margin-left:0;
	}
	.ym_world_area .left_area .loby{
		height:132%;
		margin-top:0;
	}
	.ym_world_area .plus_wrap .plus{
		width:2.5vh;
		height:2.5vh;
	}
	.ym_world_area .plus:nth-child(1){
		top:15%;
		left:28%;
	}
	.ym_world_area .plus:nth-child(2){
		top:-8%;
		left:33%;
	}
	.ym_world_area .plus:nth-child(3){
		top:-6%;
		left:59%;
	}
	.ym_world_area .plus:nth-child(4){
		top:-3%;
		left:69%;
	}
	.ym_world_area .plus:nth-child(5){
		top:17%;
		left:45%;
	}
	.ym_world_area .plus:nth-child(6){
		top:-6%;
		left:30%;
	}
	.ym_world_area .right_area{
		height:auto;
		margin-left:0;
		margin-bottom:3vh;
	}
	.ym_world_area .building_wrap{
		width:100%;
		height:14vh;
		padding:2vh;
		border-radius:30px;
	}
	.ym_world_area .building{
		padding:1.5vh;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_world_area .building img{
		display:none;
	}
	.ym_world_area .building span{
		font-size:.6em;
		margin-bottom:1vh;
	}
	.ym_world_area .building p br{
		display:none;
	}

	/*ym_difference_area*/
	.ym_difference_area .middle_area{
		-webkit-box-align:start;
		align-items:flex-start;
	}
	.ym_difference_area .img_area{
		height:27vmin;
	}

	/*ym_myroom_area*/
	.ym_myroom_area{
		padding-top:calc(80px + 3vh);
		padding-bottom:5vh;
	}
	.ym_myroom_area .inner90{
		-webkit-box-pack:center;
		justify-content:Center;
	}
	.ym_myroom_area .bottom_area{
		margin-top:2vh;
		-webkit-box-orient:vertical;
		flex-direction:column;
		width:100%;
		height:auto;
	}
	.ym_myroom_area .box{
		-webkit-transform:scale(1);
		transform:scale(1);
		top:0;
		width:35vmax;
		height:4vh;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		-webkit-transition:all .3s;
		transition:all .3s;
		border-radius:30px;
		margin:.5vh 0;
	}
	.ym_myroom_area .box.on{
		-webkit-transform:scale(1);
		transform:scale(1);
		top:0;
		height:auto;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		margin:1vh 0;
	}
	.ym_myroom_area .box:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.ym_myroom_area .box.on:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.ym_myroom_area .box .cover{
		font-size:.6em;
	}
	.ym_myroom_area .box .content{
		padding:1.5vh;
	}
	.ym_myroom_area .content span{
		font-size:.8em;
	}
	.ym_myroom_area .content p{
		font-size:.65em;
	}
	.ym_myroom_area .box .content .bottom_area{
		margin-top:0;
	}
	.ym_myroom_area .content .graph_area3 .count_wrap{
		height:29%;
	}
	.ym_myroom_area .content .graph_area3 .count1{
		top:55%;
	}
	.ym_myroom_area .content .graph_area3 .count2{
		top:55%;
	}
	.ym_myroom_area .content .graph_area3 .count3{
		top:55%;
	}
	.ym_myroom_area .content .graph_area3 .counting{
		font-size:1.2em;
	}
	.ym_myroom_area .content .graph_area1_graph{
		width:80%;
	}

	/*brain_music_area*/
	.brain_music_area .wrap{
		margin-left:0;
		top:-5vh;
	}
	.brain_music_area .wrap li{
		padding:2vh 2vw;
	}

	/*coding_block_area*/
	.coding_block_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.coding_block_area .bottom_area{
		margin-top:10vh;
	}
	.coding_block_area .cont{
		width:18vw;
	}
	.coding_block_area .cont:last-child{
		width:17vw;
	}
	.coding_block_area .cont:last-child br{
		display:none;
	}
	.coding_block_area .cont img:last-child{
		width:7.5vw;
	}

	/*yf_area*/
	.yf_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.yf_area .content_area{
		height:auto;
		width:100%;
		-webkit-box-orient:vertical;
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
		margin-top:2vh;
	}
	.yf_area .content_area .btn_area{ 
		width:100%;
		height:36px;
		padding-bottom:0;
		margin-right:0;
		margin-left:0;
		margin-bottom:1vh;
		-webkit-box-orient:horizontal;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
	}
	.yf_area .content_area .btn_area li{
		width:auto;
		height:60%;
		margin:0 3vw;
	}
	.yf_area .content_area .btn_area li.on{
		height:100%;
	}
	.yf_area .content_area .btn_area li.on:hover{
		height:100%;
	}
	.yf_area .content_area .btn_area li:hover{
		height:80%;
	}
	.yf_area .yf_img_area{
		height:34vh;
		max-height:300px;
		margin-right:0;
	}
	.yf_area .yf_img_area img{
		height:100%;
	}
	.yf_area .yf_info_area{
		width:100%;
		max-width:400px;
		height:90px;
		margin-top:15px;
		-webkit-box-pack:start;
		justify-content:flex-start;
	} 
	.yf_area .yf_info_area .album_area{
		display:none;
	}	
	.yf_area .yf_info_area .txt_area{
		width:100%;
		height:auto;
	}	
	.yf_area .yf_info_area .txt_box{
		width:100%;
		height:auto;
		padding:3vmin;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}	
	.yf_area .yf_info_area .txt_box span{
		font-size:14px;
	}
	.yf_area .yf_info_area .txt_box p{
		font-size:12px;
		text-align:center;
	}

	/* yame_sns_area */
	.yame_sns_area .inner90{
		width:90%;
		max-width:882px;
	}
	.yame_youtube_area a{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.yame_youtube_area img{
		margin-right:0;
	}
	.yame_youtube_area .right_area{
		display:-webkit-box;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-top:14px;
		width:100%;
	}
	.yame_youtube_area .top_area{
		width:100%;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.yame_youtube_area p{
		text-align:center;
		width:100%;
	}
	.yame_sns_area .yame_cafe_area{
		padding:0 20px;
	}
	.yame_cafe_area .cafe_top_area{
		width:calc(100% - 122px);
	}
	.yame_cafe_area .text_area{
		width:70%;
	}
	.yame_cafe_area p{
		margin-top:0;
	}
	

	/* floating_paging_btn_wrap */
	.floating_paging_btn_wrap{
		display:none;
	}
	
	/*use.html*/
	#content .wrap{width:100%;}
	
	/*pay.html*/
	.pay_tab_btn_area li.on{
		border:none;
	}
	.pay_wrap .product_area{
		width:100%;
	} 
	.pay_wrap .product_area li{
		width:31%;
		margin:0 1%;
	}
	.pay_wrap .product_area li .scratch{
		width:90%;
	}
	.pay_wrap .product_name{
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		align-items:center;
		flex-direction:column;
	}
	.pay_wrap .product_name p{
		padding-bottom:0;
	}
	.pay_wrap .product_name .period{
		margin-right:0;
	}
	.pay_wrap .product_price{
		-webkit-box-align:center;
		-webkit-box-orient:vertical;
		align-items:center;
		flex-direction:column;
	}
	.pay_wrap .product_price .left_area{
		-webkit-box-align:center;
		align-items:center;
		margin-right:0;
		margin-bottom:12px;
	}
	.pay_wrap .product_total_price_area{
		-webkit-box-orient:vertical;
		flex-direction:column;
		height:auto;
		padding:12px;
		padding-bottom:4px;
	}
	.pay_wrap .product_total_price_area span{
		margin-right:0;
		margin-bottom:10px;
	}
	.pay_wrap .btn_area{
		width:100%;
	} 
	.pay_wrap .pay_guide{
		width:100%;
		height:auto;
		padding:0;
		padding-bottom:60px;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-content:center;
		justify-content:center;
		flex-direction:column;
		flex-wrap:no-wrap;
	}
	.pay_wrap .pay_product_info_area{
		padding-bottom:120px;
	}
	.pay_wrap .pay_product_3d_area{
		height:auto;
		background:#ffffff;
		border:20px solid #bc9d70;
		padding-top:80px;
		padding-bottom:80px;
		margin-top:70px;
	}
	.pay_wrap .pay_product_3d_area::before{
		content:'';
		display:block;
		width:10%;
		height:125px;
		background:url(../assets/pay_product_box_bg1_top.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-85px;
		left:50%;
		-webkit-transform:translateX(-50%);
		transform:translateX(-50%);
	}
	.pay_wrap .pay_product_top_area{
		width:100%;
		padding-left:40px;
		padding-right:22px;
	}
	.pay_wrap .pay_product_3d_bottom_area{
		width:100%;
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		align-items:center;
		justify-content:center;
		flex-direction:column;
	}
	.pay_wrap .pay_product_3d_bottom_area .img_area{
		width:60%;
		margin-top:20px;
	}
	.pay_wrap .pay_product_3d_bottom_area img{
		width:100%;
		height:auto;
	}
	.pay_wrap .pay_product_3d_bottom_area .img_area:first-child{
		width:60%;
	}
	.pay_wrap .pay_product_3d_bottom_area .img_area:first-child p{
		width:100%;
	}
	.pay_wrap .pay_product_3d_bottom_area .img_area:nth-child(2){
		margin-left:0; 
		margin-right:0; 
	}
	.pay_wrap .pay_product_contents_area{
		height:auto;
		background:#ffffff;
		border:20px solid #bc9d70;
		padding-top:80px;
		padding-bottom:80px;
		margin-top:70px;
	}
	.pay_wrap .pay_product_contents_area::before{
		content:'';
		display:block;
		width:12%;
		height:136px;
		background:url(../assets/pay_product_box_bg2_top.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-123px;
		left:50%;
		-webkit-transform:translateX(-50%);
		transform:translateX(-50%);
	}
	.pay_wrap .pay_product_contents_bottom_area{
		width:100%;
	}
	.pay_wrap .pay_product_contents_bottom_area .top_area .img_area div{
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		flex-direction:column;
		justify-content:center;
	}
	.pay_wrap .pay_product_contents_bottom_area .top_area img{
		width:60%;
	}
	.pay_wrap .pay_product_contents_bottom_area .top_area img:nth-child(2){
		width:70%;
		margin-left:0;
		margin-top:20px;
	}
	.pay_wrap .pay_product_contents_bottom_area p{
		width:60%;
		margin:0 auto;
		margin-top:12px;
	}
	.pay_wrap .pay_product_contents_bottom_area .bottom_area{
		-webkit-box-orient:vertical;
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area{
		width:60%;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area p{
		width:100%;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area img{
		width:100%;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area:last-child{
		width:100%;
		margin-left:0;
		margin-top:20px;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area:last-child p{
		width:90%;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area:last-child div{
		-webkit-box-pack:center;
		justify-content:Center;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area:last-child div img{
		width:44%;
	}
	.pay_wrap .pay_product_etc_area{
		height:auto;
		background:#bc9d70;
		padding-top:80px;
		padding-bottom:50px;
		margin-top:70px;
	}
	.pay_wrap .pay_product_etc_area::before{
		content:'';
		display:block;
		width:12%;
		height:144px;
		background:url(../assets/pay_product_box_bg3_top.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-90px;
		left:50%;
		-webkit-transform:translateX(-50%);
		transform:translateX(-50%);
	}
	.pay_wrap .pay_product_etc_area .pay_product_top_area{
		width:100%;
		padding-left:56px;
	}
	.pay_product_etc_area img{
		width:90%;
	}
	.pay_wrap .pay_bottom_btn_area a{
		left:42%;
	}
	
	/* order.html */
	.order_wrap li .right_area{
		flex-wrap:wrap;
		padding:20px 0;
		padding-left:64px;
	}
	.order_wrap ul.customer_info_wrap li.vertical .right_area{
		flex-wrap:nowrap;
	}
	.how_to_pay_wrap .radio_btn_area{
		margin-right:0;
		width:50%;
	}


	/*download.html*/
	.download_area{
		padding-bottom:80px;
	}
	.download_area .download_wrap{
		width:90%; 
	}
	.download_wrap .top_top_area{
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		justify-content:center;
		align-items:center;
		flex-direction:column;
	}
	.download_wrap .left_area{
		width:100%;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		justify-content:center;
		align-items:center;
	}
	.download_wrap .left_area p{
		text-align:center;
	}
	.download_wrap .right_area{
		width:100%;
		margin-top:60px;
	}
	.download_wrap .right_area .bottom_area{
		-webkit-box-pack:center;
		justify-content:center;
		max-width:605px;
		margin:0 auto;
		margin-top:18px;
	}
	.download_wrap .btn_area{
		margin-top:100px;
	}
	.download_wrap .btn_area button{
		width:100%;
	}

	/*news.html*/
	.news_area .news_box{
		width:46%;
		margin:20px 2%;
	}

	/* news_content.html */
	.news_more_content_article_area .article_content img{
		width:80%;
		max-width:1000px;
	}

	/* qna.html */
	.qna_tab .inner{
		width:90%;
	}
	.qna_form li span{
		width:150px;
	}
	#qna_form_title_input{
		width:calc(100% - 150px);
	}
	#qna_form_content_textarea{
		width:calc(100% - 150px);
	}
	.qna_tab .line{
		width:90%;
	}
	
	/*mypage_html*/
	.mypage_tab_btn_area li{
		width:auto;
		padding:0 10px;
	}
	.mypage_tab_btn_area li a{
		padding:8px 10px;
	}
	/*mypage_report*/
	.my_report_top_btn_area{
		width:80%;
	}
	/* my_report_top_area */
	.my_report_top_area{
		width:80%;
	}
	.mypage_report .for_freeuser_info{
		width:80%;
	}
	.my_report_missonprogress_area{
		height:auto;
		width:80%;
	}
	.my_report_missonprogress_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.my_report_missonprogress_area .misson_progress{
		width:100%;
		height:auto;
		border-right:none;
		border-bottom:1px solid #e6e6e6;
		padding:30px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		-webkit-box-orient:horizontal;
		align-items:center;
		justify-content:center;
		flex-direction:row;
	}
	.my_report_missonprogress_area .misson_progress:last-child{
		border:none;
	}
	/* my_chart_missonprogress_area */
	.my_chart_missonprogress_area{
		height:auto;
		width:80%;
	}
	.my_chart_missonprogress_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.my_chart_missonprogress_area .misson_progress{
		width:100%;
		height:auto;
		border-right:none;
		border-bottom:1px solid #e6e6e6;
		padding:30px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		-webkit-box-orient:horizontal;
		align-items:center;
		justify-content:center;
		flex-direction:row;
	}
	.my_chart_missonprogress_area .misson_progress:last-child{
		border:none;
	}
	/* my_class_chart_missonprogress_area */
	.my_class_chart_missonprogress_area{
		height:auto;
		width:80%;
	}
	.my_class_chart_missonprogress_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.my_class_chart_missonprogress_area .misson_progress{
		width:100%;
		height:auto;
		border-right:none;
		border-bottom:1px solid #e6e6e6;
		padding:30px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		-webkit-box-orient:horizontal;
		align-items:center;
		justify-content:center;
		flex-direction:row;
	}
	.my_class_chart_missonprogress_area .misson_progress:last-child{
		border:none;
	}
	.misson_progress .misson_left{
		width:30%;
		height:100%;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
		margin-right:40px;
	}
	.misson_progress img{
		margin-bottom:30px;
	}
	.misson_progress .progress_wrap{
		margin-bottom:0;
	}
	.misson_progress .misson_txt{
		width:50%;
		height:100%;
		-webkit-box-align:start;
		-webkit-box-pack:center;
		align-items:flex-start;
		justify-content:center;
	}
	.misson_progress p{
		margin-top:20px;
	}
	.my_report_study_time_area{
		width:80%;
	}
	.my_report_cognitive_ability_area{
		width:80%;
		margin-bottom:100px;
	}
	.my_report_cognitive_ability_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		padding:20px;
		box-sizing:border-box;
	}
	.my_report_cognitive_ability_area .ability_area{
		width:100%;
		height:auto;
		padding:30px;
	}
	.my_report_cognitive_ability_area .ability_area:last-child{
		padding:30px;
	}
	.my_report_cognitive_ability_area .cognitive_ability{
		width:360px;
		margin-bottom:60px;
	}
	/* my_report_bottom_area */
	.my_report_bottom_area{
		width:80%;
		margin-bottom:100px;
	}
	.my_report_bottom_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		padding:20px;
		box-sizing:border-box;
	}
	.my_report_bottom_area .ability_area{
		width:100%;
		height:auto;
		padding:30px;
	}
	.my_report_bottom_area .ability_area:last-child{
		padding:30px;
	}
	.my_report_bottom_area .cognitive_ability{
		width:360px;
		margin-bottom:60px;
	}

	/*mypage_stuedent_info*/
	.mypage_stuedent_info .my_tit{
		margin-top:60px;
	}
	.parents_state_area{
		width:80%;
		height:auto;
		margin-top:50px;
	}
	/* 학부모 상태가 유료 일때 html에서 147,151번줄의 p태그에 class="pay" 추가 */
	.parents_state_box{
		width:100%;
		height:auto;
		padding:20px;
	}
	.parents_state_box .state_head{
		height:inherit;
		width:40%;
		-webkit-box-pack:center;
		-webkit-box-orient:vertical;
		justify-content:center;
		flex-direction:column;
		padding:30px;
		border:none;
	}
	.state_head .profile_wrap{
		margin-right:0;
	}
	.parents_state_box .state_head>span{
		margin-top:20px;
	}
	.parents_state_box .right_area{
		width:60%;
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
		border-left:1px solid #e6e6e6;
	}
	.parents_state_box .state_list{
		height:auto;
		width:100%;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		-webkit-box-orient:horizontal;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		padding:20px 50px;
		position:relative;
		padding-right:30px;
	}
	.parents_state_box .state_list:nth-child(1){
		padding-left:50px;
		width:100%;
	}
	.parents_state_box .state_list:nth-child(2){
		padding-left:50px;
	}
	.parents_state_box .state_list:nth-child(3){
		padding-left:50px;
		width:100%;
	}
	.parents_state_box .state_list:nth-child(4){
		padding-left:50px;
	}
	
	.state_list .info_popup{
		max-width:284px;
		min-width:230px;
		left:-50%;
	}
	.state_list span{
		margin-bottom:0;
		width:30%;
	}
	.state_list p{
		width:70%;
	}
	.state_list p.ellip{
		width:76%;
	}
	.state_list .info_popup p{
		width:100%;
	}
	.mypage_stuedent_info .line{
		width:80%;
	}
	.student_state_area{
		width:70%;
	}
	.student_state_box{
		width:100%;
		height:auto;
		display:-webkit-box;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		display:flex;
		align-items:center;
		justify-content:center;
		padding:20px;
		box-sizing:border-box;
		margin-top:60px;
	}
	/*학습자 정보/관리 페이지에 처음 들어왔을때 새로운 학습자가 생성되었을때 클래스 student_state_box에 클래스 new 추가 */
	.student_state_box.new::after{
		top:330px;
	}
	.student_state_box .state_head{
		height:inherit;
		width:40%;
		-webkit-box-pack:center;
		-webkit-box-orient:vertical;
		justify-content:center;
		flex-direction:column;
		border:none;
		padding-left:0;
	}
	.student_state_box .state_head .profile_wrap{
		margin-right:0; 
	}
	.student_state_box .state_head p{
		width:80%;
		margin-top:10px;
	}
	.student_state_box .right_area{
		width:60%;
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
		border-left:1px solid #e6e6e6;
	}
	.student_state_box .state_list{
		height:auto;
		width:100%;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		-webkit-box-orient:horizontal;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		padding:20px 40px;
		position:relative;
	}
	.student_state_box .state_list:nth-child(1){
		width:100%;
	}
	.student_state_box .state_list:nth-child(2){
		width:100%;
	}
	.student_state_box .state_list span{
		margin:0;
	}
	.student_state_box .state_list:last-child{
		width:100%;
		height:auto;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.student_state_box .state_list:last-child button{
		margin-top:0;
		margin-left:10px;
	}

	/*mypage_parents_info*/
	.mypage_parents_info .my_tit{
		margin-top:60px;
	}
	.mypage_parents_info .parents_info_area{
		width:80%;
		height:auto;
		padding:30px 40px;
	}
	.parents_info_area ul{
		height:auto;
	}
	.parents_info_area li .head{
		width:35%;
	}
	.parents_info_area .right_area{
		width:65%;
	}
	.parents_info_area p{
		padding-left:30px;
	}
	.parents_info_area p.info{
		font-size:12px;
		line-height:1.5;
		margin-top:10px;
	}
	.parents_info_area input{
		width:182px;
		height:40px;
		padding:10px 30px;
		margin-right:20px;
		font-size:14px;
	}
	.parents_info_area input::placeholder{
		font-size:12px;
	}
	.parents_info_area button{
		width:100px;
		height:35px;
		font-size:12px;
	}
	.parents_info_area li.adress_area{
		height:214px;
	}
	.parents_info_area li.adress_area .top_area input{
		width:176px;
	}
	.parents_info_area li.adress_area input{
		width:315px;
	}
	.parents_info_area li.sms_add{
		height:114px;
	}
	.parents_info_area li.sms_add .right_area{
		-webkit-box-orient:vertical;
		flex-direction:column;
		box-sizing:border-box;
		padding:20px 0;
	}
	.parents_info_area li.sms_add .top_area{
		display:-webkit-box;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		display:flex;
		align-items:center;
		justify-content:flex-start;
		width:100%;
	}
	.parents_info_area .radio_btn_area span{
		margin-right:40px;
	}

	/*mypage_pay_info*/
	.refund_popup div{
		height:90%;
		max-height:490px;
	}
	.mypage_pay_info .my_tit{
		margin-top:60px;
	}
	.mypage_pay_info .pay_info_area{
		width:80%;
		margin-bottom:100px;
	}
	.pay_info_list_wrap li.list_head div{
		width:19%;
	}
	.pay_info_list_wrap li.list_head div:nth-child(2){
		width:19%;
	}
	.pay_info_list_wrap li.list_head div:nth-child(3){
		width:12%;
	}
	.pay_info_list_wrap li.list_head div:nth-child(4){
		width:14.5%;
	}
	.pay_info_list_wrap li.list_head div:nth-child(5){
		width:14.5%; 
	}
	.pay_info_list_wrap li.list_head div:nth-child(6){
		width:21%;
	}
	.pay_info_list_wrap ul.top_area{
		width:38%;
		height:100%;
		position:relative;
	}
	.pay_info_list_wrap ul.bottom_area{
		width:62%;
		height:100%;
		position:relative;
	}
	.pay_info_list_wrap li.list li{
		width:50%;
	}
	.pay_info_list_wrap li.list .top_area li:nth-child(2){
		width:50%;
	}
	.pay_info_list_wrap li.list .bottom_area li:nth-child(1){
		width:19%;
	}
	.pay_info_list_wrap li.list .bottom_area li:nth-child(2){
		width:23.5%;
	}
	.pay_info_list_wrap li.list .bottom_area li:nth-child(3){
		width:23.5%; 
	}
	.pay_info_list_wrap li.list .bottom_area li:nth-child(4){
		width:34%;
	}
	.pay_info_list_wrap .list .date{
		font-size:14px;
	}
	.pay_info_list_wrap .list .name{
		font-size:14px;
	}
	.pay_info_list_wrap .list a{
		width:100px;
	}
	.pay_info_list_wrap .list button{
		width:100px;
	}

	/*mypage_pay_info_more*/
	.mypage_pay_info_more .my_tit{
		margin-top:60px;
	}
	.mypage_pay_info_more .pay_info_more_area{
		width:80%;
		margin-bottom:100px;
	}
	.pay_info_more_area ul{
		width:100%;
	}
	.pay_info_more_area .head{
		width:30%;
		padding-left:60px;
	}
	.pay_info_more_area p{
		width:70%;
	}
	.pay_info_more_area li.pay_more_refund div{
		width:70%;
	}
	.pay_info_more_area li.pay_more_refund p.info{
		width:100%;
		max-width:400px;
	}
	.pay_info_more_area li.info_txt div{
		width:70%;
	}

	/*mypage_student_register*/
	.mypage_student_register .my_tit{
		margin-top:60px;
	}
	.mypage_student_register .student_register_area{
		width:80%;
		height:auto;
		padding:0 40px;
	}
	.student_register_area ul{
		padding:0 20px;
		box-sizing:border-box;
	}
	.student_register_area li:first-child{
		height:146px;
	}
	.student_register_area li .head{
		height:100%;
		width:35%;
		padding-left:0;
	}
	.student_register_area .right_area{
		width:65%;
	}
	.student_register_area p.info{
		margin-top:10px;
	}
	.student_register_area input{
		width:190px;
	}
	.student_register_area select{
		width:auto;
		max-width:160px;
	}
	#my_register_product_list_select{
		width:100%;
		max-width:295px;
	}

	/*mypage_student_infofix*/
	.mypage_student_infofix .student_register_area li.infofix_vertical{
		height:128px;
	}
	.mypage_student_infofix .student_register_area li:last-child{
		margin-bottom:0px;
	}

	/*mypage_coupon*/
	.mypage_coupon .my_tit{
		margin-top:60px;
	}
	.mypage_coupon .coupon_list_area{
		width:80%;
		margin-bottom:100px;
	}
	.coupon_list_wrap li.list_head div{
		width:120px;
	}
	.coupon_list_wrap li.list_head .coupon_name{
		width:50%;
	}
	.coupon_list_wrap li.list_head .coupon_use_date{
		width:calc(50% - 190px);
	}
	.coupon_list_wrap li.list_head .coupon_state{
		width:70px;
	}
	.coupon_list_wrap .list .top_area{
		width:50%;
	}
	.coupon_list_wrap .list .coupon_name{
		font-size:14px;
		max-width:76%;
	}
	.coupon_list_wrap .list .coupon_d_day{
		margin-left:4px;
	}
	.coupon_list_wrap .list .middle_area{
		width:calc(50% - 120px);
	}
	.coupon_list_wrap .list .coupon_use_date{
		width:calc(100% - 70px);
	}
	.coupon_list_wrap .list .coupon_use_date span{
		font-size:14px;
		text-align:center;
	}
	.coupon_list_wrap .list .coupon_state{
		width:70px;
	}
	.coupon_list_wrap .list .coupon_state span{
		font-size:14px;
	}
	.coupon_list_wrap .list .bottom_area{
		width:120px;
	}
	.coupon_list_wrap .list a{
		width:100px;
	}
	.coupon_info_area .bottom_area{
		padding:10px 8px;
	}
	
	/* my_qna_info.html */
	.mypage_qna_info .qna_info_area{
		width:100%;
	}
	.qna_info_list_wrap li.list_head div{
		width:120px;
	}
	.qna_info_list_wrap li.list_head .qna_number{
		width:40px;
	}
	.qna_info_list_wrap li.list_head .qna_title{
		width:calc(100% - 424px);
	}
	.qna_info_list_wrap li.list_head .qna_name{
		width:74px;
	}
	.qna_info_list_wrap li.list_head .qna_date{
		width:100px;
	}
	.qna_info_list_wrap li.list_head .qna_state{
		width:90px;
	}
	.qna_info_list_wrap .list .right_area{
		width:calc(100% - 40px);
	}
	.qna_info_list_wrap .list .number_area{
		width:40px;
	}
	.qna_info_list_wrap .list .top_area{
		width:calc(100% - 384px);
	}
	.qna_info_list_wrap .list .name{
		width:74px;
	}
	.qna_info_list_wrap .list .date{
		width:100px;
	}
	.qna_info_list_wrap .list .state{
		width:90px;
	}
	.qna_info_list_wrap .list .bottom_area{
		width:120px;
	}
	.qna_info_list_wrap .list a{
		width:100px;
	}

	/* 자주묻는질문 */
	.faq_tab .faq_area{
		width:100%;
	}
	
}
/*모바일 세로*/
@media screen and (min-width:320px) and (max-width:767px){
	.inner{
		width:90%;
	}
	
	/* 페이징 버튼 */
	/* .list_pagebtn_area{
		display:none;
	}
	.list_more_btn{
		display:block;
	} */

	/*header*/
	.header .inner{
		width:90%;
	}
	.header .logo{
		margin:0 auto;
		margin-top:12px;
		height:44px;
	}
	.menu_wrapper{
		display:none;
	}
	/*login&mypage*/
	.header .button{
		display:none; 
	}
	/*mobile_menu_btn_area*/
	.mobile_menu_btn_area{
		display:block; 
	}
	.mobile_menu_area{
		width:70%;
	}
	
	.login_bg_wrapper{
		background:#ffffff; 
		height:auto;
	}
	.login_title{
		font-size:26px;
	}
	/*로그인 login.html*/
	.login_area{
		width:98%; 
		max-width:420px;
	}
	.login_area a.google{
		width:100%; 
		max-width:348px;
	}
	
	/*password_reset_area*/
	.password_reset_area{
		width:98%; 
		max-width:500px;
	}
	.password_reset_area .password_reset_wrap{
		height:auto;
	}

	/*email_reset_area*/
	.email_reset_area{
		width:98%; 
		max-width:500px;
	}
	.email_reset_area .email_reset_wrap{
		height:auto;
	}

	/*join_area*/
	.join_area{
		width:98%; 
		max-width:420px;
	}
	.join_area .input_area.email .input_area{
		width:70%;
	}
	
	/*join_group_area*/
	.join_group_area{
		width:98%; 
		max-width:420px;
	}
	.join_group_area .join_group_wrap .select_area span{
		font-size:15px;
	}
	
	/*stuedent_make_area*/
	.stuedent_make_area{
		width:98%; 
		max-width:420px;
	}
	.stuedent_make_area .input_area.nikname .input_area{
		width:70%;
	}
	
	/*join_agree*/
	.login_bg_wrapper.join_agree_bg_wrapper{
		min-height:800px;
	}
	.join_agree_area{
		width:98%; 
		max-width:1000px; 
		min-height:790px;
	}
	.join_agree_area a.agree_logo img{
		margin-top:20px;
	}
	.join_agree_area .join_agree_wrap .agree_use_wrap{
		height:160px;
	}
	.agree_privacy_wrap .box_wrap .title span{
		font-size:16px;
		width:70%;
	}
	.agree_privacy_wrap .box_wrap .title span:first-child{
		width:30%;
	}
	.agree_privacy_wrap .box_wrap .list span{
		width:70%;
		font-size:14px;
		padding:20px;
	}
	.agree_privacy_wrap .box_wrap .list span.name{
		width:30%;
		font-size:15px;
		padding:0;
	}
	
	/*footer*/
	section.footer_section{
		height:auto;
		background:#1a1a1a;
		padding-top:0;
		padding-bottom:0;
		top:0;
		position:relative;
	}
	.footer{
		height:auto;
	}
	.footer .inner90{
		width:90%;
		-webkit-box-direction:reverse;
		-webkit-box-orient:vertical;
		flex-direction:column-reverse;
	}
	.f_left{
		width:100%;
		height:auto;
		margin:20px 0;
		margin-top:30px;
	}
	.f_left ul{
		-webkit-box-align:center;
		align-items:center;
		padding-left:0;
	}
	.f_left li{
		text-align:center;
	}
	.f_left li:nth-child(1){
		margin-bottom:16px;
	}
	.f_right{
		width:100%;
		height:auto;
	}
	.f_right ul{
		-webkit-box-align:center;
		align-items:center;
		margin-top:40px;
	}
	.f_right li.faq a{
		font-size:32px;
	}
	
	/*index.html*/
	/* 공지팝업 */
	.notice_popup_bg{
		display:none;
		position:fixed;
		top:50%;
		left:50%;
		z-index:130;
	}
	.notice_popup_area{
		top:50%;
		left:50%;
		-webkit-transform:translate(-50%,-50%);
		transform:translate(-50%,-50%);
	}
	
	.stakato::before{
		width:.5vh;
		height:.5vh;
		top:-.7vh;
	}
	.index_tit .stakato::before{
		width:.8vh;
		height:.8vh;
		top:-.3vh;
	}
	.sub_title{
		font-size:.8em;
	}
	p.txt{
		font-size:12px;
	}
	section{
		height:100vh;
		position:fixed;
		top:100vh;
	}
	/*main_banner_area */
	.bn_content_wrap{
		padding-top:80px;
		padding-bottom:16vh;
	}
	.bn.open .top_area span.title{
		font-size:3em;
	}
	.bn.open .top_area p{
		font-size:.9em;
	}
	.bn.open .top_area p.second_p{
		line-height:1.6;
	}
	.bn.open .top_area span.strong{
		font-size:1.7em;
	}
	.bn.open .top_area p.input_info{
		text-align:center;
		line-height:1.6;
	}
	.bn.open .top_area p.input_info br{
		display:block;
	}
	.bn.open .open_email_input_area{
		width:94%;
	}
	.bn.open #banner_email_input{
		width:80%;
		max-width:343px;
	}
	.bn.open .img_area{
		margin-top:10px;
	}
	.bn.open .img_area .base img{
		width:95%;
	}
	.bn.open .img_area .top img{
		width:52%;
		margin-top:12%;
	}
	.bn.open .img_area .bottom .bounceIn .on img{
		width:30.5%;
		height:auto;
		margin-top:6%;
		-webkit-animation-delay:.8s;
		animation-delay:.8s;
	}
	.bn_btn_wrap{
		top:84%;
	}
	/* 오픈세일배너 */
	.bn.sale{
		background:url(../assets/sale_bn_bg_mobile.png) no-repeat center;
		background-size:cover;
	}
	.bn.sale .bn_content_wrap .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.bn.sale .bottom_coin_area{
		background:url(../assets/sale_bn_bottom_coin_mobile.png) no-repeat center;
		background-size:cover;
	}
	.bn.sale .txt1{
		width:96%;
		height:auto;
		margin-top:0;
	}
	.bn.sale .txt2{
		width:60%;
		height:auto;
		margin-top:26px;
		margin-bottom:32px;
	}
	.bn.sale .coin1.bounceIn{
		display:none;
	}
	.bn.sale .coin2.bounceIn{
		height:5%;
		top:89%;
		left:0;
	}
	.bn.sale .coin3.bounceIn{
		height:11%;
		top:4%;
		left:-15%;
	}
	.bn.sale .coin4.bounceIn{
		height:5%;
		top:59%;
		left:-42%;
	}
	.bn.sale .coin5.bounceIn{
		height:7%;
		top:8%;
		left:31%;
	}
	.bn.sale .coin6.bounceIn{
		height:7%;
		top:77%;
		left:16%;
	}
	.bn.sale .coin7.bounceIn{
		height:4%;
		top:41%;
		left:18%;
	}
	.bn.sale .coin8.bounceIn{
		display:none;
	}
	.bn.sale .coin9.bounceIn{
		display:none;
	}
	.bn.sale .coin10.bounceIn{
		display:none;
	}
	/* 인기브랜드대상배너 */
	.bn.popular{
		background:url(../assets/popular_bn_bg_mobile.png) no-repeat center;
		background-size:cover;
	}
	.bn.popular .txt{
		height:auto;
		width:90%;
	}
	.bn.popular .tropy{
		height:auto;
		width:77%;
		margin-top:10px;
	}
	.bn.popular .top .star1{
		height:12%;
		top:53%;
		left:-35%;
	}
	.bn.popular .top .star2{
		display:none;
	}
	.bn.popular .top .star3{
		height:19%;
		top:2%;
		left:37%;
	}
	.bn.popular .top .star4{
		height:12%;
		top:91%;
		left:38%;
	}
	.bn.popular .top .star5{
		display:none;
	}
	.bn.popular .top .star6{
		display:none;
	}
	.bn.popular .bottom .star1{
		display:none;
	}
	.bn.popular .bottom .star2{
		display:none;
	}
	.bn.popular .bottom .star3{
		height:18%;
		top:-54%;
		left:-40%;
	}
	.bn.popular .bottom .star4{
		height:14%;
		top:-1%;
		left:-41%;
	}
	.bn.popular .bottom .star5{
		display:none;
	}
	.bn.popular .bottom .star6{
		height:12%;
		top:-12%;
		left:23%;
	}
	.bn.popular .bottom .star7{
		display:none;
	}
	/* 수학섬 업데이트 배너 */
	.bn.mathupdate{
		background:url(../assets/math_bg.png) no-repeat center;
		background-size:cover;
	}
	.bn.mathupdate .bn_content_wrap .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.bn.mathupdate .txt1{
		position: absolute;
		z-index: 2;
		height:auto;
		width: 90%;
		margin-top:-20%;
		margin-left: -2%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}
	.bn.mathupdate .txt2{
		position: relative;
		z-index: 1;
		height:auto;
		width: 110%;
		margin-top:40%;
		-webkit-animation-delay:.3s;
		animation-delay:.3s;
	}
	.bn.mathupdate .button{
		position: absolute;
		z-index: 2;
		height: auto;
		margin-top:-80%;
		margin-left:-4%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}

	/*introduce_area*/
	.introduce_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.introduce_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.introduce_area .top_area{
		height:auto;
		-webkit-box-direction:reverse;
		-webkit-box-orient:vertical;
		flex-direction:column-reverse;
	}
	.introduce_area .top_area img{
		height:30vh;
		margin-right:0;
	}
	.introduce_area .top_area .right_area{
		display:-webkit-box;
		-webkit-box-align:Center;
		-webkit-box-orient:vertical;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-bottom:2.5vh;
	}
	.introduce_area .right_area p{
		font-size:12px;
		text-align:center;
		margin-top:1.5vh;
	}
	.introduce_area .right_area p br{
		display:none;
	}
	.introduce_area span.index_tit{
		text-align:center;
		margin-top:1vh;
	}
	.introduce_area .ym_who_popup.on{
		width:100%;
		height:104%;
		padding:4vmin;
		-webkit-box-orient:vertical;
		flex-direction:column;
		border-radius:30px;
	}
	.introduce_area .ym_who_popup.on .ym_wyo_box{
		width:100%;
		margin-top:3vh;
	}
	.ym_who_popup.on .ym_wyo_box:first-child{
		width:100%;
		margin:0;
	}
	.ym_who_popup.on .ym_wyo_box:nth-child(2){
		width:100%;
		margin:0;
		margin-top:3vh;
	}
	.ym_who_popup.on .ym_wyo_box span{
		font-size:16px;
	}
	.ym_who_popup.on .ym_wyo_box p{
		margin-top:1vh;
		font-size:12px;
	}
	.introduce_area .ym_who_btn{
		width:auto;
		height:auto;
		box-sizing:border-box;
		padding:1vh 15vmin;
		margin-top:6vh;
	}
	.introduce_area .ym_who_btn span{
		font-size:14px;
	}

	/*ym_world_area*/
	.ym_world_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.ym_world_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_world_area .index_tit{
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;	
	}
	.ym_world_area .index_tit .br{
		margin-right:0;
	}
	.ym_world_area p.txt{
		margin-top:1vh;
	}
	.ym_world_area p.txt br{
		display:none;
	}
	.ym_world_area .bottom_area{
		height:auto;
		width:100%;
		margin-top:1.5vh;
		-webkit-box-orient:vertical;
		-webkit-box-direction:reverse;
		flex-direction:column-reverse;
	}
	.ym_world_area .left_area{
		height:30vh;
		margin-left:0;
		margin-top:3.5vh;
	}
	.ym_world_area .left_area .loby{
		height:135%;
		margin-top:0;
	}
	.ym_world_area .plus_wrap .plus{
		width:3vh;
		height:3vh;
	}
	.ym_world_area .plus:nth-child(1){
		top:16%;
		left:34%;
	}
	.ym_world_area .plus:nth-child(2){
		top:-10%;
		left:37%;
	}
	.ym_world_area .plus:nth-child(3){
		top:-16%;
		left:58%;
	}
	.ym_world_area .plus:nth-child(4){
		top:-15%;
		left:69%;
	}
	.ym_world_area .plus:nth-child(5){
		top:11%;
		left:46%;
	}
	.ym_world_area .plus:nth-child(6){
		top:-11%;
		left:35%;
	}
	.ym_world_area .right_area{
		height:auto;
		margin-left:0;
		top:1.5vh;
	}
	.ym_world_area .building_wrap{
		width:80%;
		height:14vmax;
		padding:0;
		border:2px dashed #ffffff;
		border-radius:30px;
	}
	.ym_world_area .building{
		padding:1.5vh;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_world_area .building img{
		display:none;
	}
	.ym_world_area .building span{
		font-size:13px;
		margin-bottom:1vh;
		padding:0 2vw;
	}
	.ym_world_area .building p{
		font-size:12px;
	}
	.ym_world_area .building p br{
		display:none;
	}

	/*ym_difference_area*/
	.ym_difference_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.ym_difference_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_difference_area .tabbtn_area .btn:first-child{
		margin-right:4.5vw;
	}
	.ym_difference_area .tabbtn_area .btn{
		font-size:14px;
	}
	.ym_difference_area .tabbtn_area .btn::before{
		margin-right:1vw;
	}
	.ym_difference_area .middle_area p{
		font-size:12px;
	}
	.ym_difference_area .img_area{
		height:27vmin;
		margin-top:6vh;
	}

	/*ym_myroom_area*/
	.ym_myroom_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.ym_myroom_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_myroom_area .index_tit{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.ym_myroom_area .index_tit .br{
		margin-right:0;
	}
	.ym_myroom_area p.txt br{
		display:none;
	}
	.ym_myroom_area .bottom_area{
		margin-top:2vh;
		-webkit-box-orient:vertical;
		flex-direction:column;
		width:100%;
		height:auto;
	}
	.ym_myroom_area .box{
		-webkit-transform:scale(1);
		transform:scale(1);
		top:0;
		width:65%;
		height:5vh;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		-webkit-transition:all .3s;
		transition:all .3s;
		border-radius:30px;
		margin:3px 0;
	}
	.ym_myroom_area .box.on{
		-webkit-transform:scale(1);
		transform:scale(1);
		top:0;
		height:auto;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		margin:5px 0;
	}
	.ym_myroom_area .box:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.ym_myroom_area .box.on:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.ym_myroom_area .box .cover{
		font-size:10px;
	}
	.ym_myroom_area .box .content{
		padding:1.5vh;
	}
	.ym_myroom_area .content span{
		font-size:14px;
	}
	.ym_myroom_area .content p{
		font-size:12px;
	}
	.ym_myroom_area .box .content .bottom_area{
		margin-top:0;
	}
	.ym_myroom_area .content .graph_area3 .count_wrap{
		height:29%;
	}
	.ym_myroom_area .content .graph_area3 .count1{
		top:58%;
	}
	.ym_myroom_area .content .graph_area3 .count2{
		top:58%;
	}
	.ym_myroom_area .content .graph_area3 .count3{
		top:58%;
	}
	.ym_myroom_area .content .graph_area3 .counting{
		font-size:1em;
	}
	.ym_myroom_area .content .graph_area1_graph{
		width:80%;
	}

	/*brain_music_area*/
	.brain_music_area{
		padding-top:80px;
		padding-bottom:10vh;
		background:url(../assets/musicroom_mobile.png) no-repeat center;
		background-size:cover;
	}
	.brain_music_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.brain_music_area .index_tit{
		width:100%;
		text-align:center;
		padding-left:0;
	}
	.brain_music_area .sub_title{
		text-align:center;
		padding-left:0;
	}
	.brain_music_area .wrap{
		margin-left:0;
		top:0;
		-webkit-box-align:center;
		align-items:center;
	}
	.brain_music_area .wrap ul{
		display:-webkit-box;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-top:4vh;
	}
	.brain_music_area .wrap li{
		padding:1vh 4vw;
		width:100%;
		height:auto;
		min-width:auto;
	}
	.brain_music_area .wrap li:nth-child(2){
		margin:2vh 0;
	}
	.brain_music_area .wrap li .play_wrap{
		width:8vh;
		height:8vh;
		margin-right:4vw;
	}
	.brain_music_area .wrap li .right_area{
		width:70%;
	}
	.brain_music_area .wrap li .right_area span{
		font-size:14px;
	}
	.brain_music_area .wrap li .right_area p{
		font-size:12px;
	}

	/*coding_block_area*/
	.coding_block_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.coding_block_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.coding_block_area .index_tit{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.coding_block_area .bottom_area{
		margin-top:2vh;
		-webkit-box-orient:vertical;
		flex-direction:column;
		height:auto;
	}
	.coding_block_area .cont{
		width:90%;
		height:6vh;
		overflow:hidden;
		padding:2vmin;
	}
	.coding_block_area .cont:last-child{
		width:100%;
	}
	.coding_block_area .cont:last-child br{
		display:none;
	}
	.coding_block_area .cont .num{
		height:4.5vh;
	}
	.coding_block_area .cont .title{
		font-size:14px;
		visibility:hidden;
	}
	.coding_block_area .cont .sub_tit{
		font-size:11px;
		visibility:hidden;
	}
	.coding_block_area .cont p{
		font-size:12px;
		visibility:hidden;
	}
	.coding_block_area .cont img:last-child{
		width:20vw;
		visibility:hidden;
	}
	.coding_block_area .cont.on .title{
		visibility:visible;
	}
	.coding_block_area .cont.on .sub_tit{
		visibility:visible;
	}
	.coding_block_area .cont.on p{
		visibility:visible;
	}
	.coding_block_area .cont.on img:last-child{
		visibility:visible;
	}
	.coding_block_area .cont.on{
		-webkit-transform:scale(1);
		transform:scale(1);
		margin:2vh 0;
		overflow:visible;
		height:auto;
	}
	.coding_block_area .cont.on:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.coding_block_area .cont:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.coding_block_area .cont:last-child.on{
		margin-left:0;
	}
	.coding_block_area .arrow_wrap{
		display:none;
	}

	/*yf_area*/
	.yf_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.yf_area .content_area{
		height:auto;
		width:100%;
		-webkit-box-orient:vertical;
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
		margin-top:2vh;
	}
	.yf_area .content_area .btn_area{ 
		width:100%;
		height:36px;
		padding-bottom:0;
		margin-right:0;
		margin-left:0;
		margin-bottom:1vh;
		-webkit-box-orient:horizontal;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
	}
	.yf_area .content_area .btn_area li{
		width:auto;
		height:60%;
		margin:0 3vw;
	}
	.yf_area .content_area .btn_area li.on{
		height:100%;
	}
	.yf_area .content_area .btn_area li.on:hover{
		height:100%;
	}
	.yf_area .content_area .btn_area li:hover{
		height:80%;
	}
	.yf_area .yf_img_area{
		height:160px;
		margin-right:0;
	}
	.yf_area .yf_img_area img{
		height:100%;
	}
	.yf_area .yf_info_area{
		width:100%;
		height:90px;
		margin-top:15px;
		-webkit-box-pack:start;
		justify-content:flex-start;
	} 
	.yf_area .yf_info_area .album_area{
		display:none;
	}	
	.yf_area .yf_info_area .txt_area{
		width:100%;
		height:auto;
	}	
	.yf_area .yf_info_area .txt_box{
		width:100%;
		height:auto;
		padding:3vmin;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}	
	.yf_area .yf_info_area .txt_box span{
		font-size:14px;
	}
	.yf_area .yf_info_area .txt_box p{
		font-size:12px;
		text-align:center;
	}

	/* yame_sns_area */
	.yame_sns_area{
		padding-bottom:10vh;
		background-size:auto 50%;
	}
	.yame_sns_area .inner90{
		width:90%;
	}
	.yame_sns_area .yame_sns_title_area{
		height:52px;
	}
	.yame_sns_title_area span{
		font-size:1.45em;
	}
	.yame_sns_area .yame_youtube_area{
		margin-top:10px;
		padding:0;
	}
	.yame_youtube_area a{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.yame_youtube_area img{
		margin-right:0;
		height:132px;
	}
	.yame_youtube_area .right_area{
		display:-webkit-box;
		-webkit-box-orient:vertical;
		 -webkit-box-align:center;
		 display:flex;
		 flex-direction:column;
		 align-items:center;
		 margin-top:6px;
		 width:100%;
	}
	.yame_youtube_area .top_area{
		width:100%;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.yame_youtube_area .top_area img{
		height:26px;
	}
	.yame_youtube_area span{
		font-size:1.45em;
	}
	.yame_youtube_area p{
		text-align:center;
		font-size:1em;
		line-height:1.6;
		margin-top:6px;
		width:100%;
	}
	.yame_youtube_area p br{
		display:none;
	}
	.yame_sns_area .yame_cafe_area{
		width:100%;
		height:auto;
		box-sizing:border-box;
		padding:12px 20px;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		flex-direction:column;
		align-items:center;
		justify-content:Center;
	}
	.yame_cafe_area .cafe_top_area{
		width:100%;
	}
	.yame_cafe_area .text_area{
		width:70%;
	}
	.yame_cafe_area span{
		font-size:1em;
	}
	.yame_cafe_area p{
		margin-top:0;
		font-size:.8em;
	}
	.yame_cafe_area a{
		margin-top:6px;
		width:100%;
		height:42px;
		font-size:.9em;
	}

	/* floating_paging_btn_wrap */
	.floating_paging_btn_wrap{
		visibility:hidden;
	}
	
	/*use.html*/
	#content .wrap{width:100%;}
	/*privacy.html*/
	#content .box_wrap .title span{
		font-size:16px;
		width:70%;
	}
	#content .box_wrap .title span:first-child{
		width:30%;
	}
	#content .box_wrap .list span{
		width:70%;
		font-size:14px;
		padding:20px;
	}
	#content .box_wrap .list span.name{
		width:30%;
		font-size:15px;
		padding:0;
	}
	#content .wrap p{
		padding:10px 0;
		font-size:16px;
	}
	
	/*pay.html*/
	.pay_wrap .product_area{
		width:100%;
	} 
	.pay_wrap .product_area .top_top_area{
		height:144px;
		display:-webkit-box;
		-webkit-box-pack:center;
		display:flex;
		justify-content:center;
	}
	.product_area .top_top_area img{
		width:auto;
		height:100%;
	}
	.pay_wrap .product_area ul{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.pay_wrap .product_area li{
		width:100%;
		margin:0;
		margin-bottom:20px;
	}
	.pay_wrap .product_area li .scratch{
		width:90%;
	}
	.pay_wrap .btn_area{
		width:100%;
	}
	.pay_wrap .pay_guide{
		width:100%;
		height:auto;
		padding:0;
		padding-bottom:60px;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-content:center;
		justify-content:center;
		flex-direction:column;
		flex-wrap:no-wrap;
	}
	.pay_wrap .pay_guide li{
		margin-right:0;
	}
	.pay_wrap .pay_guide li br{
		display:block;
	}
	.pay_wrap .pay_guide li:first-child::before{
		position:relative;
		top:-9px;
	}
	.pay_wrap .pay_guide li:nth-child(2)::before{
		position:relative;
		top:-20px;
	}
	.pay_wrap .pay_guide li a{
		text-decoration:underline;
	}
	.pay_wrap .pay_guide li a::before{
		display:none;
	}
	.pay_wrap .pay_guide li a::after{
		position:relative;
		top:11px;
		left:-66px;
	}
	.pay_wrap .pay_product_info_top_line::after{
		width:70%;
		max-width:334px;
	}
	.pay_wrap .pay_product_info_area{
		padding-bottom:120px;
		background-size:110% auto;
		padding-top:54%;
	}
	.pay_wrap .pay_product_title{
		font-size:22px;
	}
	.pay_wrap .pay_product_3d_area{
		height:auto;
		background:#ffffff;
		border:20px solid #bc9d70;
		padding:20px 0;
		margin-top:70px;
	}
	.pay_wrap .pay_product_3d_area::before{
		content:'';
		display:block;
		width:14%;
		height:106px;
		background:url(../assets/pay_product_box_bg1_top.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-80px;
		left:50%;
		-webkit-transform:translateX(-50%);
		transform:translateX(-50%);
	}
	.pay_wrap .pay_product_top_area{
		width:100%;
		padding-left:30px;
		padding-right:0;
	}
	.pay_wrap .pay_product_3d_bottom_area{
		width:100%;
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		align-items:center;
		justify-content:center;
		flex-direction:column;
	}
	.pay_wrap .pay_product_3d_bottom_area .img_area{
		width:90%;
		margin-top:20px;
	}
	.pay_wrap .pay_product_3d_bottom_area img{
		width:100%;
		height:auto;
	}
	.pay_wrap .pay_product_3d_bottom_area .img_area:first-child{
		width:90%;
	}
	.pay_wrap .pay_product_3d_bottom_area .img_area:first-child p{
		width:100%;
		padding-left:0;
	}
	.pay_wrap .pay_product_3d_bottom_area .img_area:nth-child(2){
		margin-left:0; 
		margin-right:0; 
	}
	.pay_wrap .pay_product_contents_area{
		height:auto;
		background:#ffffff;
		border:20px solid #bc9d70;
		padding:20px 0;
		margin-top:70px;
	}
	.pay_wrap .pay_product_contents_area::before{
		content:'';
		display:block;
		width:14%;
		height:100px;
		background:url(../assets/pay_product_box_bg2_top.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-90px;
		left:50%;
		-webkit-transform:translateX(-50%);
		transform:translateX(-50%);
	}
	.pay_wrap .pay_product_contents_bottom_area{
		width:100%;
	}
	.pay_wrap .pay_product_top_area span::before{
		position:absolute;
		top:15px;
		left:-15px;
	}
	.pay_wrap .pay_product_top_area p{
		padding-left:0;
		padding-right:10px;
	}
	.pay_wrap .pay_product_contents_bottom_area .top_area .img_area div{
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		flex-direction:column;
		justify-content:center;
	}
	.pay_wrap .pay_product_contents_bottom_area .top_area img{
		width:90%;
	}
	.pay_wrap .pay_product_contents_bottom_area .top_area img:nth-child(2){
		width:90%;
		margin-left:0;
		margin-top:20px;
	}
	.pay_wrap .pay_product_contents_bottom_area p{
		width:100%;
		margin:0 auto;
		margin-top:12px;
	}
	.pay_wrap .pay_product_contents_bottom_area .bottom_area{
		-webkit-box-orient:vertical;
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
	}
	.pay_wrap .pay_product_contents_bottom_area p br.mobile{
		display:block;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area{
		width:90%;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area p{
		width:100%;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area img{
		width:100%;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area:last-child{
		width:100%;
		margin-left:0;
		margin-top:20px;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area:last-child p{
		width:90%;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area:last-child div{
		-webkit-box-pack:center;
		justify-content:Center;
	}
	.pay_product_contents_bottom_area .bottom_area .img_area:last-child div img{
		width:44%;
	}
	.pay_wrap .pay_product_etc_area{
		height:auto;
		background:#bc9d70;
		padding:40px 0;
		margin-top:70px;
	}
	.pay_wrap .pay_product_etc_area::before{
		content:'';
		display:block;
		width:12%;
		height:100px;
		background:url(../assets/pay_product_box_bg3_top.png) no-repeat center;
		background-size:100% auto;
		position:absolute;
		top:-65px;
		left:50%;
		-webkit-transform:translateX(-50%);
		transform:translateX(-50%);
	}
	.pay_wrap .pay_product_etc_area .pay_product_top_area{
		width:100%;
		padding-left:40px;
	}
	.pay_product_etc_area img{
		width:96%;
	}
	.pay_wrap .pay_bottom_btn_area a{
		left:15%;
		width:80%;
		max-width:374px;
	}
	
	/* order.html */
	.order_wrap li .left_area{
		width:40%;
		min-width:40%;
		padding-left:16px;
	}
	.order_wrap li .right_area{
		width:60%;
		flex-wrap:wrap;
		padding:20px 0;
		padding-left:16px;
	}
	.order_wrap li.how_to_pay_wrap{
		height:200px;
	}
	.order_wrap ul.customer_info_wrap li.vertical .right_area{
		flex-wrap:nowrap;
		padding-left:16px;
	}
	ul.customer_info_wrap li.vertical .right_area p{
		word-break:break-all;
	}
	ul.customer_info_wrap li.vertical .right_area p br{
		display:none;
	}
	.how_to_pay_wrap .radio_btn_area{
		margin-right:0;
		width:100%;
	}
	.customer_info_wrap .right_area input{
		width:90%;
		max-width:160px;
	}
	.customer_info_wrap .right_area input#order_customer_eamil{
		width:90%;
		max-width:220px;
	}
	

	/*download.html*/
	.download_area{
		padding-bottom:100px;
	}
	.download_area .download_wrap{
		width:90%; 
		padding:30px;
		padding-top:70px;
		border-radius:60px;
		margin-bottom:50px;
		margin-top:60px;
	}
	.download_wrap .top_top_area{
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		justify-content:center;
		align-items:center;
		flex-direction:column;
	}
	.download_wrap .left_area{
		width:100%;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		justify-content:center;
		align-items:center;
		position:relative;
	}
	.download_wrap .left_area img{
		width:90%;
		max-width:323px;
	}
	.download_wrap .left_area .download_title_wrap{
		width:100%;
	}
	.download_wrap .download_title_wrap img{
		width:100%;
		max-width:100%;
	}
	.download_wrap .left_area p{
		text-align:center;
		font-size:22px;
	}
	.download_wrap .left_area p br{
		display:none;
	}
	.download_wrap .left_area p.sub_title{
		font-size:20px;
	}
	.download_wrap .down_link_area{
		width:100%;
		height:auto;
	}
	.download_wrap .down_link_area a{
		width:46%;
	}
	.download_wrap .down_link_area img{
		width:100%;
		max-width:100%;
	}
	.download_wrap .right_area{
		width:100%;
		margin-top:40px;
	}
	.download_wrap .right_area .top_area{
		height:auto;
	}
	.download_wrap .right_area .top_area img{
		width:100%;
		height:auto;
	}
	.download_wrap .right_area .bottom_area{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.download_wrap .right_area .bottom_area img{
		width:48%;
		height:auto;
		margin:11px 1%;
	}
	.download_wrap .right_area .bottom_area img:nth-child(2){
		margin:11px 1%;
	}
	.download_wrap .right_area .bottom_area img:nth-child(5){
		margin:11px 1%;
	}
	.download_wrap .btn_area{
		margin-top:60px;
		height:100px;
	}
	.download_wrap .btn_area button{
		width:100%;
	}

	/*news.html*/
	.news_area .news_content_area{
		padding-top:60px;
	}
	.news_area .news_box{
		width:96%;
		margin:14px 2%;
	}
	.news_area .news_box .img_area{
		height:180px;
	}
	.news_area .news_box .text_area p{
		-webkit-line-clamp:2;
	}

	/* news_content.html */
	.news_more_content_article_area .article_content img{
		width:100%;
		max-width:600px;
	}
	
	/* qna.html */
	.qna_tab .inner{
		width:90%;
	}
	.qna_form li span{
		width:100px;
		padding-left:16px;
	}
	#qna_form_email_input{
		width:calc(100% - 100px);
	}
	.qna_form li.password div{
		width:calc(100% - 100px);
	}
	#qna_form_password_input{
		width:100%;
	}
	#qna_form_title_input{
		width:calc(100% - 100px);
	}
	#qna_form_content_textarea{
		width:calc(100% - 100px);
	}
	.qna_tab .line{
		width:90%;
	}
	/* 비회원 본인확인 팝업 */
	.qna_no_member_confirm_popup div{
		height:90%;
		max-height:460px;
		overflow-y:scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
	.qna_no_member_confirm_popup div::-webkit-scrollbar {
		display: none;
	}
	/* 개인정보약관 팝업 */
	.qna_privacy_popup .close_area{
		width:90%;
	}
	.qna_privacy_popup .qna_privacy_popup_wrapper{
		width:90%;
		height:90%;
		max-height:500px;
	}

	/*mypage_html*/
	/*mypage_tab_btn_area*/
	.mypage_tab_btn_area{
		height:auto;
	}
	.mypage_tab_btn_area ul{
		height:auto;
		flex-wrap:wrap;
	}
	.mypage_tab_btn_area li{
		height:40px;
		width:50%;
		padding:10px;
		margin:0;
	}
	.mypage_tab_btn_area li a{
		height:100%;
		padding:0;
	}
	.mypage_tab_btn_area li.on a{
		color:#f15a24;
		border:none;
	}
	
	/*mypage_report*/
	.my_report_top_btn_area{
		width:100%;
	}
	/* my_report_top_area */
	.my_report_top_area{
		width:100%;
	}
	.mypage_report #report_student_list_select{
		width:187px;
	}
	.my_report_top_btn_area button{
		width:100px;
	}
	/* my_report_top_area */
	.my_report_top_area button{
		width:100%;
	}
	.mypage_report .for_freeuser_info{
		width:100%;
		font-size:12px;
		text-align:center;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.mypage_report .for_freeuser_info br{
		display:block;
	}
	.my_report_missonprogress_area{
		height:auto;
		width:100%;
	}
	.my_report_missonprogress_area .head{
		height:auto;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		flex-direction:column;
		align-items:flex-start;
		justify-content:center;
		padding:20px;
	}
	.my_report_missonprogress_area .head span{
		margin-right:0;
	}
	.my_report_missonprogress_area .head p{
		margin-top:5px;
	}
	.my_report_missonprogress_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.my_report_missonprogress_area .misson_progress{
		width:100%;
		height:auto;
		border-right:none;
		border-bottom:1px solid #e6e6e6;
		padding:30px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}
	.my_report_missonprogress_area .misson_progress:last-child{
		border:none;
	}
	/* my_chart_missonprogress_area */
	.my_chart_missonprogress_area{
		height:auto;
		width:100%;
	}
	.my_chart_missonprogress_area .head{
		height:auto;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		flex-direction:column;
		align-items:flex-start;
		justify-content:center;
		padding:20px;
	}
	.my_chart_missonprogress_area .head span{
		margin-right:0;
	}
	.my_chart_missonprogress_area .head p{
		margin-top:5px;
	}
	.my_chart_missonprogress_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.my_chart_missonprogress_area .misson_progress{
		width:100%;
		height:auto;
		border-right:none;
		border-bottom:1px solid #e6e6e6;
		padding:30px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}
	.my_chart_missonprogress_area .misson_progress:last-child{
		border:none;
	}
	/* my_class_chart_missonprogress_area */
	.my_class_chart_missonprogress_area{
		height:auto;
		width:100%;
	}
	.my_class_chart_missonprogress_area .head{
		height:auto;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		flex-direction:column;
		align-items:flex-start;
		justify-content:center;
		padding:20px;
	}
	.my_class_chart_missonprogress_area .head span{
		margin-right:0;
	}
	.my_class_chart_missonprogress_area .head p{
		margin-top:5px;
	}
	.my_class_chart_missonprogress_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.my_class_chart_missonprogress_area .misson_progress{
		width:100%;
		height:auto;
		border-right:none;
		border-bottom:1px solid #e6e6e6;
		padding:30px;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}
	.my_class_chart_missonprogress_area .misson_progress:last-child{
		border:none;
	}
	.misson_progress .misson_left{
		width:100%;
		height:auto;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}
	.misson_progress img{
		margin-bottom:30px;
		width:60px;
		height:60px;
	}
	.misson_progress .misson_txt{
		width:100%;
		height:auto;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}
	.misson_progress p{
		margin-top:20px;
	}
	.my_report_study_time_area{
		width:100%;
	}
	.my_report_cognitive_ability_area{
		width:100%;
		margin-bottom:100px;
	}
	.my_report_cognitive_ability_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		padding:40px 20px;
		box-sizing:border-box;
	}
	.my_report_cognitive_ability_area .ability_area{
		width:100%;
		height:auto;
		padding:12px;
	}
	.my_report_cognitive_ability_area .ability_area:last-child{
		padding:12px;
		padding-top:32px;
	}
	.my_report_cognitive_ability_area .cognitive_ability{
		width:100%;
		margin-bottom:50px;
	}
	/* my_report_bottom_area */
	.my_report_bottom_area{
		width:100%;
		margin-bottom:100px;
	}
	.my_report_bottom_area .wrap{
		height:auto;
		-webkit-box-orient:vertical;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		padding:40px 20px;
		box-sizing:border-box;
	}
	.my_report_bottom_area .ability_area{
		width:100%;
		height:auto;
		padding:12px;
	}
	.my_report_bottom_area .ability_area:last-child{
		padding:12px;
		padding-top:32px;
	}
	.my_report_bottom_area .cognitive_ability{
		width:100%;
		margin-bottom:50px;
	}
	
	/*mypage_stuedent_info*/
	.mypage_stuedent_info .my_tit{
		margin-top:60px;
	}
	.parents_state_area>p{
		margin-left:0;
		width:100%;
		display:-webkit-box;
		-webkit-box-pack:center;
		display:flex;
		justify-content:center;
	}
	.parents_state_area{
		width:100%;
		height:auto;
		margin-top:50px;
	}
	/* 학부모 상태가 유료 일때 html에서 147,151번줄의 p태그에 class="pay" 추가 */
	.parents_state_box{
		width:100%;
		height:auto;
		padding:30px;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.parents_state_box .state_head{
		height:auto;
		width:100%;
		padding:10px;
		border:none;
		border-bottom:1px solid #e6e6e6;
	}
	.state_head .profile_wrap{
		margin-right:30px;
	}
	.parents_state_box .right_area{
		width:100%;
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.parents_state_box .state_list{
		height:auto;
		width:100%;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		-webkit-box-orient:horizontal;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		padding:20px;
		position:relative;
		padding-right:0;
	}
	.parents_state_box .state_list:nth-child(1){
		padding-left:20px;
		width:100%;
	}
	.parents_state_box .state_list:nth-child(2){
		padding-left:20px;
	}
	.parents_state_box .state_list:nth-child(3){
		padding-left:20px;
		width:100%;
	}
	.parents_state_box .state_list:nth-child(4){
		padding-left:20px;
	}
	.state_list .info_popup{
		max-width:283px;
		min-width:283px;
		left:-82%;
		padding-right:44px;
	}
	.parents_state_box .state_list div p{
		width:84%;
	}
	.parents_state_box .state_list .info_popup p{
		width:100%;
	}
	.state_list .info_popup_btn{
		margin-left:10px;
	}
	.state_list span{
		margin-bottom:0;
		width:30%;
	}
	.state_list p{
		width:70%;
	}
	.state_list p.ellip{
		width:70%;
	}
	.mypage_stuedent_info .line{
		width:80%;
	}
	.student_state_area{
		width:100%;
	}
	.student_state_box{
		width:100%;
		height:auto;
		display:-webkit-box;
		-webkit-box-pack:center;
		-webkit-box-align:center;
		display:flex;
		align-items:center;
		justify-content:center;
		padding:30px;
		box-sizing:border-box;
		-webkit-box-orient:vertical;
		flex-direction:column;
		margin-top:60px;
	}
	/*학습자 정보/관리 페이지에 처음 들어왔을때 새로운 학습자가 생성되었을때 클래스 student_state_box에 클래스 new 추가 */
	.student_state_box.new::after{
		top:calc(100% + 8px);
		left:0;
		font-size:12px;
	}
	.student_state_box .state_head{
		height:auto;
		width:100%;
		border:none;
		padding-left:0;
		padding:10px;
		border-bottom:1px solid #e6e6e6;
	}
	.student_state_box .state_head .profile_wrap{
		margin-right:20px; 
	}
	.student_state_box .state_head>span{
		text-align:left;
	}
	.student_state_box .right_area{
		width:100%;
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.student_state_box .state_list{
		height:auto;
		width:100%;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		-webkit-box-orient:horizontal;
		flex-direction:row;
		align-items:center;
		justify-content:flex-start;
		padding:20px;
		position:relative;
	}
	.student_state_box .state_list:nth-child(1){
		width:100%;
	}
	.student_state_box .state_list:nth-child(2){
		width:100%;
	}
	.student_state_box .state_list span{
		margin:0;
	}
	.student_state_box .state_list:last-child{
		width:100%;
		height:auto;
		padding-left:0;
		padding:20px;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.student_state_box .state_list:last-child button{
		margin-top:0;
		margin-left:10px;
	}
	.mypage_stuedent_info .go_price{
		width:260px;
		margin-bottom:100px;
	}
	
	/*mypage_parents_info*/
	.mypage_parents_info .my_tit{
		margin-top:60px;
	}
	.mypage_parents_info .parents_info_area{
		width:100%;
		height:auto;
		padding:20px;
	}
	.parents_info_area ul{
		height:auto;
	}
	.parents_info_area li{
		height:60px;
	}
	.parents_info_area li .head{
		display:none;
	}
	.parents_info_area .right_area{
		width:100%;
	}
	.parents_info_area p{
		padding-left:20px;
	}
	.parents_info_area p.info{
		font-size:12px;
		line-height:1.5;
		margin-top:10px;
	}
	.parents_info_area input{
		width:70%;
		max-width:257px;
		height:40px;
		padding:10px 20px;
		margin-right:4px;
		font-size:14px;
	}
	.parents_info_area input::placeholder{
		font-size:12px;
	}
	.parents_info_area li.phone_number input{
		max-width:170px;
	}
	.parents_info_area button{
		width:100px;
		height:35px;
		font-size:12px;
	}
	.parents_info_area li.base{
		height:80px;
	}
	.parents_info_area li.adress_area{
		height:214px;
	}
	.parents_info_area li.adress_area input{
		width:100%;
		max-width:100%;
	}
	.parents_info_area li.adress_area .top_area input{
		width:140px;
	}
	.parents_info_area li.sms_add{
		height:114px;
	}
	.parents_info_area li.sms_add .head{
		width:30%;
		max-width:200px;
		font-size:14px;
		position:relative;
		display:-webkit-box;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		display:flex;
		align-items:center;
		justify-content:flex-start;
	}
	.parents_info_area li.sms_add br{
		display:block;
	}
	.parents_info_area li.sms_add .right_area{
		width:70%;
		-webkit-box-orient:vertical;
		flex-direction:column;
		box-sizing:border-box;
		padding:20px 0;
	}
	.parents_info_area li.sms_add .top_area{
		display:-webkit-box;
		-webkit-box-align:center;
		-webkit-box-pack:start;
		display:flex;
		align-items:center;
		justify-content:flex-start;
		width:100%;
	}
	.parents_info_area .radio_btn_area input{
		width:20px;
		height:20px;
		margin-right:8px;
	}
	.parents_info_area .radio_btn_area input:checked::after{
		width:10px;
		height:10px;
	}
	.parents_info_area .radio_btn_area span{
		margin-right:10px;
	}
	.parents_info_area .btn_area{
		margin-top:50px;
	}
	

	/*mypage_pay_info*/
	.refund_popup div{
		height:90%;
		max-height:490px;
	}
	.mypage_pay_info .my_tit{
		margin-top:60px;
	}
	.mypage_pay_info .pay_info_area{
		width:100%;
		margin-bottom:100px;
	}
	.pay_info_list_wrap li.list_head div{
		width:100%;
	}
	.pay_info_list_wrap li.list_head div:nth-child(1){
		display:none;
	}
	.pay_info_list_wrap li.list_head div:nth-child(2){
		width:100%;
	}
	.pay_info_list_wrap li.list_head div:nth-child(3){
		display:none;
	}
	.pay_info_list_wrap li.list_head div:nth-child(4){
		display:none;
	}
	.pay_info_list_wrap li.list_head div:nth-child(5){
		display:none;
	}
	.pay_info_list_wrap li.list_head div:nth-child(6){
		display:none;
	}
	.pay_info_list_wrap .list{
		-webkit-box-orient:vertical;
		flex-direction:column;
		position:relative;
		height:150px;
	}
	.pay_info_list_wrap li.list li{
		width:auto;
		height:auto;
	}
	.pay_info_list_wrap li.list li br{
		display:none;
	}
	.pay_info_list_wrap ul.top_area{
		width:100%;
		height:50%;
		-webkit-box-orient:vertical;
		-webkit-box-align:start;
		-webkit-box-pack:center;
		align-items:flex-start;
		justify-content:center;
		flex-direction:column;
		box-sizing:border-box;
		padding:10px 10px 0 10px;
	}
	.pay_info_list_wrap ul.bottom_area{
		width:100%;
		height:50%;
		padding:0 10px 10px 10px;
		-webkit-box-align:start;
		align-items:flex-start;
	}
	.pay_info_list_wrap li.list .top_area li:nth-child(2){
		width:98%;
	}
	.pay_info_list_wrap li.list .bottom_area li:nth-child(1){
		width:auto;
	}
	.pay_info_list_wrap li.list .bottom_area li:nth-child(2){
		width:auto;
	}
	.pay_info_list_wrap li.list .bottom_area li:nth-child(3){
		width:auto; 
	}
	.pay_info_list_wrap li.list .bottom_area li:nth-child(4){
		width:auto;
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.pay_info_list_wrap .list .date{
		font-size:13px;
	}
	.pay_info_list_wrap .list .name{
		text-align:left;
		width:100%;
		white-space:nowrap;
		-webkit-line-clamp:1;
		display:block;
	}
	.pay_info_list_wrap .list .student_name{
		font-size:13px;
		margin-right:20px;
		max-width:52px;
	}
	.pay_info_list_wrap .list .fee{
		font-size:13px;
		margin-right:20px;
	}
	.pay_info_list_wrap .list .pay_condition{
		font-size:13px;
	}
	.pay_info_list_wrap .list a{
		font-size:12px;
		width:45%;
		height:32px;
		position:absolute;
		bottom:14px;
		margin:0;
		left:3%;
	}
	.pay_info_list_wrap .list button{
		font-size:12px;
		width:45%;
		height:32px;
		position:absolute;
		bottom:14px;
		margin:0;
		left:52%;
	}
	.list_pagebtn_area li{
		margin:0 5px;
	}
	
	/*mypage_pay_info_more*/
	.mypage_pay_info_more .my_tit{
		margin-top:60px;
	}
	.mypage_pay_info_more .pay_info_more_area{
		width:100%;
		margin-bottom:100px;
	}
	.pay_info_more_area ul{
		width:100%;
		box-sizing:border-box;
		padding:0 15px;
	}
	.pay_info_more_area li{
		height:100px;
		position:relative;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.pay_info_more_area .head{
		width:30%;
		min-width:95px;
		max-width:326px;
		padding-left:0px;
	}
	.pay_info_more_area p{
		width:60%;
		min-width:160px;
		max-width:484px;
	}
	.pay_info_more_area li.pay_more_refund div{
		width:60%;
	}
	.pay_info_more_area li.pay_more_refund p.info{
		width:100%;
	}
	.pay_info_more_area li.info_txt div{
		width:60%;
	}
	.pay_info_more_area li.info_txt div p{
		min-width:auto;
		margin-right:8px;
	}
	.pay_info_more_area li.info_txt .fake_account_number_copy{
		padding:2px 6px;
		font-size:12px;
	}
	.pay_info_more_area li.info_txt div p.info{
		width:100%;
		min-width:160px;
		margin-right:0;
	}
	.pay_info_more_area a{
		margin-top:50px;
	}

	/*mypage_student_register*/
	.mypage_student_register .my_tit{
		margin-top:60px;
	}
	.mypage_student_register .student_register_area{
		width:100%;
		height:auto;
		padding:15px;
	}
	.student_register_area li:first-child{
		height:146px;
	}
	.student_register_area li .head{
		height:100%;
		width:32%;
		min-width:92px;
		font-size:13px;
		padding-left:0;
	}
	.student_register_area .right_area{
		width:65%;
		min-width:166px;
	}
	.student_register_area p.info{
		margin-top:10px;
	}

	.student_register_area input{
		width:80%;
		max-width:190px;
		height:50px;
		padding:10px 20px;
		font-size:15px;
	}
	.student_register_area .radio_btn_area span{
		margin-right:30px;
	}
	.student_register_area select{
		width:110px;
		height:45px;
		padding:5px 10px;
		margin-left:10px;
		font-size:14px;
	}
	#my_register_product_list_select{
		width:100%;
		max-width:295px;
		padding-right:32px;
	}
	
	/*mypage_student_infofix*/
	.mypage_student_infofix .student_register_area li.infofix_vertical{
		margin-top:0px;
		height:128px;
	}
	.mypage_student_infofix .student_register_area li:last-child{
		margin-bottom:0px;
	}
	.mypage_student_infofix .student_register_area li.infofix_vertical .right_area span{
		font-size:14px;
	}
	.mypage_student_infofix .student_register_area li.infofix_vertical .right_area span:last-child{
		font-size:12px;
	}

	/*mypage_coupon*/
	.mypage_coupon .my_tit{
		margin-top:60px;
	}
	.mypage_coupon .coupon_title_info{
		font-size:14px;
		margin-top:8px;
	}
	#coupon_register_input{
		width:80%;
		max-width:420px;
	}
	.coupon_register_area button{
		/* width:70px; */
		width: auto;
		padding-left: 5px;
		padding-right: 5px;
	}
	.mypage_coupon .coupon_list_area{
		width:100%;
		margin-bottom:100px;
	}
	.coupon_list_wrap li.list_head div{
		display:none;
	}
	.coupon_list_wrap li.list_head .coupon_name{
		width:100%;
		display:-webkit-box;
		display:flex;
	}
	.coupon_list_wrap .list{
		height:auto;
		-webkit-box-orient:vertical;
		flex-direction:column;
		box-sizing:border-box;
		padding:8px 12px;
	}
	.coupon_list_wrap .list .top_area{
		width:100%;
		height:auto;
		-webkit-box-pack:start;
		justify-content:flex-start;
		margin-top:12px;
	}
	.coupon_list_wrap .list .coupon_name{
		max-width:calc(100% - 54px);
	}
	.coupon_list_wrap .list .middle_area{
		width:100%;
		height:auto;
		-webkit-box-pack:start;
		justify-content:flex-start;
		margin-top:6px;
	}
	.coupon_list_wrap .list .coupon_use_date{
		width:auto;
		height:auto;
		-webkit-box-pack:start;
		justify-content:flex-start;
		margin-right:8px;
	}
	.coupon_list_wrap .list .coupon_use_date span{
		font-size:13px;
	}
	.coupon_list_wrap .list .coupon_state{
		width:auto;
		height:auto;
		-webkit-box-pack:start;
		justify-content:flex-start;
	}
	.coupon_list_wrap .list .coupon_state span{
		font-size:13px;
	}
	.coupon_list_wrap .list .bottom_area{
		width:100%;
		height:auto;
		margin-top:12px;
	}
	.coupon_list_wrap .list a{
		font-size:12px;
		width:100%;
		height:32px;
	}
	.coupon_info_area .bottom_area{
		padding:5px 4px;
	}
	.coupon_info_area .bottom_area li{
		margin:5px 0;
	}

	/* my_qna_info.html */
	.mypage_qna_info .my_tit{
		margin-top:60px;
	}
	.mypage_qna_info .qna_info_area{
		width:100%;
	}
	.qna_info_list_wrap li.list_head div{
		display:none;
	}
	.qna_info_list_wrap li.list_head .qna_title{
		display:flex;
		width:100%;
	}
	.qna_info_list_wrap .list{
		height:auto;
		box-sizing: border-box;
		padding:10px;
	}
	.qna_info_list_wrap .list .number_area{
		display:none;
	}
	.qna_info_list_wrap .list .right_area{
		width:100%;
		-webkit-box-orient:vertical;
		-webkit-box-align:start;
		align-items:flex-start;
		flex-direction:column;
	}
	.qna_info_list_wrap .list .top_area{
		width:100%;
		height:auto;
		-webkit-box-pack:start;
		justify-content: flex-start;
		margin-top:12px;
	}
	.qna_info_list_wrap .list .middle_area{
		width:100%;
		height:auto;
		-webkit-box-pack:start;
		justify-content: flex-start;
		margin-top:6px;
	}
	.qna_info_list_wrap .list .name{
		width:auto;
		height:auto;
		-webkit-box-pack:start;
		justify-content: flex-start;
	}
	.qna_info_list_wrap .list .name span{
		max-width:62px;
		font-size:13px;
	}
	.qna_info_list_wrap .list .date{
		width:auto;
		height:auto;
		-webkit-box-pack:start;
		justify-content: flex-start;
		margin-left:8px;
		font-size:13px;
	}
	.qna_info_list_wrap .list .state{
		width:auto;
		height:auto;
		-webkit-box-pack:start;
		justify-content: flex-start;
		margin-left:12px;
		font-size:13px;
	}
	.qna_info_list_wrap .list .bottom_area{
		width:100%;
		height:32px;
		margin-top:12px;
	}
	.qna_info_list_wrap .list a{
		width:100%;
		height:100%;
		font-size:12px;
	}


	/* 자주묻는질문 */
	.faq_tab .faq_area{
		width:100%;
	}
	.faq_tab .faq_txt{
		text-align:center;
		font-size:13px;
	}
	#faq_srch_select{
		width:80px;
		padding:0 4px;
		font-size:13px;
	}
	.faq_srch_area #faq_input_id{
		width:240px;
		font-size:15px;
	}
	.faq_categori_area #faq_categori_select{
		font-size:15px;
	}
	.faq_list_wrap .question_area{
		padding:20px;
	}
	.faq_list_wrap .question_area span{
		margin-right:20px;
	}
	.faq_list_wrap .question_area p{
		width:82%;
		margin-right:20px;
	}
	.faq_list_wrap .answer_area{
		padding:30px;
	}

	/* 404 not found */
	.not_found_area img{
		width:260px;
	}
	.not_found_area p{
		font-size:24px;
	}
	.not_found_area a{
		width:180px;
		margin-top:34px;
	}

}
/* 타블렛 가로 */
@media screen and (min-width:768px) and (max-width:1199px) and (orientation: landscape){
	
	section.footer_section{
		padding-top:30px;
		padding-bottom:30px;
	}
	.f_left ul{
		padding-left:0;
	}
	.f_right{
		margin-top:20px;
	}
	.f_right li.faq a{
		font-size:26px;
	}
	.f_right li.inquiry{
		font-size:14px;
	}
	
	/*index.html*/
	/*main_banner_area */
	.bn_content_wrap{
		padding-top:5vh;
		padding-bottom:5vh;
	}
	.bn_content_wrap .inner90{
		-webkit-box-pack:center;
		-webkit-box-orient:horizontal;
		justify-content:center;
		flex-direction:row;
	}
	/* 오픈세일배너 */
	.bn.sale{
		background:url(../assets/sale_bn_bg.png) no-repeat center;
		background-size:cover;
	}
	.bn.sale .bn_content_wrap .inner90{
		-webkit-box-pack:center;
		-webkit-box-orient:vertical;
		justify-content:center;
		flex-direction:column;
	}
	.bn.sale .txt1{
		width:auto;
		height:7%;
	}
	.bn.sale .txt2{
		width:auto;
		height:30%;
		margin-top:2%;
		margin-bottom:2%;
	}
	/* 인기브랜드대상배너 */
	.bn.popular .inner90{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.bn.popular .txt{
		height:20%;
		margin-top:5%;
	}
	.bn.popular .tropy{
		height:50%;
	}
	/* 수학섬 업데이트 배너 */
	.bn.mathupdate{
		background:url(../assets/math_bg.png) no-repeat center;
		background-size:cover;
	}
	.bn.mathupdate .bn_content_wrap .inner90{
		-webkit-box-pack:center;
		-webkit-box-orient:vertical;
		justify-content:center;
		flex-direction:column;
	}
	.bn.mathupdate .txt1{
		position: absolute;
		z-index: 2;
		width: 50%;
		height:auto;
		/* margin-top:-16%; */
		margin-left: -2%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}
	.bn.mathupdate .txt2{
		position: relative;
		z-index: 1;
		width: 70%;
		height:auto;
		margin-top:10%;
		-webkit-animation-delay:.3s;
		animation-delay:.3s;
	}
	.bn.mathupdate .button{
		position: absolute;
		z-index: 2;
		width: auto;
		margin-top:17%;
		margin-left:-4%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}
	
	/*introduce_area*/
	.introduce_area .top_area{
		-webkit-box-direction:normal;
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.introduce_area .top_area img{
		height:40vh;
		margin-right:4vw;
	}
	.introduce_area .top_area .right_area{
		-webkit-box-align:starat;
		align-items:flex-start;
		margin-bottom:0;
	}
	.introduce_area .right_area p{
		text-align:left;
	}
	.introduce_area .right_area p br{
		display:block;
	}
	.introduce_area .ym_who_popup.on{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}

	/*ym_world_area*/
	.ym_world_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.ym_world_area p.txt br{
		display:block;
	}
	.ym_world_area .bottom_area{
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
		align-items:center;
	}
	.ym_world_area .right_area{
		height:20vh;
		width:30vw;
		margin-left:3vw;
		top:0;
	}
	.ym_world_area .building_wrap{
		width:100%;
		height:100%;
	}

	/*ym_difference_area*/
	.ym_difference_area .middle_area{
		margin-top:4vh;
	}

	/*ym_myroom_area*/
	.ym_myroom_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.ym_myroom_area .bottom_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
		margin-top:4vh;
	}
	.ym_myroom_area .box{
		width:25vw;
		height:auto;
		margin:0 1vw;
	}
	.ym_myroom_area .box.on{
		margin:0 2vw;
	}
	.ym_myroom_area .content .graph_area3 .counting{
		font-size:1em;
	}
	.ym_myroom_area .content .graph_area3 .count1{
		top:55%;
	}
	.ym_myroom_area .content .graph_area3 .count2{
		top:55%;
	}
	.ym_myroom_area .content .graph_area3 .count3{
		top:55%;
	}
	.ym_myroom_area .content .graph_area1_graph{
		width:75%;
		position:relative;
		top:-8%;
	}

	/*brain_music_area*/
	.brain_music_area{
		background:url(../assets/뮤직룸.png) no-repeat center;
		background-size:cover;
	}
	.brain_music_area .inner90{
		-webkit-box-pack:start;
		justify-content:flex-start;
	}
	.brain_music_area .wrap{
		-webkit-box-align:start;
		align-items:flex-start;
	}
	.brain_music_area .wrap ul{
		width:100%;
	}
	.brain_music_area .wrap li{
		padding:1vh 2vw;
	}
	.brain_music_area .wrap li .play_wrap{
		margin-right:2vw;
	}

	/*coding_block_area*/
	.coding_block_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.coding_block_area .bottom_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
		height:auto;
	}
	.coding_block_area .cont .num{
		height:4.5vh;
	}
	.coding_block_area .cont img:last-child{
		display:none;
	}

	/*yf_area*/
	.yf_area .content_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.yf_area .content_area .btn_area{ 
		width:20%;
		height:22vh;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.yf_area .content_area .btn_area li{
		margin:1vh 0;
		height:20%;
		position:relative;
	}
	.yf_area .content_area .btn_area li.on{
		height:20%;
	}
	.yf_area .content_area .btn_area li.on:hover{
		height:20%;
	}
	.yf_area .content_area .btn_area li img{
		height:70%;
	}
	.yf_area .content_area .btn_area li.on img{
		height:100%;
	}
	.yf_area .yf_info_area{
		width:50%;
	} 

	/* yame_sns_area */
	.yame_sns_area{
		padding-bottom:10vh;
	}
	.yame_sns_area .yame_sns_title_area{
		height:52px;
	}
	.yame_sns_title_area span{
		font-size:1.45em;
	}
	.yame_youtube_area a{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.yame_youtube_area img{
		margin-right:32px;
	}
	.yame_youtube_area .right_area{
		margin-top:0;
		-webkit-box-align:start;
		align-items:flex-start;
		width:auto;
	}
	.yame_youtube_area .top_area{
		width:auto;
		-webkit-box-pack:start;
		justify-content:flex-start;
	}
	.yame_youtube_area p{
		text-align:left;
		width:auto;
	}

}
/* 모바일 가로 */
@media screen and (min-width:320px) and (max-width:1024px) and (orientation: landscape){
	
	
	/* header */
	.mobile_menu_area{
		width:50%;
	}
	.mobile_menu_wrap .mobile_menu_top_area{
		height:50px;
	}
	.mobile_menu_wrap .mobile_menu_top_area img{
		height:32px;
	}
	.mobile_my_area .my_wrap .top_area{
		height:50px;
	}
	.mobile_my_area .my_wrap .left_area{
		height:32px;
	}
	.mobile_my_area .my_wrap .bottom_area{
		height:32px;
	}
	.mobile_menu_wrap .bottom_menu_area{
		display:-webkit-box;
		display:flex;
		flex-wrap:wrap;
	}
	.mobile_menu_wrap .bottom_menu_area a{
		height:40px;
		width:50%;
		font-size:13px;
	}
	
	/* footer */
	section.footer_section{
		padding-top:30px;
		padding-bottom:30px;
	}
	.footer .inner90{
		width:90%;
		-webkit-box-direction:normal;
		-webkit-box-orient:horizontal;
		-webkit-box-align:start;
		flex-direction:row;
		align-items:flex-start;
	}
	.f_left{
		width:75%;
	}
	.f_right{
		width:25%;
		margin-top:20px;
		box-sizing:border-box;
		padding-left:10px;
	}
	.f_right ul{
		margin-top:0;
		-webkit-align:start;
		align-items:flex-start;
	}
	.f_right li:nth-child(3){
		text-align:center;
	}
	.f_right li.faq a{
		font-size:20px;
	}
	.f_right li.inquiry{
		font-size:14px;
	}
	.f_left ul{
		-webkit-box-align:start;
		align-items:flex-start;
		padding-left:0;
	}
	.f_left li{
		text-align:left;
	}
	.f_left li:nth-child(1){
		margin-bottom:15px;
		margin-left:64px;
		display:none;
	}
	.f_left li:nth-child(3){
		margin-bottom:16px;
	}
	.f_left li br{
		display:none
	}
	
	/* 공지팝업 */
	.notice_popup{
		padding:10px 20px;
	}
	.notice_popup p{
		display:none;
	}
	.notice_popup p.mobile_horizontal{
		display:block;
	}

	/*index.html*/
	.index_tit{
		font-size:1.25em;
	}
	/*main_banner_area */
	.bn_content_wrap{
		padding-top:2vh;
		padding-bottom:5vh;
	}
	.bn_content_wrap .inner90{
		-webkit-box-pack:center;
		-webkit-box-orient:horizontal;
		justify-content:center;
		flex-direction:row;
	}
	.bn.open .top_area span{
		font-size:.9em;
	}
	.bn.open .top_area span.title{
		font-size:2em;
	}
	.bn.open .top_area p{
		font-size:.6em;
		margin-top:20px;
	}
	.bn.open .top_area p.input_info{
		font-size:.6em;
		margin-top:10px;
	}
	.bn.open .open_email_input_area{
		height:32px;
	}
	.bn.open #banner_email_input{
		font-size:.8em;
	}
	.bn.open .open_email_input_area button{
		font-size:.8em;
	}
	.bn_btn_wrap{
		top:75%;
	}
	/* 오픈세일배너 */
	.bn.sale .bn_content_wrap .inner90{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.bn.sale .txt1{
		width:auto;
		height:8%;
	}
	.bn.sale .txt2{
		width:auto;
		height:39%;
		margin-top:1%;
		margin-bottom:0;
	}
	.bn.sale .bottom_coin_area{
		display:none;
	}
	.bn.sale .coin1.bounceIn{
		height:17%;
		top:22%;
		left:-32%;
	}
	.bn.sale .coin2.bounceIn{
		height:8%;
		top:75%;
		left:-32%;
	}
	.bn.sale .coin3.bounceIn{
		height:11%;
		top:8%;
		left:-31%;
	}
	.bn.sale .coin4.bounceIn{
		height:5%;
		top:43%;
		left:-34%;
	}
	.bn.sale .coin5.bounceIn{
		height:7%;
		top:21%;
		left:31%;
	}
	.bn.sale .coin6.bounceIn{
		height:9%;
		top:74%;
		left:28%;
	}
	.bn.sale .coin7.bounceIn{
		height:4%;
		top:41%;
		left:30.5%;
	}
		
	/* 인기브랜드대상배너 */
	.bn.popular{
		background:url(../assets/popular_bn_bg_mobile_row.jpg) no-repeat center;
		background-size:cover;
	}
	.bn.popular .inner90{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.bn.popular .txt{
		width:60%;
		height:auto;
	}
	.bn.popular .tropy{
		width:25%;
		height:auto;
		margin-top:0;
	}
	.bn.popular .top .star2{
		display:block;
		top:76%;
		left:-47%;
	}
	.bn.popular .top .star4{
		top:93%;
		left:45%;
	}

	/* 수학섬 업데이트 배너 */
	.bn.mathupdate .bn_content_wrap .inner90{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.bn.mathupdate .txt1{
		position: absolute;
		z-index: 2;
		width: 30%;
		height:auto;
		margin-top:-10;
		margin-left: -2%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}
	.bn.mathupdate .txt2{
		position: relative;
		z-index: 1;
		width: 70%;
		height:auto;
		margin-top:10%;
		-webkit-animation-delay:.3s;
		animation-delay:.3s;
	}
	.bn.mathupdate .button{
		position: absolute;
		z-index: 2;
		width: auto;
		margin-top:17%;
		margin-left:-4%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}
	
	/*introduce_area*/
	.introduce_area{
		padding-top:0;
		padding-bottom:5vh;
	}
	.introduce_area .top_area{
		-webkit-box-direction:normal;
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.introduce_area .top_area img{
		height:40vh;
		margin-right:4vw;
	}
	.introduce_area .top_area .right_area{
		-webkit-box-align:starat;
		align-items:flex-start;
		margin-bottom:0;
	}
	.introduce_area .right_area p{
		text-align:left;
	}
	.introduce_area .right_area p br{
		display:block;
	}
	.introduce_area .ym_who_popup.on{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.introduce_area .ym_who_btn{
		margin-top:3vh;
	}
	.introduce_area .ym_who_btn span{
		font-size:13px;
	}

	/*ym_world_area*/
	.ym_world_area{
		padding-top:0;
		padding-bottom:5vh;
	}
	.ym_world_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.ym_world_area p.txt br{
		display:block;
	}
	.ym_world_area .bottom_area{
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
		align-items:center;
	}
	.ym_world_area .right_area{
		height:30vh;
		width:50%;
		margin-left:3vw;
		top:0;
	}
	.ym_world_area .building_wrap{
		width:100%;
		height:100%;
	}

	/*ym_difference_area*/
	.ym_difference_area{
		padding-top:0;
		padding-bottom:5vh;
	}
	.ym_difference_area .middle_area{
		margin-top:4vh;
		height:25vh;
	}
	.ym_difference_area .img_area{
		display:none;
	}

	/*ym_myroom_area*/
	.ym_myroom_area{
		padding-top:0;
		padding-bottom:5vh;
	}
	.ym_myroom_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.ym_myroom_area .bottom_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.ym_myroom_area .box{
		width:22vw;
		height:auto;
		margin:0 1vw;
	}
	.ym_myroom_area .box.on{
		margin:0 2vw;
	}
	.ym_myroom_area .content .graph_area3 img{
		width:80%;
	}
	.ym_myroom_area .content .graph_area3 .counting{
		font-size:.8em;
	}
	.ym_myroom_area .content .graph_area3 .count1{
		top:62%;
		left:8%;
	}
	.ym_myroom_area .content .graph_area3 .count2{
		top:62%;
	}
	.ym_myroom_area .content .graph_area3 .count3{
		top:62%;
		left:67%;
	}
	.ym_myroom_area .graph_area1 .wrap{
		width:53%;
	}
	.ym_myroom_area .content .graph_area1_graph{
		width:75%;
		position:relative;
		top:-8%;
	}
	.ym_myroom_area .content .bottom_area{
		height:48%;
	}

	/*brain_music_area*/
	.brain_music_area{
		padding-top:0;
		padding-bottom:15vh;
	}
	.brain_music_area .index_tit{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.brain_music_area .wrap{
		width:64%;
	}
	.brain_music_area .wrap ul{
		width:100%;
	}
	.brain_music_area .wrap li{
		padding:1vh 2vw;
	}
	.brain_music_area .wrap li .play_wrap{
		margin-right:2vw;
	}

	/*coding_block_area*/
	.coding_block_area{
		padding-top:0;
		padding-bottom:10vh;
	}
	.coding_block_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.coding_block_area .bottom_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
		height:auto;
	}
	.coding_block_area .cont .num{
		height:4.5vh;
	}
	.coding_block_area .cont img:last-child{
		display:none;
	}

	/*yf_area*/
	.yf_area{
		padding-top:0;
		padding-bottom:10vh;
	}
	.yf_area .content_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.yf_area .content_area .btn_area{ 
		width:20%;
		height:36vh;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.yf_area .content_area .btn_area li{
		margin:1vh 0;
		height:20%;
		position:relative;
	}
	.yf_area .content_area .btn_area li.on{
		height:20%;
	}
	.yf_area .content_area .btn_area li.on:hover{
		height:20%;
	}
	.yf_area .content_area .btn_area li img{
		height:70%;
	}
	.yf_area .content_area .btn_area li.on img{
		height:100%;
	}
	.yf_area .yf_info_area{
		width:50%;
	} 

	/* yame_sns_area */
	.yame_sns_area{
		padding-top:0;
		background-size:100% auto;
	}
	.yame_sns_area .yame_sns_title_area{
		box-sizing:border-box;
		padding-left:50px;
		height:40px;
	}
	.yame_sns_title_area span{
		font-size:1.25em;
	}
	.yame_sns_area .yame_youtube_area{
		padding:0 56px 0;
		margin-top:0;
	}
	.yame_youtube_area a{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.yame_youtube_area img{
		margin-right:32px;
		height:100px;
	}
	.yame_youtube_area .right_area{
		margin-top:0;
		-webkit-box-align:start;
		align-items:flex-start;
		width:auto;
	}
	.yame_youtube_area .top_area{
		width:auto;
		-webkit-box-pack:start;
		justify-content:flex-start;
	}
	.yame_youtube_area span{
		font-size:1.25em;
	}
	.yame_youtube_area p{
		text-align:left;
		font-size:16px;
		width:auto;
	}
	.yame_youtube_area a{
		padding-bottom:8px;
	}
	.yame_sns_area .yame_cafe_area{
		width:calc(100% - 112px);
		-webkit-box-orient:horizontal;
		-webkit-box-pack:justify;
		flex-direction:row;
		justify-content:space-between;
		padding:6px 20px;
		margin-top:10px;
	}
	.yame_cafe_area .cafe_top_area{
		width:calc(100% - 122px);
	}
	.yame_cafe_area span{
		font-size:16px;
	}
	.yame_cafe_area p{
		font-size:12px;
	}
	.yame_cafe_area a{
		width:102px;
		margin-top:0;
	}

}

/* 모바일 가로 - 기기의 세로가 태블릿의 범주일때 틀어짐 방지 (헤더/푸터/메인페이지) */
@media screen and (min-device-width:320px) and (max-device-width:1024px) and (orientation: landscape){
	
	/* 모바일 세로 미디어쿼리 + 모바일 가로 미디어 쿼리 */
	
	.inner{
		width:90%;
	}
	
	/*header*/
	.header .inner{
		width:90%;
	}
	.header .logo{
		margin:0 auto;
		margin-top:12px;
		height:44px;
	}
	.menu_wrapper{
		display:none;
	}
	/*login&mypage*/
	.header .button{
		display:none; 
	}
	/*mobile_menu_btn_area*/
	.mobile_menu_btn_area{
		display:block; 
	}
	.mobile_menu_area{
		width:70%;
	}
	
	
	/*footer*/
	section.footer_section{
		height:auto;
		background:#1a1a1a;
		padding-top:0;
		padding-bottom:0;
		top:0;
		position:relative;
	}
	.footer{
		height:auto;
	}
	.footer .inner90{
		width:90%;
		-webkit-box-direction:reverse;
		-webkit-box-orient:vertical;
		flex-direction:column-reverse;
	}
	.f_left{
		width:100%;
		height:auto;
		margin:20px 0;
		margin-top:30px;
	}
	.f_left ul{
		-webkit-box-align:center;
		align-items:center;
		padding-left:0;
	}
	.f_left li{
		text-align:center;
	}
	.f_left li:nth-child(1){
		margin-bottom:16px;
	}
	.f_right{
		width:100%;
		height:auto;
	}
	.f_right ul{
		-webkit-box-align:center;
		align-items:center;
		margin-top:40px;
	}
	.f_right li.faq a{
		font-size:32px;
	}
	
	/*index.html*/
	/* 공지팝업 */
	.notice_popup_bg{
		display:none;
		position:fixed;
		top:30%;
		left:5%;
		z-index:130;
	}
	.notice_popup_area{
		top:50%;
		left:50%;
		-webkit-transform:translate(-50%,-50%);
		transform:translate(-50%,-50%);
	}
	.stakato::before{
		width:.5vh;
		height:.5vh;
		top:-.7vh;
	}
	.index_tit .stakato::before{
		width:.8vh;
		height:.8vh;
		top:-.3vh;
	}
	.sub_title{
		font-size:.8em;
	}
	p.txt{
		font-size:12px;
	}
	section{
		height:100vh;
		position:fixed;
		top:100vh;
	}
	/*main_banner_area */
	.bn_content_wrap{
		padding-top:80px;
		padding-bottom:16vh;
	}
	.bn.open .top_area span.title{
		font-size:3em;
	}
	.bn.open .top_area p{
		font-size:.9em;
	}
	.bn.open .top_area span.strong{
		font-size:1.7em;
	}
	.bn.open .top_area p.input_info{
		text-align:center;
		line-height:1.6;
	}
	.bn.open .top_area p.input_info br{
		display:block;
	}
	.bn.open .open_email_input_area{
		width:94%;
	}
	.bn.open #banner_email_input{
		width:80%;
		max-width:343px;
	}
	.bn.open .img_area{
		margin-top:10px;
	}
	.bn.open .img_area .base img{
		width:95%;
	}
	.bn.open .img_area .top img{
		width:52%;
		margin-top:12%;
	}
	.bn.open .img_area .bottom .bounceIn .on img{
		width:30.5%;
		height:auto;
		margin-top:6%;
		-webkit-animation-delay:.8s;
		animation-delay:.8s;
	}
	.bn_btn_wrap{
		top:84%;
	}
	/* 오픈세일배너 */
	.bn.sale{
		background:url(../assets/sale_bn_bg_mobile.png) no-repeat center;
		background-size:cover;
	}
	.bn.sale .bn_content_wrap .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.bn.sale .bottom_coin_area{
		background:url(../assets/sale_bn_bottom_coin_mobile.png) no-repeat center;
		background-size:cover;
	}
	.bn.sale .txt1{
		width:96%;
		height:auto;
		margin-top:0;
	}
	.bn.sale .txt2{
		width:60%;
		height:auto;
		margin-top:26px;
		margin-bottom:32px;
	}
	.bn.sale .coin1.bounceIn{
		display:none;
	}
	.bn.sale .coin2.bounceIn{
		height:5%;
		top:89%;
		left:0;
	}
	.bn.sale .coin3.bounceIn{
		height:11%;
		top:4%;
		left:-15%;
	}
	.bn.sale .coin4.bounceIn{
		height:5%;
		top:59%;
		left:-42%;
	}
	.bn.sale .coin5.bounceIn{
		height:7%;
		top:8%;
		left:31%;
	}
	.bn.sale .coin6.bounceIn{
		height:7%;
		top:77%;
		left:16%;
	}
	.bn.sale .coin7.bounceIn{
		height:4%;
		top:41%;
		left:18%;
	}
	.bn.sale .coin8.bounceIn{
		display:none;
	}
	.bn.sale .coin9.bounceIn{
		display:none;
	}
	.bn.sale .coin10.bounceIn{
		display:none;
	}
	/* 인기브랜드대상배너 */
	.bn.popular{
		background:url(../assets/popular_bn_bg_mobile.png) no-repeat center;
		background-size:cover;
	}
	.bn.popular .txt{
		height:auto;
		width:110%;
	}
	.bn.popular .tropy{
		height:auto;
		width:77%;
		margin-top:10px;
	}
	.bn.popular .top .star1{
		height:12%;
		top:53%;
		left:-35%;
	}
	.bn.popular .top .star2{
		display:none;
	}
	.bn.popular .top .star3{
		height:19%;
		top:2%;
		left:37%;
	}
	.bn.popular .top .star4{
		height:12%;
		top:91%;
		left:38%;
	}
	.bn.popular .top .star5{
		display:none;
	}
	.bn.popular .top .star6{
		display:none;
	}
	.bn.popular .bottom .star1{
		display:none;
	}
	.bn.popular .bottom .star2{
		display:none;
	}
	.bn.popular .bottom .star3{
		height:18%;
		top:-54%;
		left:-40%;
	}
	.bn.popular .bottom .star4{
		height:14%;
		top:-1%;
		left:-41%;
	}
	.bn.popular .bottom .star5{
		display:none;
	}
	.bn.popular .bottom .star6{
		height:12%;
		top:-12%;
		left:23%;
	}
	.bn.popular .bottom .star7{
		display:none;
	}
	/* 수학섬 업데이트 배너 */
	.bn.mathupdate{
		background:url(../assets/math_bg.png) no-repeat center;
		background-size:cover;
	}
	.bn.mathupdate .bn_content_wrap .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.bn.mathupdate .txt1{
		position: absolute;
		z-index: 2;
		height:35%;
		margin-top:6%;
		margin-left: -2%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}
	.bn.mathupdate .txt2{
		position: relative;
		z-index: 1;
		height:70%;
		margin-top:10%;
		-webkit-animation-delay:.3s;
		animation-delay:.3s;
	}
	.bn.mathupdate .button{
		position: absolute;
		z-index: 2;
		width: auto;
		margin-top:17%;
		margin-left:-4%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}


	/*introduce_area*/
	.introduce_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.introduce_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.introduce_area .top_area{
		height:auto;
		-webkit-box-direction:reverse;
		-webkit-box-orient:vertical;
		flex-direction:column-reverse;
	}
	.introduce_area .top_area img{
		height:30vh;
		margin-right:0;
	}
	.introduce_area .top_area .right_area{
		display:-webkit-box;
		-webkit-box-align:Center;
		-webkit-box-orient:vertical;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-bottom:2.5vh;
	}
	.introduce_area .right_area p{
		font-size:12px;
		text-align:center;
		margin-top:1.5vh;
	}
	.introduce_area .right_area p br{
		display:none;
	}
	.introduce_area span.index_tit{
		text-align:center;
		margin-top:1vh;
	}
	.introduce_area .ym_who_popup.on{
		width:100%;
		height:104%;
		padding:4vmin;
		-webkit-box-orient:vertical;
		flex-direction:column;
		border-radius:30px;
	}
	.introduce_area .ym_who_popup.on .ym_wyo_box{
		width:100%;
		margin-top:3vh;
	}
	.ym_who_popup.on .ym_wyo_box:first-child{
		width:100%;
		margin:0;
	}
	.ym_who_popup.on .ym_wyo_box:nth-child(2){
		width:100%;
		margin:0;
		margin-top:3vh;
	}
	.ym_who_popup.on .ym_wyo_box span{
		font-size:16px;
	}
	.ym_who_popup.on .ym_wyo_box p{
		margin-top:1vh;
		font-size:12px;
	}
	.introduce_area .ym_who_btn{
		width:auto;
		height:auto;
		box-sizing:border-box;
		padding:1vh 15vmin;
		margin-top:6vh;
	}
	.introduce_area .ym_who_btn span{
		font-size:14px;
	}

	/*ym_world_area*/
	.ym_world_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.ym_world_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_world_area .index_tit{
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		flex-direction:column;
		align-items:center;	
	}
	.ym_world_area .index_tit .br{
		margin-right:0;
	}
	.ym_world_area p.txt{
		margin-top:1vh;
	}
	.ym_world_area p.txt br{
		display:none;
	}
	.ym_world_area .bottom_area{
		height:auto;
		width:100%;
		margin-top:1.5vh;
		-webkit-box-orient:vertical;
		-webkit-box-direction:reverse;
		flex-direction:column-reverse;
	}
	.ym_world_area .left_area{
		height:30vh;
		margin-left:0;
		margin-top:3.5vh;
	}
	.ym_world_area .left_area .loby{
		height:135%;
		margin-top:0;
	}
	.ym_world_area .plus_wrap .plus{
		width:3vh;
		height:3vh;
	}
	.ym_world_area .plus:nth-child(1){
		top:16%;
		left:34%;
	}
	.ym_world_area .plus:nth-child(2){
		top:-10%;
		left:37%;
	}
	.ym_world_area .plus:nth-child(3){
		top:-16%;
		left:58%;
	}
	.ym_world_area .plus:nth-child(4){
		top:-15%;
		left:69%;
	}
	.ym_world_area .plus:nth-child(5){
		top:11%;
		left:46%;
	}
	.ym_world_area .plus:nth-child(6){
		top:-11%;
		left:35%;
	}
	.ym_world_area .right_area{
		height:auto;
		margin-left:0;
		top:1.5vh;
	}
	.ym_world_area .building_wrap{
		width:80%;
		height:14vmax;
		padding:0;
		border:2px dashed #ffffff;
		border-radius:30px;
	}
	.ym_world_area .building{
		padding:1.5vh;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_world_area .building img{
		display:none;
	}
	.ym_world_area .building span{
		font-size:13px;
		margin-bottom:1vh;
		padding:0 2vw;
	}
	.ym_world_area .building p{
		font-size:12px;
	}
	.ym_world_area .building p br{
		display:none;
	}

	/*ym_difference_area*/
	.ym_difference_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.ym_difference_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_difference_area .tabbtn_area .btn:first-child{
		margin-right:4.5vw;
	}
	.ym_difference_area .tabbtn_area .btn{
		font-size:14px;
	}
	.ym_difference_area .tabbtn_area .btn::before{
		margin-right:1vw;
	}
	.ym_difference_area .middle_area p{
		font-size:12px;
	}
	.ym_difference_area .img_area{
		height:27vmin;
		margin-top:6vh;
	}

	/*ym_myroom_area*/
	.ym_myroom_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.ym_myroom_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.ym_myroom_area .index_tit{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.ym_myroom_area .index_tit .br{
		margin-right:0;
	}
	.ym_myroom_area p.txt br{
		display:none;
	}
	.ym_myroom_area .bottom_area{
		margin-top:2vh;
		-webkit-box-orient:vertical;
		flex-direction:column;
		width:100%;
		height:auto;
	}
	.ym_myroom_area .box{
		-webkit-transform:scale(1);
		transform:scale(1);
		top:0;
		width:65%;
		height:5vh;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		-webkit-transition:all .3s;
		transition:all .3s;
		border-radius:30px;
		margin:3px 0;
	}
	.ym_myroom_area .box.on{
		-webkit-transform:scale(1);
		transform:scale(1);
		top:0;
		height:auto;
		box-shadow:0 0 10px 0 rgb(0,0,0,.35);
		margin:5px 0;
	}
	.ym_myroom_area .box:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.ym_myroom_area .box.on:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.ym_myroom_area .box .cover{
		font-size:10px;
	}
	.ym_myroom_area .box .content{
		padding:1.5vh;
	}
	.ym_myroom_area .content span{
		font-size:14px;
	}
	.ym_myroom_area .content p{
		font-size:12px;
	}
	.ym_myroom_area .box .content .bottom_area{
		margin-top:0;
	}
	.ym_myroom_area .content .graph_area3 .count_wrap{
		height:29%;
	}
	.ym_myroom_area .content .graph_area3 .count1{
		top:58%;
	}
	.ym_myroom_area .content .graph_area3 .count2{
		top:58%;
	}
	.ym_myroom_area .content .graph_area3 .count3{
		top:58%;
	}
	.ym_myroom_area .content .graph_area3 .counting{
		font-size:1em;
	}
	.ym_myroom_area .content .graph_area1_graph{
		width:80%;
	}

	/*brain_music_area*/
	.brain_music_area{
		padding-top:80px;
		padding-bottom:10vh;
		background:url(../assets/musicroom_mobile.png) no-repeat center;
		background-size:cover;
	}
	.brain_music_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.brain_music_area .index_tit{
		width:100%;
		text-align:center;
		padding-left:0;
	}
	.brain_music_area .sub_title{
		text-align:center;
		padding-left:0;
	}
	.brain_music_area .wrap{
		margin-left:0;
		top:0;
		-webkit-box-align:center;
		align-items:center;
	}
	.brain_music_area .wrap ul{
		display:-webkit-box;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-top:4vh;
	}
	.brain_music_area .wrap li{
		padding:1vh 4vw;
		width:100%;
		height:auto;
		min-width:auto;
	}
	.brain_music_area .wrap li:nth-child(2){
		margin:2vh 0;
	}
	.brain_music_area .wrap li .play_wrap{
		width:8vh;
		height:8vh;
		margin-right:4vw;
	}
	.brain_music_area .wrap li .right_area{
		width:70%;
	}
	.brain_music_area .wrap li .right_area span{
		font-size:14px;
	}
	.brain_music_area .wrap li .right_area p{
		font-size:12px;
	}

	/*coding_block_area*/
	.coding_block_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.coding_block_area .inner90{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.coding_block_area .index_tit{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.coding_block_area .bottom_area{
		margin-top:2vh;
		-webkit-box-orient:vertical;
		flex-direction:column;
		height:auto;
	}
	.coding_block_area .cont{
		width:90%;
		height:6vh;
		overflow:hidden;
		padding:2vmin;
	}
	.coding_block_area .cont:last-child{
		width:100%;
	}
	.coding_block_area .cont:last-child br{
		display:none;
	}
	.coding_block_area .cont .num{
		height:4.5vh;
	}
	.coding_block_area .cont .title{
		font-size:14px;
		visibility:hidden;
	}
	.coding_block_area .cont .sub_tit{
		font-size:11px;
		visibility:hidden;
	}
	.coding_block_area .cont p{
		font-size:12px;
		visibility:hidden;
	}
	.coding_block_area .cont img:last-child{
		width:20vw;
		visibility:hidden;
	}
	.coding_block_area .cont.on .title{
		visibility:visible;
	}
	.coding_block_area .cont.on .sub_tit{
		visibility:visible;
	}
	.coding_block_area .cont.on p{
		visibility:visible;
	}
	.coding_block_area .cont.on img:last-child{
		visibility:visible;
	}
	.coding_block_area .cont.on{
		-webkit-transform:scale(1);
		transform:scale(1);
		margin:2vh 0;
		overflow:visible;
		height:auto;
	}
	.coding_block_area .cont.on:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.coding_block_area .cont:hover{
		-webkit-transform:scale(1);
		transform:scale(1);
	}
	.coding_block_area .cont:last-child.on{
		margin-left:0;
	}
	.coding_block_area .arrow_wrap{
		display:none;
	}

	/*yf_area*/
	.yf_area{
		padding-top:80px;
		padding-bottom:10vh;
	}
	.yf_area .content_area{
		height:auto;
		width:100%;
		-webkit-box-orient:vertical;
		-webkit-box-align:Center;
		flex-direction:column;
		align-items:center;
		margin-top:2vh;
	}
	.yf_area .content_area .btn_area{ 
		width:100%;
		height:36px;
		padding-bottom:0;
		margin-right:0;
		margin-left:0;
		margin-bottom:1vh;
		-webkit-box-orient:horizontal;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
	}
	.yf_area .content_area .btn_area li{
		width:auto;
		height:60%;
		margin:0 3vw;
	}
	.yf_area .content_area .btn_area li.on{
		height:100%;
	}
	.yf_area .content_area .btn_area li.on:hover{
		height:100%;
	}
	.yf_area .content_area .btn_area li:hover{
		height:80%;
	}
	.yf_area .yf_img_area{
		height:160px;
		margin-right:0;
	}
	.yf_area .yf_img_area img{
		height:100%;
	}
	.yf_area .yf_info_area{
		width:100%;
		height:90px;
		margin-top:15px;
		-webkit-box-pack:start;
		justify-content:flex-start;
	} 
	.yf_area .yf_info_area .album_area{
		display:none;
	}	
	.yf_area .yf_info_area .txt_area{
		width:100%;
		height:auto;
	}	
	.yf_area .yf_info_area .txt_box{
		width:100%;
		height:auto;
		padding:3vmin;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		align-items:center;
		justify-content:center;
	}	
	.yf_area .yf_info_area .txt_box span{
		font-size:14px;
	}
	.yf_area .yf_info_area .txt_box p{
		font-size:12px;
		text-align:center;
	}

	/* yame_sns_area */
	.yame_sns_area{
		padding-bottom:10vh;
		background-size:auto 50%;
	}
	.yame_sns_area .inner90{
		width:90%;
	}
	.yame_sns_area .yame_sns_title_area{
		height:52px;
	}
	.yame_sns_title_area span{
		font-size:1.45em;
	}
	.yame_sns_area .yame_youtube_area{
		margin-top:10px;
		padding:0;
	}
	.yame_youtube_area a{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.yame_youtube_area img{
		margin-right:0;
		height:132px;
	}
	.yame_youtube_area .right_area{
		display:-webkit-box;
		-webkit-box-orient:vertical;
		 -webkit-box-align:center;
		 display:flex;
		 flex-direction:column;
		 align-items:center;
		 margin-top:6px;
		 width:100%;
	}
	.yame_youtube_area .top_area{
		width:100%;
		-webkit-box-pack:center;
		justify-content:center;
	}
	.yame_youtube_area .top_area img{
		height:26px;
	}
	.yame_youtube_area span{
		font-size:1.45em;
	}
	.yame_youtube_area p{
		text-align:center;
		font-size:1em;
		line-height:1.6;
		margin-top:6px;
		width:100%;
	}
	.yame_youtube_area p br{
		display:none;
	}
	.yame_sns_area .yame_cafe_area{
		width:100%;
		height:auto;
		box-sizing:border-box;
		padding:12px 20px;
		-webkit-box-orient:vertical;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		flex-direction:column;
		align-items:center;
		justify-content:Center;
	}
	.yame_cafe_area .cafe_top_area{
		width:100%;
	}
	.yame_cafe_area .text_area{
		width:70%;
	}
	.yame_cafe_area span{
		font-size:1em;
	}
	.yame_cafe_area p{
		margin-top:0;
		font-size:.8em;
	}
	.yame_cafe_area a{
		margin-top:6px;
		width:100%;
		height:42px;
		font-size:.9em;
	}

	/* floating_paging_btn_wrap */
	.floating_paging_btn_wrap{
		visibility:hidden;
	}
	
	/* header */
	.mobile_menu_area{
		width:50%;
	}
	.mobile_menu_wrap .mobile_menu_top_area{
		height:50px;
	}
	.mobile_menu_wrap .mobile_menu_top_area img{
		height:32px;
	}
	.mobile_my_area .my_wrap .top_area{
		height:50px;
	}
	.mobile_my_area .my_wrap .left_area{
		height:32px;
	}
	.mobile_my_area .my_wrap .bottom_area{
		height:32px;
	}
	.mobile_menu_wrap .bottom_menu_area{
		display:-webkit-box;
		display:flex;
		flex-wrap:wrap;
	}
	.mobile_menu_wrap .bottom_menu_area a{
		height:40px;
		width:50%;
		font-size:13px;
	}
	
	/* footer */
	section.footer_section{
		padding-top:30px;
		padding-bottom:30px;
	}
	.footer .inner90{
		width:90%;
		-webkit-box-direction:normal;
		-webkit-box-orient:horizontal;
		-webkit-box-align:start;
		flex-direction:row;
		align-items:flex-start;
	}
	.f_left{
		width:75%;
	}
	.f_right{
		width:25%;
		margin-top:20px;
		box-sizing:border-box;
		padding-left:10px;
	}
	.f_right ul{
		margin-top:0;
		-webkit-align:start;
		align-items:flex-start;
	}
	.f_right li:nth-child(3){
		text-align:center;
	}
	.f_right li.faq a{
		font-size:20px;
	}
	.f_right li.inquiry{
		font-size:14px;
	}
	.f_left ul{
		-webkit-box-align:start;
		align-items:flex-start;
		padding-left:0;
	}
	.f_left li{
		text-align:left;
	}
	.f_left li:nth-child(1){
		margin-bottom:15px;
		margin-left:64px;
		display:none;
	}
	.f_left li:nth-child(3){
		margin-bottom:16px;
	}
	.f_left li br{
		display:none
	}
	
	/*index.html*/
	.index_tit{
		font-size:1.25em;
	}
	/*main_banner_area */
	.bn_content_wrap{
		padding-top:2vh;
		padding-bottom:5vh;
	}
	.bn_content_wrap .inner90{
		-webkit-box-pack:center;
		-webkit-box-orient:horizontal;
		justify-content:center;
		flex-direction:row;
	}
	.bn.open .top_area span{
		font-size:.9em;
	}
	.bn.open .top_area span.title{
		font-size:2em;
	}
	.bn.open .top_area p{
		font-size:.6em;
		margin-top:20px;
	}
	.bn.open .top_area p.input_info{
		font-size:.6em;
		margin-top:10px;
	}
	.bn.open .open_email_input_area{
		height:32px;
	}
	.bn.open #banner_email_input{
		font-size:.8em;
	}
	.bn.open .open_email_input_area button{
		font-size:.8em;
	}
	.bn_btn_wrap{
		top:75%;
	}
	/* 오픈세일배너 */
	.bn.sale .bn_content_wrap .inner90{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.bn.sale .txt1{
		width:auto;
		height:8%;
	}
	.bn.sale .txt2{
		width:auto;
		height:39%;
		margin-top:1%;
		margin-bottom:0;
	}
	.bn.sale .bottom_coin_area{
		display:none;
	}
	.bn.sale .coin1.bounceIn{
		height:17%;
		top:22%;
		left:-32%;
	}
	.bn.sale .coin2.bounceIn{
		height:8%;
		top:75%;
		left:-32%;
	}
	.bn.sale .coin3.bounceIn{
		height:11%;
		top:8%;
		left:-31%;
	}
	.bn.sale .coin4.bounceIn{
		height:5%;
		top:43%;
		left:-34%;
	}
	.bn.sale .coin5.bounceIn{
		height:7%;
		top:21%;
		left:31%;
	}
	.bn.sale .coin6.bounceIn{
		height:9%;
		top:74%;
		left:28%;
	}
	.bn.sale .coin7.bounceIn{
		height:4%;
		top:41%;
		left:30.5%;
	}
		
	/* 인기브랜드대상배너 */
	.bn.popular{
		background:url(../assets/popular_bn_bg_mobile_row.jpg) no-repeat center;
		background-size:cover;
	}
	.bn.popular .inner90{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.bn.popular .txt{
		width:60%;
		height:auto;
	}
	.bn.popular .tropy{
		width:25%;
		height:auto;
		margin-top:0;
	}
	.bn.popular .top .star2{
		display:block;
		top:76%;
		left:-47%;
	}
	.bn.popular .top .star4{
		top:93%;
		left:45%;
	}

	/* 수학섬 업데이트 배너 */
	.bn.mathupdate .bn_content_wrap .inner90{
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.bn.mathupdate .txt1{
		position: absolute;
		z-index: 2;
		height:35%;
		margin-top:6%;
		margin-left: -2%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}
	.bn.mathupdate .txt2{
		position: relative;
		z-index: 1;
		height:70%;
		margin-top:10%;
		-webkit-animation-delay:.3s;
		animation-delay:.3s;
	}
	.bn.mathupdate .button{
		position: absolute;
		z-index: 2;
		width: auto;
		margin-top:17%;
		margin-left:-4%;
		-webkit-animation-delay:.1s;
		animation-delay:.1s;
	}

	/*introduce_area*/
	.introduce_area{
		padding-top:0;
		padding-bottom:5vh;
	}
	.introduce_area .top_area{
		-webkit-box-direction:normal;
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.introduce_area .top_area img{
		height:40vh;
		margin-right:4vw;
	}
	.introduce_area .top_area .right_area{
		-webkit-box-align:starat;
		align-items:flex-start;
		margin-bottom:0;
	}
	.introduce_area .right_area p{
		text-align:left;
	}
	.introduce_area .right_area p br{
		display:block;
	}
	.introduce_area .ym_who_popup.on{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.introduce_area .ym_who_btn{
		margin-top:3vh;
	}
	.introduce_area .ym_who_btn span{
		font-size:13px;
	}

	/*ym_world_area*/
	.ym_world_area{
		padding-top:0;
		padding-bottom:5vh;
	}
	.ym_world_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.ym_world_area p.txt br{
		display:block;
	}
	.ym_world_area .bottom_area{
		-webkit-box-orient:horizontal;
		-webkit-box-direction:normal;
		-webkit-box-align:center;
		-webkit-box-pack:center;
		flex-direction:row;
		justify-content:center;
		align-items:center;
	}
	.ym_world_area .right_area{
		height:30vh;
		width:50%;
		margin-left:3vw;
		top:0;
	}
	.ym_world_area .building_wrap{
		width:100%;
		height:100%;
	}

	/*ym_difference_area*/
	.ym_difference_area{
		padding-top:0;
		padding-bottom:5vh;
	}
	.ym_difference_area .middle_area{
		margin-top:4vh;
		height:25vh;
	}
	.ym_difference_area .img_area{
		display:none;
	}

	/*ym_myroom_area*/
	.ym_myroom_area{
		padding-top:0;
		padding-bottom:5vh;
	}
	.ym_myroom_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.ym_myroom_area .bottom_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.ym_myroom_area .box{
		width:22vw;
		height:auto;
		margin:0 1vw;
	}
	.ym_myroom_area .box.on{
		margin:0 2vw;
	}
	.ym_myroom_area .content .graph_area3 img{
		width:80%;
	}
	.ym_myroom_area .content .graph_area3 .counting{
		font-size:.8em;
	}
	.ym_myroom_area .content .graph_area3 .count1{
		top:62%;
		left:8%;
	}
	.ym_myroom_area .content .graph_area3 .count2{
		top:62%;
	}
	.ym_myroom_area .content .graph_area3 .count3{
		top:62%;
		left:67%;
	}
	.ym_myroom_area .graph_area1 .wrap{
		width:53%;
	}
	.ym_myroom_area .content .graph_area1_graph{
		width:75%;
		position:relative;
		top:-8%;
	}
	.ym_myroom_area .content .bottom_area{
		height:48%;
	}

	/*brain_music_area*/
	.brain_music_area{
		padding-top:0;
		padding-bottom:15vh;
	}
	.brain_music_area .index_tit{
		-webkit-box-pack:center;
		justify-content:center;
	}
	.brain_music_area .wrap{
		width:64%;
	}
	.brain_music_area .wrap ul{
		width:100%;
	}
	.brain_music_area .wrap li{
		padding:1vh 2vw;
	}
	.brain_music_area .wrap li .play_wrap{
		margin-right:2vw;
	}

	/*coding_block_area*/
	.coding_block_area{
		padding-top:0;
		padding-bottom:10vh;
	}
	.coding_block_area .index_tit{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.coding_block_area .bottom_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
		height:auto;
	}
	.coding_block_area .cont .num{
		height:4.5vh;
	}
	.coding_block_area .cont img:last-child{
		display:none;
	}

	/*yf_area*/
	.yf_area{
		padding-top:0;
		padding-bottom:10vh;
	}
	.yf_area .content_area{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.yf_area .content_area .btn_area{ 
		width:20%;
		height:36vh;
		-webkit-box-orient:vertical;
		flex-direction:column;
	}
	.yf_area .content_area .btn_area li{
		margin:1vh 0;
		height:20%;
		position:relative;
	}
	.yf_area .content_area .btn_area li.on{
		height:20%;
	}
	.yf_area .content_area .btn_area li.on:hover{
		height:20%;
	}
	.yf_area .content_area .btn_area li img{
		height:70%;
	}
	.yf_area .content_area .btn_area li.on img{
		height:100%;
	}
	.yf_area .yf_info_area{
		width:50%;
	} 

	/* yame_sns_area */
	.yame_sns_area{
		padding-top:0;
		background-size:100% auto;
	}
	.yame_sns_area .yame_sns_title_area{
		box-sizing:border-box;
		padding-left:50px;
		height:40px;
	}
	.yame_sns_title_area span{
		font-size:1.25em;
	}
	.yame_sns_area .yame_youtube_area{
		padding:0 56px 0;
		margin-top:0;
	}
	.yame_youtube_area a{
		-webkit-box-orient:horizontal;
		flex-direction:row;
	}
	.yame_youtube_area img{
		margin-right:32px;
		height:100px;
	}
	.yame_youtube_area .right_area{
		margin-top:0;
		-webkit-box-align:start;
		align-items:flex-start;
		width:auto;
	}
	.yame_youtube_area .top_area{
		width:auto;
		-webkit-box-pack:start;
		justify-content:flex-start;
	}
	.yame_youtube_area span{
		font-size:1.25em;
	}
	.yame_youtube_area p{
		text-align:left;
		font-size:16px;
		width:auto;
	}
	.yame_youtube_area a{
		padding-bottom:8px;
	}
	.yame_sns_area .yame_cafe_area{
		width:calc(100% - 112px);
		-webkit-box-orient:horizontal;
		-webkit-box-pack:justify;
		flex-direction:row;
		justify-content:space-between;
		padding:6px 20px;
		margin-top:10px;
	}
	.yame_cafe_area .cafe_top_area{
		width:calc(100% - 122px);
	}
	.yame_cafe_area span{
		font-size:16px;
	}
	.yame_cafe_area p{
		font-size:12px;
	}
	.yame_cafe_area a{
		width:102px;
		margin-top:0;
	}
}


